import { BackendResource, DeviceType } from "../../models/SmartHomeApi";
import { slice } from "./reducer";
import * as selectors from "./selector";

export interface DeviceTypeState {
  isLoading: boolean;
  error?: string;
  data?: BackendResource<DeviceType>[];
}

export const DeviceTypeStore = {
  reducer: slice.reducer,
  actions: slice.actions,
  selectors,
};
