import React, { FC, useEffect, useState } from "react";
import StepFooter from "../../../StepFooter/StepFooter";

import { useKeyListener } from "../../../../../../../hooks/KeyListeners";
import {
  TkLockIdNumberPng,
  TkLockInfoLocationPng,
} from "../../../../../../../assets";

interface IdentificationNumberProps {
  value: string;
  onSubmit: (value: string) => void;
  onPrevious: () => void;
}

const IdentificationNumber: FC<IdentificationNumberProps> = ({
  value: initialValue,
  onSubmit,
  onPrevious,
}) => {
  const [value, setValue] = useState<string>(initialValue);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    setIsValid(/^[0-9]{5}$/.test(value));
  }, [value]);

  const handleInput = (e: any) => {
    // strip non-numeric chars from input & limit length to 5
    const value: string =
      e.target.value?.trim()?.replace(/[^\d]/, "")?.substring(0, 5) ?? "";

    // set state & update ui w/out altered value
    setValue(value);
    e.target.value = value;
  };

  const handleSubmit = () => {
    if (value && isValid) {
      onSubmit(value);
    }
  };
  useKeyListener("Enter", handleSubmit);

  const handlePrevious = () => {
    onPrevious();
  };

  return (
    <div className="step-scroll-container">
      <div className="step-header">
        <div className="title">Enter identification number</div>
      </div>
      <div className="step-body">
        <div>
          First, you'll need to find and enter the lock identification number.
          Locate the identification number on the inside of the lock (known as
          the BT ID number) and enter the digits after 'TKVR'
        </div>
        <div>
          <input
            id="inp-id-num"
            type="text"
            name="identification-number"
            placeholder="Identification Number"
            value={value}
            onChange={handleInput}
            autoFocus
          ></input>
        </div>
        <div>
          <img src={TkLockIdNumberPng} alt="code location" />
          <img src={TkLockInfoLocationPng} alt="sticker location" />
          <i>The lock ID is located on a sticker inside the back of the lock</i>
        </div>
      </div>
      <StepFooter
        onSubmit={handleSubmit}
        onPrevious={handlePrevious}
        disabled={!isValid}
      />
    </div>
  );
};

export default IdentificationNumber;
