import React, { FC } from "react";
import { Image } from "@vacasa/react-components-lib";
import { RegisteredDevice } from "../../../models/SmartDeviceApi";
import Device from "./Device/Device";

import "./MonitoringDevices.scss";
import AddButton from "../../../elements/Button/AddButton";

interface MonitoringDevicesProps {
  isLoading: boolean;
  data?: RegisteredDevice[];
  error?: string;
  addDisabled?: boolean;
  onAddDevice?: (e: MouseEvent) => void;
  onEditDevice?: (edit: RegisteredDevice) => void;
  onUnregisterDevice?: (device: RegisteredDevice) => void;
  timezone?: string;
}

const MonitoringDevices: FC<MonitoringDevicesProps> = (props) => {
  const handleAddDeviceClick = function (e: any) {
    props.onAddDevice?.(e);
  };

  const handleDeviceEdit = function (data: RegisteredDevice) {
    props.onEditDevice?.(data);
  };

  const handleDeviceUnregister = function (data: RegisteredDevice) {
    props.onUnregisterDevice?.(data);
  };

  const getDeviceList = function () {
    if (props.isLoading) {
      return (
        <>
          <div>loading...</div>
          <Image.Spinner height={36} width={36} />
        </>
      );
    } else if (props.error) {
      return <span>{props.error}</span>;
    } else if (!props.data?.length) return <span>No data</span>;

    return props.data.map((device) => {
      return (
        <Device
          key={device?.MacAddress}
          device={device}
          onEdit={handleDeviceEdit}
          onUnregister={handleDeviceUnregister}
          timezone={props.timezone}
        />
      );
    });
  };

  return (
    <div className="component-monitoring-devices">
      <div className="device-list">{getDeviceList()}</div>
      <div className="footer">
        <AddButton
          id="btn-add-monitoring-device"
          display="outline"
          onClick={handleAddDeviceClick}
          disabled={props.addDisabled}
        >
          Add Monitoring Device
        </AddButton>
      </div>
    </div>
  );
};

export default MonitoringDevices;
