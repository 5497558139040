import { FC } from "react";
import { ICellRendererParams } from "ag-grid-community";
import { AiOutlineCheck, AiOutlineMinusCircle } from "react-icons/ai";
import { IReport } from "../../../models/SmartHomeApi";
/**
 * Note no tz changes here.  It will load the date as a string and compare to now in the users tz
 * as we do not know the units tz.
 */
const UsableRenderer: FC<ICellRendererParams> = (props) => {
  const data = props.data as IReport;
  const start = new Date(data.start_time);
  const end = new Date(data.end_time);
  const now = new Date();
  if (start <= now && now <= end) return <AiOutlineCheck color="green" />;
  return <AiOutlineMinusCircle color="red" />;
};

export default UsableRenderer;
