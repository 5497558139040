import React, { FC } from "react";
import StepFooter from "../../../StepFooter/StepFooter";

interface DoneProps {
  onSubmit: () => void;
}

const Done: FC<DoneProps> = ({ onSubmit }) => {
  return (
    <div>
      <div className="step-header">
        <div className="title">Congratulations!</div>
      </div>
      <div className="step-body done">
        Your lock is now configured and ready to use!
      </div>
      <StepFooter onSubmit={onSubmit} submitText="Close" />
    </div>
  );
};

export default Done;
