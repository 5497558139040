import React, { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DeviceTypeEnum } from "../../../../models/DeviceTypeEnum";
import {
  BackendResource,
  Compatibility,
} from "../../../../models/SmartHomeApi";
import SmartHomeService from "../../../../services/smarthome.service";
import BasicButton from "../../../Button/BasicButton";
import YesNoToggle from "../../../Form/YesNoToggle";
import { Image } from "@vacasa/react-components-lib";
import { DeviceCompatibilityStore } from "../../../../store/device-compatibility";

interface CompatibilityRowProps {
  title: string;
  type: DeviceTypeEnum;
  compatibility?: BackendResource<Compatibility>;
  options: string[];
  disabled?: boolean;
  operator?: string;
  propertyId: string;
}

const CompatibilityRow: FC<CompatibilityRowProps> = (props) => {
  const dispatch = useDispatch();

  const [isCompatible, setIsCompatible] = useState(
    props.compatibility === undefined,
  );
  const [reason, setReason] = useState(
    props.compatibility?.attributes?.reason ?? "",
  );

  const [isValid, setIsValid] = useState(true);
  const [isChanged, setIsChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  // checks form validity
  useEffect(() => {
    const validYes = isCompatible && !reason;
    const validNo = !isCompatible && !!reason;
    setIsValid(validYes || validNo);
  }, [isCompatible, reason]);

  // checks whether values have been modified
  useEffect(() => {
    const defaultIsCompatible = props.compatibility === undefined;
    const defaultReason = props.compatibility?.attributes?.reason ?? "";

    setIsChanged(
      defaultIsCompatible !== isCompatible || defaultReason !== reason,
    );
  }, [isCompatible, reason, props.compatibility]);

  // bubbles up changes to reason when value is modified
  const handleValueChange = (newValue: boolean) => {
    setIsCompatible(newValue);
    if (newValue) setReason("");
    else {
      setReason(props.compatibility?.attributes?.reason ?? "");
    }
  };

  // changes reason
  const handleReasonChange = (e: any) => {
    setReason(e.target.value);
  };

  // creates, updates, or deletes the resource
  const handleSave = () => {
    if (isValid) {
      const smartHomeService = new SmartHomeService();

      let apiCall:
        | Promise<{
            data: BackendResource<Compatibility>;
          }>
        | undefined;

      let action:
        | ((res: { data: BackendResource<Compatibility> }) => void)
        | undefined;

      if (isCompatible && props.compatibility) {
        // Need to delete in db
        apiCall = smartHomeService.deleteCompatibility(
          props.compatibility,
          props.operator,
        );
        action = (_) =>
          dispatch(
            DeviceCompatibilityStore.actions.removeData(props.compatibility!),
          );
      } else if (!isCompatible) {
        action = (res) =>
          dispatch(DeviceCompatibilityStore.actions.upsertData(res!.data));
        if (props.compatibility && reason) {
          // Need to update
          const updated = { ...props.compatibility };
          updated.attributes = {
            property_id: props.propertyId,
            device_type: props.type,
            reason,
          };
          apiCall = smartHomeService.updateCompatibility(
            updated,
            props.operator,
          );
        } else if (reason) {
          // Need to create
          const newCompatibility = {
            property_id: props.propertyId,
            device_type: props.type,
            reason: reason,
          };
          apiCall = smartHomeService.createCompatibility(
            newCompatibility,
            props.operator,
          );
        }
      }

      if (apiCall && action) handleApiRequest(apiCall, action);
    }
  };
  const handleCancel = () => {
    setIsCompatible(props.compatibility === undefined);
    setReason(props.compatibility?.attributes?.reason ?? "");
    setErrorMessage(undefined);
  };

  const handleApiRequest = (
    request: Promise<{
      data: BackendResource<Compatibility>;
    }>,
    callback: (res: { data: BackendResource<Compatibility> }) => void,
  ) => {
    // Make the api call and update the store
    setIsLoading(true);
    setErrorMessage(undefined);
    request
      .then(callback)
      .catch((err) => setErrorMessage(err.message))
      .finally(() => setIsLoading(false));
  };

  const getActionContent = () => {
    if (isLoading) {
      return (
        <div className="compatibility-spinner">
          <Image.Spinner height={36} width={36} />
        </div>
      );
    } else if (isChanged && !props.disabled) {
      return (
        <>
          <BasicButton
            display="fill"
            disabled={!isValid}
            onClick={handleSave}
            style={{ marginRight: 9 }}
          >
            Save
          </BasicButton>
          <BasicButton display="outline" onClick={handleCancel}>
            Cancel
          </BasicButton>
        </>
      );
    }
  };

  return (
    <div className="component-compatibility-row">
      <div className="title col">{props.title}</div>
      <div className="supported col">
        <YesNoToggle
          value={isCompatible}
          onChange={handleValueChange}
          disabled={props.disabled}
        ></YesNoToggle>
      </div>
      <div className="reason col">
        <select
          value={reason}
          onChange={handleReasonChange}
          disabled={isCompatible}
        >
          <option value="">--</option>
          {props.options.map((x) => (
            <option key={x} value={x}>
              {x}
            </option>
          ))}
        </select>
      </div>
      <div className="action col">
        {getActionContent()}
        <div className="error-message">{errorMessage}</div>
      </div>
    </div>
  );
};

export default CompatibilityRow;
