import React, { FC } from "react";

import "./Toggle.scss";

interface YesNoToggleProps {
  value: boolean;
  onChange?: (newValue: boolean) => void;
  disabled?: boolean;
}

const YesNoToggle: FC<YesNoToggleProps> = (props) => {
  const setValue = (value: boolean) => {
    if (!props.disabled) {
      props.onChange?.(value);
    }
  };

  return (
    <div className="component-toggle component-yes-no-toggle">
      <div
        className={`${props.disabled ? "disabled" : ""} ${
          !props.value ? "selected" : ""
        } value-no`}
        onClick={() => setValue(false)}
      >
        No
      </div>
      <div
        className={`${props.disabled ? "disabled" : ""} ${
          props.value ? "selected" : ""
        } value-yes`}
        onClick={() => setValue(true)}
      >
        Yes
      </div>
    </div>
  );
};
export default YesNoToggle;
