import { Image } from "@vacasa/react-components-lib";
import React, { FC, useState } from "react";
import BasicButton from "../../../../../elements/Button/BasicButton";
import {
  RegisteredDevice,
  RegisteredDeviceType,
} from "../../../../../models/SmartDeviceApi";
import SmartDeviceService from "../../../../../services/smart-device.service";
import DeviceDetail from "../DeviceDetails/DeviceDetails";

import "./UnregisterDevice.scss";

interface UnregisterDeviceProps {
  device: RegisteredDevice;
  onSubmit?: () => void;
  onCancel?: () => void;
}

const UnregisterDevice: FC<UnregisterDeviceProps> = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const handleSubmit = () => {
    const service = new SmartDeviceService();

    setIsLoading(true);
    setErrorMessage(undefined);

    service
      .unregisterDevice(props.device.MacAddress, props.device.DeviceType)
      .then(() => props.onSubmit?.())
      .catch((err) => setErrorMessage(err.message))
      .finally(() => setIsLoading(false));
  };

  const handleCancel = () => {
    props.onCancel?.();
  };

  const getTitle = () => {
    if (props.device.DeviceType === RegisteredDeviceType.Sensor)
      return "Unregister Sound Monitor";
    else if (props.device.DeviceType === RegisteredDeviceType.Router)
      return "Unregister Router";
    return "Unregister Device";
  };

  return (
    <div className="component-unregister-device">
      <DeviceDetail device={props.device} title={getTitle()}>
        <div className="confirmation">
          Are you sure you want to unregister this device?
        </div>
      </DeviceDetail>
      <div className="unregister-device-footer">
        <div>
          <BasicButton display="outline" onClick={handleCancel}>
            No
          </BasicButton>
          {isLoading ? (
            <Image.Spinner className="spinner" width={36} height={36} />
          ) : (
            <BasicButton
              display="fill"
              onClick={handleSubmit}
              style={{ float: "right" }}
            >
              Yes, Unregister
            </BasicButton>
          )}
        </div>
        <div className="error-message">{errorMessage}</div>
      </div>
    </div>
  );
};

export default UnregisterDevice;
