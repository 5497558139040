import React, { FC, useState } from "react";
import {
  BackendResource,
  DeviceType,
} from "../../../../../models/SmartHomeApi";
import SelectList from "../../../../../elements/Form/SelectList";
import { parseResource } from "../../lib/parse-resource";
import { useFormValue } from "../../../../../hooks/FormValue";
import StepFooter from "../StepFooter/StepFooter";

interface SelectTypeStepProps {
  deviceTypes?: BackendResource<DeviceType>[];
  onSubmit: (deviceType: BackendResource<DeviceType>) => void;
}

const SelectTypeStep: FC<SelectTypeStepProps> = ({ deviceTypes, onSubmit }) => {
  const deviceTypeForm = useFormValue<number>(undefined, false);
  const [deviceTypeOptions] = useState(deviceTypes?.map(parseResource));

  const handleSubmit = function () {
    if (deviceTypeForm.isValid) {
      const type = deviceTypes?.find((x) => x.id === deviceTypeForm.value);
      if (type) {
        onSubmit(type);
      }
    }
  };

  return (
    <div className="select-list-wrapper">
      <SelectList
        title="New Smart Device"
        description="CHOOSE DEVICE TYPE"
        value={deviceTypeForm}
        options={deviceTypeOptions ?? []}
        displayFilter={true}
        filterPlaceholder="search devices"
      />
      <StepFooter onSubmit={handleSubmit} disabled={!deviceTypeForm.isValid} />
    </div>
  );
};

export default SelectTypeStep;
