import React, { FC, useCallback, useState } from "react";

import "./Copy.scss";
import { AiFillCopy } from "react-icons/ai";

export interface BasicCopyProps {
  value: any;
  size?: number;
}

const CopyValue: FC<BasicCopyProps> = (props) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const copyValue = useCallback(
    (event: any = null) => {
      if (!showTooltip) {
        navigator.clipboard.writeText(props.value);
        setShowTooltip(true);
      }
      if (event) setTimeout(() => setShowTooltip(false), 500);
    },
    [showTooltip],
  );

  const attributes = {
    size: props.size ?? 18,
    onClick: copyValue,
    key: `${props.value}-${Math.random()}`,
  };

  return (
    <span
      className="copyButton"
      style={{ color: `${showTooltip ? "#2cbf2c" : "#7d827d"}` }}
    >
      <AiFillCopy {...attributes} />
    </span>
  );
};

export default CopyValue;
