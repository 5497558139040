import React, { FC } from "react";
import StepFooter from "../../../StepFooter/StepFooter";

interface MemoryResetProps {
  onSubmit: () => void;
  onPrevious: () => void;
}

const MemoryReset: FC<MemoryResetProps> = ({ onSubmit, onPrevious }) => {
  return (
    <div>
      <div className="step-header">
        <div className="title">Memory Reset</div>
      </div>
      <div className="step-body">
        Now let's reset the memory
        <ol>
          <li>
            Enter your 6 digit Private Master Code (PMC) on the lock keypad
          </li>
          <li>
            Press the Schlage key and wait for the Schlage light to stop
            blinking green
          </li>
          <li>Press 5 on the keypad</li>
          <li>Press the Schlage key again</li>
        </ol>
        The Schlage button should flash green, and the lock should beep.
      </div>
      <StepFooter onSubmit={onSubmit} onPrevious={onPrevious} />
    </div>
  );
};

export default MemoryReset;
