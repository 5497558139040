import { FC, useEffect, useState } from "react";

import "./Reporting.scss";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

import SmartHomeService from "../../services/smarthome.service";
import { IReport, IReports } from "../../models/SmartHomeApi";
import { PropertyStore } from "../../store/property";
import UnauthorizedMessage from "../../elements/Templates/UnauthorizedMessage/UnauthorizedMessage";
import { HttpResponseError } from "../../core/errors";
import ReportType from "./ReportType";
import { canView } from "./ReportAuth";
import SpinnerOverlay from "../../elements/Spinner/SpinnerOverlay";

interface ReportingGridProps {}

const ReportGrid: FC<ReportingGridProps> = () => {
  const [errorMessage, setErrorMessage] = useState<string>();
  const [viewReports, setViewReports] = useState<boolean>(false);

  const [tableData, setTableData] = useState<IReports>();
  const property = PropertyStore.selectors.usePropertyData()!;
  const propertyId = property.id;

  const [isAuthorized, setIsAuthorized] = useState(true);

  // Loads all needed data for table view
  useEffect(() => {
    setViewReports(canView());
    const smartHomeService = new SmartHomeService();

    smartHomeService
      .getReports(propertyId)
      .then((reports) => {
        for (const reportType in reports) {
          const report = reports[reportType as keyof IReports];
          if (report.length)
            report.sort((a: IReport, b: IReport) => a.id - b.id);
        }
        setTableData(reports);
      })
      .catch((err) => {
        setTableData({ maintenance: [] });
        setErrorMessage(err.message);
        if (err instanceof HttpResponseError && err.status === 403) {
          setIsAuthorized(false);
        }
      });
  }, [propertyId]);

  if (!isAuthorized || !viewReports)
    return <UnauthorizedMessage tabName="Reporting" />;
  if (errorMessage) return <div className="ReportingPage">{errorMessage}</div>;
  if (!tableData) return <SpinnerOverlay fullScreen={true} />;

  return (
    <div data-testid="ReportingGrid" className="ReportingPage">
      <div>
        {Object.keys(tableData).map((reportType) => (
          <ReportType
            key={reportType}
            reportData={tableData[reportType as keyof IReports]}
          />
        ))}
      </div>
    </div>
  );
};

export default ReportGrid;
