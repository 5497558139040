import { Icon, Tooltip } from "@vacasa/react-components-lib";
import React, { FC } from "react";

import "./Button.scss";

export interface AddButtonProps {
  id?: string;
  display: "fill" | "basic" | "outline" | "alt" | "warning";
  hidden?: boolean;
  hoverText?: string;
  disabled?: boolean;
  onClick?: (e: MouseEvent) => void;
  children?: React.ReactNode;
}

const AddButton: FC<AddButtonProps> = (props) => {
  const displayMode = props.display ?? "basic";
  const attributes = {
    id: props.id,
    className: `btn btn-add ${displayMode}`,
    hidden: props.hidden,
    style: {
      paddingTop: 2,
    },
    disabled: props.disabled,
    onClick: (e: any) => props.onClick?.(e),
  };

  return (
    <Tooltip message={props.hoverText ?? ""}>
      <div>
        <button {...attributes}>
          <span className="btn-add-text">{props.children}</span>
          <span className="btn-add-icon">
            <Icon.PlusCircle className={displayMode} />
          </span>
        </button>
      </div>
    </Tooltip>
  );
};

export default AddButton;
