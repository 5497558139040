import React, { FC } from "react";
import { Link, useLocation } from "react-router-dom";
import { Image } from "@vacasa/react-components-lib";

import "./MonitoringDeviceAlerts.scss";
import Alert from "./Alert/Alert";
import { ReadingAlert } from "../../../models/SmartDeviceApi";

interface MonitoringDeviceAlertsProps {
  isLoading: boolean;
  data?: ReadingAlert[];
  error?: string;
}

const MonitoringDeviceAlerts: FC<MonitoringDeviceAlertsProps> = (props) => {
  const { search } = useLocation();

  const getAlertList = function () {
    if (props.isLoading) {
      return (
        <>
          <div>loading...</div>
          <Image.Spinner height={36} width={36} />
        </>
      );
    } else if (props.error) {
      return <span>{props.error}</span>;
    } else if (!props.data?.length) {
      return <span>No data</span>;
    } else {
      return props.data.map((alert, index) => (
        <Alert alert={alert} key={index} />
      ));
    }
  };

  return (
    <div className="component-monitoring-device-alerts">
      <div className="noise-alerts">{getAlertList()}</div>
      <div className="footer">
        <Link to={"./alerts" + search}>View All Alerts</Link>
      </div>
    </div>
  );
};

export default MonitoringDeviceAlerts;
