import { ICellRendererParams } from "ag-grid-community";
import React, { FC } from "react";

import styles from "./ActiveStateRenderer.module.scss";

const ActiveStateRenderer: FC<ICellRendererParams> = (props) => {
  const isActive = props.value;

  return (
    <div
      className={
        isActive ? styles.ActiveStateRenderer : styles.InactiveStateRenderer
      }
    >
      <span>{isActive ? "ACTIVE" : "INACTIVE"}</span>
    </div>
  );
};

export default ActiveStateRenderer;
