import { FC, useEffect, useState } from "react";
import { groupBy } from "lodash";
import {
  differenceInDays,
  isBefore,
  addMinutes,
  isAfter,
  startOfHour,
  getMinutes,
} from "date-fns";
import { Chart } from "react-google-charts";
import useWindowDimensions from "../../hooks/Window";
import { MOBILE_SCREEN_MAX_WIDTH } from "../../core/constants";

import { DataPoint, ChartDataSeries } from "./types";

import { formatInTimeZone } from "date-fns-tz";

interface LineChartProps {
  title?: string;
  data: ChartDataSeries;
  filterFrom?: Date;
  filterTo?: Date;
  timezone?: string;
}

export const LineChart: FC<LineChartProps> = (props) => {
  const data = props.data;
  const filterFrom = props.filterFrom;
  const filterTo = props.filterTo;

  const window = useWindowDimensions();
  const [legendPosition, setLegendPosition] = useState<"top" | "right">(
    "right",
  );

  const chartOptions = {
    title: props.title,
    legend: legendPosition,
    interpolateNulls: true,
    timezone: props.timezone,
  };

  useEffect(() => {
    if (window.width <= MOBILE_SCREEN_MAX_WIDTH) setLegendPosition("top");
    else setLegendPosition("right");
  }, [window]);

  /**
   * Formats props.data for chart display
   */
  const formatChartData = () => {
    const filtered: ChartDataSeries = {};
    Object.entries(data).forEach(([seriesName, seriesData]) => {
      filtered[seriesName] = [];
      seriesData.forEach((datapoint) => {
        const isAfterFilter =
          !filterFrom || isAfter(datapoint.timestamp, filterFrom);
        const isBeforeFilter =
          !filterTo || isBefore(datapoint.timestamp, filterTo);
        if (isAfterFilter && isBeforeFilter) {
          filtered[seriesName].push(datapoint);
        }
      });
      filtered[seriesName].sort(
        (a, b) => a.timestamp.getTime() - b.timestamp.getTime(),
      );
    });

    let aggregateFn = calculateAverage;
    let aggregationPeriodMinutes: number;

    const allData = Object.values(filtered).reduce(
      (acc, cur) => acc.concat(cur),
      [],
    );
    const allTimestamps = allData.map((x) => x.timestamp.getTime());
    const minTimestamp = Math.min(...allTimestamps);
    const maxTimestamp = Math.max(...allTimestamps);
    const dayDiff = differenceInDays(
      new Date(minTimestamp),
      new Date(maxTimestamp),
    );

    if (dayDiff === 0) {
      if (allData.length > 1) {
        // charts control does not render correctly if there are multiple second values within a minute range
        // instead, display the max value per sensor in each 1-minute window
        aggregationPeriodMinutes = 1;
        aggregateFn = calculateMax;
      } else {
        // no aggregation
        return toGoogleChartsData(filtered);
      }
    } else if (dayDiff <= 14) {
      aggregationPeriodMinutes = 5;
    } else {
      aggregationPeriodMinutes = 60;
    }

    const aggregated = aggregateSeries(
      filtered,
      aggregationPeriodMinutes,
      aggregateFn,
      props.timezone,
    );
    return toGoogleChartsData(aggregated);
  };

  const aggregateSeries = (
    series: ChartDataSeries,
    aggMinutes: number,
    aggregateFn: (arr: number[]) => number,
    timezone?: string | undefined,
  ): ChartDataSeries => {
    if (aggMinutes <= 0) return series;

    // aggregate so only 1 value per time window per series...
    const aggregated: ChartDataSeries = {};
    Object.entries(series).forEach(([seriesName, seriesData]) => {
      // group datapoints into buckets based on aggregationPeriod
      const grouped = groupBy(seriesData, (datapoint) => {
        const currMinutes = getMinutes(datapoint.timestamp);
        const minuteWindowStart =
          Math.trunc(currMinutes / aggMinutes) * aggMinutes;
        const timestamp = addMinutes(
          startOfHour(datapoint.timestamp),
          minuteWindowStart,
        );
        if (timezone === undefined) return timestamp.toISOString();
        else return formatInTimeZone(timestamp, timezone, "MM/dd/yyyy H:mm");
      });
      // combine each window into a single datapoint
      const aggregatedData: DataPoint[] = Object.entries(grouped).map(
        ([bucket, datapoints]) => {
          const timestamp = new Date(bucket);
          const value = aggregateFn(datapoints.map((x) => x.value));
          return { timestamp, value };
        },
      );
      aggregated[seriesName] = aggregatedData;
    });
    return aggregated;
  };

  /**
   * Translates chart data for google charts input
   * @param series
   * @returns
   */
  const toGoogleChartsData = (series: ChartDataSeries): any[] => {
    // header = [xAxisLabel, seriesAName, seriesBName, ..., seriesZName]
    // columns = [timestamp, seriesAValue, seriesBValue, ..., seriesZValue][]
    const seriesNames = Object.keys(series);
    const header: string[] = ["Time", ...seriesNames];

    // Group all datapoints by timestamp
    const columnMap: { [key: string]: { [key: string]: number } } = {};
    Object.entries(series).forEach(([seriesName, seriesData]) => {
      seriesData.forEach((datapoint) => {
        const timestamp = datapoint.timestamp.toISOString();
        const value = datapoint.value;

        if (!columnMap[timestamp]) columnMap[timestamp] = {};
        columnMap[timestamp][seriesName] = value;
      });
    });
    const columns: any[][] = Object.entries(columnMap).map(
      ([timestamp, seriesData]) => {
        // return nested array where each inner array is [timestamp, seriesAValue, seriesBValue, ...]
        const column: any[] = seriesNames.map((seriesName) => {
          const dataValue = seriesData[seriesName];
          return dataValue ?? NaN;
        });
        column.unshift(new Date(timestamp));
        return column;
      },
    );

    // Sort columns in ascending order by timestamp
    columns.sort((a, b) => {
      const aTime = new Date(a[0]);
      const bTime = new Date(b[0]);
      return aTime.getTime() - bTime.getTime();
    });

    if (columns.length) return [header, ...columns];
    else return [];
  };

  /**
   * Returns the average of a list of numbers
   * @param items
   * @returns
   */
  const calculateAverage = (items: number[]) => {
    if (items && items.length > 0) {
      const sum = items.reduce((acc, current) => acc + current, 0);
      return Math.round(sum / items.length);
    }
    return 0;
  };

  /**
   * Returns the max of a list of numbers
   * @param items
   * @returns
   */
  const calculateMax = (items: number[]) => {
    return Math.max(...items);
  };

  /**
   * Returns a chart drawing
   * @param chartData
   * @returns
   */
  const getCharts = (chartData: any[][]) => {
    return (
      <div className="chart-content">
        <Chart
          chartType="LineChart"
          width="100%"
          height="200px"
          data={chartData}
          options={chartOptions}
        />
      </div>
    );
  };

  const getChartsSection = () => {
    const chartData = formatChartData();
    if (filterFrom && filterTo) {
      if (filterFrom.getTime() > filterTo.getTime()) {
        return (
          <div>
            <div className="chart-placeholder">
              <div className="Status">Invalid date range filter</div>
            </div>
          </div>
        );
      }
    }
    return (
      <div>
        {chartData.length > 0 ? (
          getCharts(chartData)
        ) : (
          <div className="chart-placeholder">
            <div className="Status">No data for this date range</div>
          </div>
        )}
      </div>
    );
  };

  return getChartsSection();
};

export default LineChart;
