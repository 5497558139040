import React, { FC, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Icon, Image } from "@vacasa/react-components-lib";
import { ReadingAlert } from "../../models/SmartDeviceApi";

import SmartDeviceService from "../../services/smart-device.service";
import Alert from "../DeviceMonitoring/MonitoringDeviceAlerts/Alert/Alert";

import "./DeviceMonitoringAlerts.scss";
import BasicButton from "../../elements/Button/BasicButton";
import FormInput from "../../elements/Form";
import { useFormValue } from "../../hooks/FormValue";
import { PropertyStore } from "../../store/property";

const DeviceMonitoringAlerts: FC = () => {
  const now = new Date();
  now.setHours(23, 59, 59, 999);

  const defaultStart = new Date(now);
  defaultStart.setDate(defaultStart.getDate() - 5);
  defaultStart.setHours(0, 0, 0, 0);

  const absoluteMin = new Date(now);
  absoluteMin.setDate(absoluteMin.getDate() - 30);
  absoluteMin.setHours(0, 0, 0, 0);

  const { search } = useLocation();
  const property = PropertyStore.selectors.usePropertyData();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>();

  const alerts = useRef<ReadingAlert[]>([]);
  const [page, setPage] = useState<string>();
  const [nextPage, setNextPage] = useState<string>();

  const [minDate, setMinDate] = useState<Date | undefined>(defaultStart);
  const [maxDate, setMaxDate] = useState<Date | undefined>(now);

  const fromDate = useFormValue<Date>(defaultStart, true);
  const toDate = useFormValue<Date>(now, true);

  // Load noise alerts
  useEffect(() => {
    if (!property) return;

    const service = new SmartDeviceService();
    setIsLoading(true);
    setError(undefined);
    service
      .getAlerts(property.id, page, minDate, maxDate)
      .then((response) => {
        alerts.current = alerts.current.concat(response.data);
        setNextPage(response.nextPage);
      })
      .catch((err) => setError(err.message))
      .finally(() => setIsLoading(false));
  }, [property, page, minDate, maxDate]);

  // Reload data whenever dates change
  useEffect(() => {
    if (fromDate.isValid && toDate.isValid) {
      alerts.current = [];
      setPage(undefined);
      setNextPage(undefined);

      setMinDate(fromDate.value);
      setMaxDate(toDate.value);
    }
  }, [fromDate.value, fromDate.isValid, toDate.value, toDate.isValid]);

  const handleLoadNextPage = () => {
    if (nextPage) {
      setPage(nextPage);
      setNextPage(undefined);
    }
  };

  const getAlertTable = () => {
    if (!alerts.current.length && isLoading) return undefined;
    else if (!alerts.current.length)
      return <div className="no-data">No data</div>;
    else {
      return (
        <div className="alert-list">
          {alerts.current.map((alert, index) => (
            <div key={index} className="alert-row">
              <Alert alert={alert} />
            </div>
          ))}
        </div>
      );
    }
  };

  const getFooter = () => {
    return (
      <div>
        {isLoading && (
          <div className="spinner">
            <Image.Spinner width={36} height={36} />
          </div>
        )}
        {nextPage && !isLoading && (
          <BasicButton display="outline" onClick={handleLoadNextPage}>
            Load More Alerts
          </BasicButton>
        )}
        <div className="error-message">{error}</div>
      </div>
    );
  };

  return (
    <div className="component-sound-monitoring-alerts">
      <div className="card">
        <div className="card-container">
          <div className="header">
            <div className="back">
              <Link to={"./.." + search} className="back-btn">
                <Icon.ArrowLeft className="icon" />
                Back
              </Link>
            </div>
            <span className="title">Alert History</span>
            <span className="date-filters">
              <FormInput.Date
                label="FROM"
                value={fromDate}
                min={absoluteMin}
                display="compact"
                required
              />
              <FormInput.Date
                label="TO"
                value={toDate}
                endOfDay={true}
                display="compact"
                required
              />
            </span>
          </div>
          <div className="body">{getAlertTable()}</div>
          <div className="footer">{getFooter()}</div>
        </div>
      </div>
    </div>
  );
};

export default DeviceMonitoringAlerts;
