import React, { FC, useEffect, useState } from "react";
import {
  BackendResource,
  DeviceType,
  Vendor,
} from "../../../../../models/SmartHomeApi";
import SelectList from "../../../../../elements/Form/SelectList";
import { useFormValue } from "../../../../../hooks/FormValue";
import { parseResource } from "../../lib/parse-resource";
import { isLockType } from "../../../../../models/DeviceTypeEnum";
import { NON_LOCK_VENDORS } from "../../../../../core/constants";
import StepFooter from "../StepFooter/StepFooter";

interface SelectVendorStepProps {
  vendors?: BackendResource<Vendor>[];
  deviceType?: BackendResource<DeviceType>;
  onPrevious: () => void;
  onSubmit: (vendor: BackendResource<Vendor>) => void;
}

const SelectVendorStep: FC<SelectVendorStepProps> = ({
  vendors,
  deviceType,
  onPrevious,
  onSubmit,
}) => {
  const vendorForm = useFormValue<number>(undefined, false);
  const [vendorOptions, setVendorOptions] = useState(
    vendors?.map(parseResource),
  );

  // filter available vendors based on type of device
  useEffect(() => {
    let filtered = vendors?.map(parseResource);
    if (deviceType) {
      if (isLockType(deviceType.id!))
        filtered = filtered?.filter(
          (x) => !NON_LOCK_VENDORS.includes(x.displayText),
        );
      else
        filtered = filtered?.filter((x) =>
          NON_LOCK_VENDORS.includes(x.displayText),
        );
    }
    setVendorOptions(filtered);
  }, [vendors, deviceType]);

  const handlePrevious = () => {
    vendorForm.reset();
    onPrevious();
  };

  const handleSubmit = () => {
    if (vendorForm.isValid) {
      const vendor = vendors?.find((x) => x.id === vendorForm.value);
      if (vendor) {
        onSubmit(vendor);
      }
    }
  };

  return (
    <div className="select-list-wrapper">
      <SelectList
        title="New Smart Device"
        description="SELECT DEVICE ACCOUNT"
        value={vendorForm}
        options={vendorOptions ?? []}
        displayFilter={true}
        filterPlaceholder="search vendors"
      />
      <StepFooter
        onSubmit={handleSubmit}
        onPrevious={handlePrevious}
        disabled={!vendorForm.isValid}
      />
    </div>
  );
};

export default SelectVendorStep;
