import AbstractService from "./abstract.service";
import { SOUND_MONITORING_API } from "../core/constants";
import {
  AddAlertSubscriberRequest,
  AlertSubscriber,
  ReadingAlert,
  Login,
  Page,
  RegisteredDevice,
  RegisteredDeviceType,
  RemoveAlertSubscriberRequest,
  RouterReadingItem,
  SensorReadingItem,
  Unit,
  UpdateAlertSubscriberRequest,
} from "../models/SmartDeviceApi";
import AuthService from "./auth.service";

class SmartDeviceService extends AbstractService {
  constructor() {
    super(SOUND_MONITORING_API);
  }

  protected getDefaultHeaders() {
    const headers = super.getDefaultHeaders();
    const identity = AuthService.idToken;
    return {
      ...headers,
      IdentityToken: identity || "",
    };
  }

  async getDevicesByPropertyId(
    propertyId: string,
    signal?: AbortSignal,
  ): Promise<RegisteredDevice[]> {
    const url = `/devices?deviceGroup=${propertyId}`;
    const result = await this.get<RegisteredDevice[]>(url, {
      signal,
    });
    return result ?? [];
  }

  async getDevice(
    macAddress: string,
    type: RegisteredDeviceType,
    signal?: AbortSignal,
  ): Promise<RegisteredDevice | null> {
    const url = `/devices/${macAddress}?deviceType=${type}`;
    const result = await this.get<RegisteredDevice>(url, {
      signal,
    });
    return result;
  }

  async registerDevice(request: RegisteredDevice): Promise<RegisteredDevice> {
    return await this.post<RegisteredDevice>(`/devices`, request);
  }

  async updateDevice(request: RegisteredDevice): Promise<RegisteredDevice> {
    return await this.put<RegisteredDevice>(
      `/devices/${request.MacAddress}`,
      request,
    );
  }

  async unregisterDevice(
    macAddress: string,
    type: RegisteredDeviceType,
    signal?: AbortSignal,
  ): Promise<RegisteredDevice> {
    const url = `/devices/${macAddress}?deviceType=${type}`;
    return await this.delete<RegisteredDevice>(url, { signal });
  }

  async getSensorReadings(
    macAddress: string,
    from?: Date,
    to?: Date,
  ): Promise<SensorReadingItem[]> {
    return this.getDeviceReadings(
      macAddress,
      RegisteredDeviceType.Sensor,
      from,
      to,
    ) as Promise<SensorReadingItem[]>;
  }

  async getRouterReadings(
    macAddress: string,
    from?: Date,
    to?: Date,
  ): Promise<RouterReadingItem[]> {
    return this.getDeviceReadings(
      macAddress,
      RegisteredDeviceType.Router,
      from,
      to,
    ) as Promise<RouterReadingItem[]>;
  }

  private async getDeviceReadings(
    macAddress: string,
    deviceType: RegisteredDeviceType,
    from?: Date,
    to?: Date,
    page?: string,
    recurseLimit = 5,
  ): Promise<(SensorReadingItem | RouterReadingItem)[]> {
    if (recurseLimit <= 0) return [];

    const url = `/device-readings/${macAddress}`;
    const params = new URLSearchParams({
      deviceType,
    });
    if (from) params.append("from", from.toISOString());
    if (to) params.append("to", to.toISOString());
    if (page) params.append("page", page);

    const result = (await this.get<Page<SensorReadingItem | RouterReadingItem>>(
      `${url}?${params.toString()}`,
    ))!;

    const readings =
      (result.data as (SensorReadingItem | RouterReadingItem)[]) || [];
    const nextPageId: string | undefined = result.nextPage;

    if (nextPageId && nextPageId.length > 0) {
      const nextPageOfData = await this.getDeviceReadings(
        macAddress,
        deviceType,
        from,
        to,
        result.nextPage,
        recurseLimit - 1,
      );
      return readings.concat(nextPageOfData);
    }

    return readings;
  }

  async getAlerts(
    propertyId: string,
    page?: string,
    from?: Date,
    to?: Date,
    signal?: AbortSignal,
  ): Promise<Page<ReadingAlert>> {
    const params = new URLSearchParams({
      deviceGroup: propertyId,
    });
    if (page) params.append("page", page);
    if (from) params.append("from", from.toISOString());
    if (to) params.append("to", to.toISOString());

    const url = `/alerts?${params.toString()}`;
    const result = await this.get<Page<ReadingAlert>>(url, { signal });
    return result!;
  }

  async getAlertSubscribersByDeviceGroup(
    propertyId: string,
    signal?: AbortSignal,
  ): Promise<AlertSubscriber[]> {
    const url = `/alert-subscribers/by-device-group/${propertyId}`;
    const result = await this.get<AlertSubscriber[]>(url, { signal });
    return result!;
  }

  async addAlertSubscriber(
    request: AddAlertSubscriberRequest,
  ): Promise<AlertSubscriber> {
    const result = await this.post<AlertSubscriber>(
      `/alert-subscribers/add`,
      request,
    );
    return result;
  }

  async updateAlertSubscriber(
    request: UpdateAlertSubscriberRequest,
  ): Promise<AlertSubscriber> {
    const result = await this.post<AlertSubscriber>(
      `/alert-subscribers/update`,
      request,
    );
    return result;
  }

  async removeAlertSubscriber(
    request: RemoveAlertSubscriberRequest,
  ): Promise<void> {
    await this.post(`/alert-subscribers/remove`, request);
  }

  async getLoginByEmailAddress(
    emailAddress: string,
    signal?: AbortSignal,
  ): Promise<Login | null> {
    const url = `/logins/by-email-address/${emailAddress}`;
    const result = await this.get<Login>(url, { signal });
    return result;
  }

  async getUnit(unitId: number): Promise<Unit | null> {
    const url = `/units/${unitId}`;
    const result = await this.get<Unit>(url);
    return result;
  }
}

export default SmartDeviceService;
