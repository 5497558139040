import { Image, Tooltip } from "@vacasa/react-components-lib";
import { ICellRendererParams } from "ag-grid-community";
import React, { FC, useEffect, useState } from "react";
import DeviceTableRow from "../../../../models/DeviceTableRow";
import SmartHomeService from "../../../../services/smarthome.service";

import styles from "./DeviceStatusRenderer.module.scss";

const DeviceStatusRenderer: FC<ICellRendererParams> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<string>(props.value);
  const [description, setDescription] = useState<string>();
  const defaultStatus = "--";

  useEffect(() => {
    if (status) return;

    const smartHomeService = new SmartHomeService();
    const updateStatus = function (statusValue: string, message?: string) {
      if (message) {
        setDescription(message);
      }
      props.setValue?.(statusValue);
      setStatus(statusValue);
      setIsLoading(false);
    };

    const rowData = props.data as DeviceTableRow;
    if (!rowData.externalId || !rowData.vendorId) return;

    // load device status from backend
    setIsLoading(true);
    smartHomeService
      .verifyDeviceStatus(rowData.externalId, rowData.vendorId)
      .then((res) => {
        const data = res.data[0];
        let nextStatus = defaultStatus;
        if (data.type === "success") nextStatus = "Ok";

        updateStatus(nextStatus, data.message);
      })
      .catch((err) => {
        updateStatus(defaultStatus, err.message);
      });
  }, [props, status]);

  return isLoading ? (
    <Image.Spinner width={26} height={26} />
  ) : (
    <div className={styles.DeviceStatusRenderer}>
      <Tooltip message={description || "unknown"}>
        <span>{status}</span>
      </Tooltip>
    </div>
  );
};

export default DeviceStatusRenderer;
