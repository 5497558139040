import React, { FC, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./NavBar.scss";

export interface NavItem {
  display: string;
  link: string;
}

interface NavBarProps {
  navItems: { link: string; display: string }[];
  onChange?: (route: NavItem) => void;
}

const NavBar: FC<NavBarProps> = (props) => {
  const { pathname, search } = useLocation();
  const [route, setRoute] = useState<string>();
  const [showMobile, setShowMobile] = useState(false);

  // Track url changes and notify parent
  useEffect(() => {
    const navItem = props.navItems.find((x) => !!pathname.match(x.link));
    if (navItem) {
      setRoute(navItem.link);
      setShowMobile(false);
      props.onChange?.(navItem);
    }
  }, [pathname, props]);

  const toggleMobile = () => {
    setShowMobile(!showMobile);
  };

  const activeClass = showMobile ? "active" : "inactive";
  return (
    <nav className={`nav-bar ${activeClass}`}>
      <div className={`hamburger ${activeClass}`} onClick={toggleMobile}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>
      <div className={`nav-items ${activeClass}`}>
        {props.navItems.map((navItem) => {
          return (
            <Link
              key={navItem.link}
              to={navItem.link + search}
              className={`nav-item ${route === navItem.link && "active"}`}
            >
              {navItem.display}
            </Link>
          );
        })}
      </div>
    </nav>
  );
};

export default NavBar;
