import React, { FC } from "react";

import "./UnauthorizedMessage.scss";

interface UnauthorizedMessageProps {
  tabName: string;
}

const UnauthorizedMessage: FC<UnauthorizedMessageProps> = (props) => {
  return (
    <div className="component-unauthorized-message">
      <div className="title">Unauthorized</div>
      <div className="message">
        Sorry, but you don't have the correct permissions to access the{" "}
        {props.tabName} tab. Please contact your manager for help getting the
        correct permissions.
      </div>
    </div>
  );
};

export default UnauthorizedMessage;
