import { Icon } from "@vacasa/react-components-lib";
import React, { FC, useEffect, useRef, useState } from "react";
import BasicButton from "../../../../elements/Button/BasicButton";
import SelectList from "../../../../elements/Form/SelectList";
import { useFormValue } from "../../../../hooks/FormValue";
import { SelectOption } from "../../../../models/Form";
import { RegisteredDeviceType } from "../../../../models/SmartDeviceApi";

import "./SelectDeviceType.scss";

interface SelectDeviceTypeProps {
  compatibleDevices?: RegisteredDeviceType[];
  onSubmit?: (type: RegisteredDeviceType) => void;
}

const SelectDeviceType: FC<SelectDeviceTypeProps> = (props) => {
  const selected = useFormValue<RegisteredDeviceType>(undefined, false);
  const allDeviceTypes = useRef<SelectOption[]>([
    {
      icon: <Icon.Radio />,
      value: RegisteredDeviceType.Sensor,
      displayText: "Sensor",
    },
    {
      icon: <Icon.Wifi />,
      value: RegisteredDeviceType.Router,
      displayText: "Router",
    },
  ]);
  const [deviceTypes, setDeviceTypes] = useState(allDeviceTypes.current);

  useEffect(() => {
    if (!props.compatibleDevices) setDeviceTypes(allDeviceTypes.current);
    else {
      const compatibleDevices: SelectOption[] = [];
      for (let i = 0; i < allDeviceTypes.current.length; i++) {
        const deviceType = allDeviceTypes.current[i].value;
        if (props.compatibleDevices.includes(deviceType)) {
          compatibleDevices.push(allDeviceTypes.current[i]);
        }
      }
      setDeviceTypes(compatibleDevices);
    }
  }, [props.compatibleDevices]);

  const handleSubmit = () => {
    if (selected.isValid) {
      props.onSubmit?.(selected.value!);
    }
  };

  return (
    <div className="component-add-monitoring-device-select-type">
      <div className="select-device-type-list">
        <SelectList
          description="Selece Device Type"
          value={selected}
          options={deviceTypes}
        />
      </div>
      <div className="select-device-type-footer">
        <BasicButton
          display="fill"
          onClick={handleSubmit}
          disabled={!selected.isValid}
        >
          Next
        </BasicButton>
      </div>
    </div>
  );
};

export default SelectDeviceType;
