export interface Property {
  id: string;
  attributes: {
    active: number;
    e_lock_active: number;
    address: {
      address_1: string;
      address_2?: string;
      city: string;
      state: string;
      zip: string;
      country: {
        name: string;
        code: string;
      };
    };
    code: string;
    housing_type: string;
    max_occupancy: number;
    name: string;
    created_at: string;
    timezone: string;
  };
}

export interface Amenity {
  type: string;
  id: string;
  attributes: {
    unit_id: number;
    amenity_id: number;
    provider_name: string | null;
    amenity_value: number | null;
    unit_code: string;
    provider_id: string | null;
    amenity_name: string;
    display_name: string;
    category: string;
    amenity_option: string | null;
    instructions: string | null;
    internal_notes: string | null;
    notes: string | null;
    is_public: boolean;
    is_shared: boolean;
    shared_text: string | null;
  };
}

export interface CreateAmenityBody extends UpdateAmenityBody {
  amenity_id: number;
  unit_id: number;
}
export interface UpdateAmenityBody {
  amenity_value: number | null;
  internal_notes: string | null;
  instructions: string | null;
  notes: string | null;
  provider_id: string | null;
}

export enum AmenityType {
  NoiseSensor = 645,
}
