export enum RegisteredDeviceType {
  Sensor = "Sensor",
  Router = "Router",
}

export enum AlertType {
  SensorNoiseAlert = "SensorNoiseAlert",
  SensorTemperatureAlert = "SensorTemperatureAlert",
  RouterConnectedAlert = "RouterConnectedAlert",
}

export type RegisteredDevice = RegisteredSensor | RegisteredRouter;
export interface BaseDevice {
  MacAddress: string;
  RegistrationDate: string;
  DeviceGroup: string;
  DeviceName: string;
  AlertsPauseUntilDate: string | null;
  UpdatedByEmailAddress: string;
  UpdatedDate: string;
}

export interface RegisteredSensor extends BaseDevice {
  DeviceType: RegisteredDeviceType.Sensor;
  NoiseAlertDecibelThreshold: number;
  TemperatureLowThreshold: number;
  TemperatureHighThreshold: number;
  RecentReading?: SensorReading;
}

export interface RegisteredRouter extends BaseDevice {
  DeviceType: RegisteredDeviceType.Router;
  ConnectedAlertThreshold: number;
  RecentReading?: RouterReading;
}

export interface SensorReading {
  MacAddress: string;
  Latitude: number;
  Longitude: number;
  ReadingDate: string;
  Decibels: number;
  Temperature: number;
}

export interface RouterReading {
  MacAddress: string;
  Address: string;
  ReadingDate: string;
  Connected: number;
  Visible: number;
}

export interface SensorReadingItem {
  ReadingDate: string;
  Decibels: number;
  Temperature: number;
  IsFahrenheit?: boolean;
}

export interface RouterReadingItem {
  ReadingDate: string;
  Connected: number;
  Visible: number;
}

export type ReadingAlert =
  | SensorNoiseAlert
  | SensorTemperatureAlert
  | RouterConnectedAlert;
export interface BaseAlert {
  AlertType: AlertType;
  DeviceGroup: string; // Dynamo partition key
  AlertDate: string; // Dynamo sort key
  Notifications: AlertNotification[];
  AlertUnit?: Unit;
  CurrentPeriodAlertCount: number;
}

export interface SensorNoiseAlert extends BaseAlert {
  AlertType: AlertType.SensorNoiseAlert;
  Contributors: SensorNoiseAlertContributor[];
}

export interface SensorTemperatureAlert extends BaseAlert {
  AlertType: AlertType.SensorTemperatureAlert;
  Contributors: SensorTemperatureAlertContributor[];
}

export interface RouterConnectedAlert extends BaseAlert {
  AlertType: AlertType.RouterConnectedAlert;
  Contributors: RouterConnectedAlertContributor[];
}

export interface SensorNoiseAlertContributor {
  MacAddress: string;
  DeviceName?: string;
  NoiseAlertDecibelThreshold: number;
  CustomNoiseAlertDecibelThreshold: boolean;
  Readings: SensorReadingItem[];
}

export interface SensorTemperatureAlertContributor {
  MacAddress: string;
  DeviceName?: string;
  TemperatureLowThreshold: number;
  TemperatureHighThreshold: number;
  Readings: SensorReadingItem[];
}

export interface RouterConnectedAlertContributor {
  MacAddress: string;
  DeviceName?: string;
  ConnectedAlertThreshold: number;
  Readings: RouterReadingItem[];
}

export interface AlertNotification {
  NotificationDate: string;
  Recipient: Login;
  NsStatusText: string;
  NsType: string; // email or sms
  NsId?: number; // Notification Service Notification ID
  NsIdentifier?: string; // Template identifier
  NsCreatedDate?: string;
  NsSentDate?: string; // final step for email
  NsDeliveredDate?: string; // final step for sms
  NsBounced?: boolean;
  NsTo?: string; // email address or phone number
}

export interface Login {
  Id: number;
  FirstName: string;
  LastName: string;
  EmailAddress: string;
  Phone: string;
}

export interface AlertSubscriber {
  DeviceGroup: string;
  Login: Login;
  SendEmail: boolean;
  SendSms: boolean;
  SensorNoiseAlert: boolean;
  SensorTemperatureAlert: boolean;
  RouterConnectedAlert: boolean;
  UpdatedDate: string;
}

export interface AddAlertSubscriberRequest {
  DeviceGroup: string;
  LoginId: number;
  SendEmail: boolean;
  SendSms: boolean;
  SensorNoiseAlert: boolean;
  SensorTemperatureAlert: boolean;
  RouterConnectedAlert: boolean;
}

export interface UpdateAlertSubscriberRequest {
  DeviceGroup: string;
  LoginId: number;
  SendEmail: boolean;
  SendSms: boolean;
  SensorNoiseAlert: boolean;
  SensorTemperatureAlert: boolean;
  RouterConnectedAlert: boolean;
}

export interface RemoveAlertSubscriberRequest {
  DeviceGroup: string;
  LoginId: number;
}

export interface Unit {
  Id: number;
  Code: string;
  Name: string;
  MaxOccupancy: number;
}

export interface Page<T> {
  data: T[];
  nextPage: string | undefined;
}
