import { slice } from "./reducer";
import * as selectors from "./selector";

export interface FeatureFlagState {
  [key: string]: boolean;
}

export const FeatureFlagStore = {
  reducer: slice.reducer,
  actions: slice.actions,
  selectors,
};
