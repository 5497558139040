import { useState, useEffect, FC, useCallback } from "react";
import SmartDeviceService from "../../../services/smart-device.service";
import {
  SensorReadingItem,
  RouterReadingItem,
} from "../../../models/SmartDeviceApi";
import { celsiusToFahrenheit } from "../../../core/unit.converter";
import { ChartDataSeries } from "../../../elements/Chart/types";

import "./Chart.scss";

import BasicChart from "./BasicChart";
import DateRangeChart from "./DateRangeChart";

export interface SensorItem {
  DeviceName: string;
  MacAddress: string;
}

export interface SensorChartData {
  device: SensorItem;
  reading: SensorReadingItem;
}

export interface RouterChartData {
  device: SensorItem;
  reading: RouterReadingItem;
}

interface SensorChartsProps {
  sensors?: SensorItem[];
  routers?: SensorItem[];
  error?: string;
  timezone?: string;
}

const DeviceCharts: FC<SensorChartsProps> = (props) => {
  const { sensors, routers } = props;

  const defaultFilterTo = new Date();
  defaultFilterTo.setHours(23, 59, 59, 999);

  const defaultFilterFrom = new Date(defaultFilterTo);
  defaultFilterFrom.setHours(0, 0, 0, 0);
  defaultFilterFrom.setDate(defaultFilterFrom.getDate() - 1);

  const absoluteMinFilterDate = new Date(defaultFilterFrom);
  absoluteMinFilterDate.setDate(absoluteMinFilterDate.getDate() - 89);

  const absoluteMaxFilterDate = new Date(defaultFilterTo);

  // Recent Sensor Data (noise & temperature)
  const [isLoadingRecentSensors, setIsLoadingRecentSensors] = useState(false);
  const [refreshRecentSensorsAt, setRefreshRecentSensorsAt] = useState(
    new Date(),
  );
  const [recentSensorReadings, setRecentSensorReadings] = useState<
    SensorChartData[]
  >([]);
  const [recentNoise, setRecentNoise] = useState<ChartDataSeries>();
  const [recentTemperature, setRecentTemperature] = useState<ChartDataSeries>();
  const [recentSensorError, setRecentSensorError] = useState<string>();

  // Historic Noise Data
  const [isLoadingNoise, setIsLoadingNoise] = useState(false);
  const [historicNoiseReadings, setHistoricNoiseReadings] = useState<
    SensorChartData[]
  >([]);
  const [historicNoiseChartData, setHistoricNoiseChartData] =
    useState<ChartDataSeries>();
  const [noiseFilterTo, setNoiseFilterTo] = useState(defaultFilterTo);
  const [noiseFilterFrom, setNoiseFilterFrom] = useState(defaultFilterFrom);
  const [refreshNoiseAt, setRefreshNoiseAt] = useState(new Date());
  const [noiseError, setNoiseError] = useState<string>();

  // Historic Temperature Data
  const [isLoadingTemperature, setIsLoadingTemperature] = useState(false);
  const [historicTemperatureReadings, setHistoricTemperatureReadings] =
    useState<SensorChartData[]>([]);
  const [historicTemperatureChartData, setHistoricTemperatureChartData] =
    useState<ChartDataSeries>();
  const [temperatureFilterTo, setTemperatureFilterTo] =
    useState(defaultFilterTo);
  const [temperatureFilterFrom, setTemperatureFilterFrom] =
    useState(defaultFilterFrom);
  const [refreshTemperatureAt, setRefreshTemperatureAt] = useState(new Date());
  const [temperatureError, setTemperatureError] = useState<string>();

  // Recent Router Data
  const [isLoadingRecentRouters, setIsLoadingRecentRouters] = useState(false);
  const [recentRouterReadings, setRecentRouterReadings] = useState<
    RouterChartData[]
  >([]);
  const [recentRouterChartData, setRecentRouterChartData] =
    useState<ChartDataSeries>();
  const [refreshRecentRoutersAt, setRefreshRecentRoutersAt] = useState(
    new Date(),
  );
  const [recentRouterError, setRecentRouterError] = useState<string>();

  // Historic Router Data
  const [isLoadingRouter, setIsLoadingRouter] = useState(false);
  const [historicRouterReadings, setHistoricRouterReadings] = useState<
    RouterChartData[]
  >([]);
  const [historicRouterChartData, setHistoricRouterChartData] =
    useState<ChartDataSeries>();
  const [routerFilterTo, setRouterFilterTo] = useState(defaultFilterTo);
  const [routerFilterFrom, setRouterFilterFrom] = useState(defaultFilterFrom);
  const [refreshRouterAt, setRefreshRouterAt] = useState(new Date());
  const [routerError, setRouterError] = useState<string>();

  // Loads recent sensor readings given list of sensor devices
  useEffect(() => {
    if (!sensors) return;

    const twoHoursAgo = new Date();
    twoHoursAgo.setHours(twoHoursAgo.getHours() - 2);

    // Loads device readings for each given sensor
    const service = new SmartDeviceService();
    const promises = sensors.map((sensor) =>
      service.getSensorReadings(sensor.MacAddress, twoHoursAgo),
    );
    setIsLoadingRecentSensors(true);
    setRecentSensorError(undefined);
    Promise.all(promises)
      .then((responses) => {
        let allData: SensorChartData[] = [];
        responses.forEach((data, index) => {
          const readings = data.map((reading) => ({
            device: sensors[index],
            reading,
          }));
          allData = allData.concat(readings);
        });
        setRecentSensorReadings(allData);
      })
      .catch((err) => setRecentSensorError(err.message))
      .finally(() => setIsLoadingRecentSensors(false));
  }, [sensors, refreshRecentSensorsAt]);

  // Parses recent sensor readings into chart data for noise and temp
  useEffect(() => {
    if (!recentSensorReadings) return;
    const noiseData: ChartDataSeries = {};
    const temperatureData: ChartDataSeries = {};

    recentSensorReadings.forEach((recent) => {
      const { device, reading } = recent;

      if (!noiseData[device.DeviceName]) noiseData[device.DeviceName] = [];
      if (!temperatureData[device.DeviceName])
        temperatureData[device.DeviceName] = [];

      noiseData[device.DeviceName].push({
        timestamp: new Date(reading.ReadingDate),
        value: reading.Decibels,
      });
      temperatureData[device.DeviceName].push({
        timestamp: new Date(reading.ReadingDate),
        value: reading.IsFahrenheit
          ? reading.Temperature
          : celsiusToFahrenheit(reading.Temperature),
      });
    });

    setRecentNoise(noiseData);
    setRecentTemperature(temperatureData);
  }, [recentSensorReadings]);

  // Loads historic noise readings
  useEffect(() => {
    if (!sensors) return;

    // Loads device readings for each given sensor
    const service = new SmartDeviceService();
    const promises = sensors.map((sensor) =>
      service.getSensorReadings(
        sensor.MacAddress,
        noiseFilterFrom,
        noiseFilterTo,
      ),
    );
    setIsLoadingNoise(true);
    setNoiseError(undefined);
    Promise.all(promises)
      .then((responses) => {
        let allData: SensorChartData[] = [];
        responses.forEach((data, index) => {
          const readings = data.map((reading) => ({
            device: sensors[index],
            reading,
          }));
          allData = allData.concat(readings);
        });
        setHistoricNoiseReadings(allData);
      })
      .catch((err) => setNoiseError(err.message))
      .finally(() => setIsLoadingNoise(false));
  }, [sensors, noiseFilterFrom, noiseFilterTo, refreshNoiseAt]);

  // Parses historic noise readings into chart data
  useEffect(() => {
    if (!historicNoiseReadings) return;
    const noiseData: ChartDataSeries = {};

    historicNoiseReadings.forEach((sensorReading) => {
      const { device, reading } = sensorReading;

      if (!noiseData[device.DeviceName]) noiseData[device.DeviceName] = [];

      noiseData[device.DeviceName].push({
        timestamp: new Date(reading.ReadingDate),
        value: reading.Decibels,
      });
    });

    setHistoricNoiseChartData(noiseData);
  }, [historicNoiseReadings]);

  // Loads historic temperature readings
  useEffect(() => {
    if (!sensors) return;

    // Loads device readings for each given sensor
    const service = new SmartDeviceService();
    const promises = sensors.map((sensor) =>
      service.getSensorReadings(
        sensor.MacAddress,
        temperatureFilterFrom,
        temperatureFilterTo,
      ),
    );
    setIsLoadingTemperature(true);
    setTemperatureError(undefined);
    Promise.all(promises)
      .then((responses) => {
        let allData: SensorChartData[] = [];
        responses.forEach((data, index) => {
          const readings = data.map((reading) => ({
            device: sensors[index],
            reading,
          }));
          allData = allData.concat(readings);
        });
        setHistoricTemperatureReadings(allData);
      })
      .catch((err) => setTemperatureError(err.message))
      .finally(() => setIsLoadingTemperature(false));
  }, [
    sensors,
    temperatureFilterFrom,
    temperatureFilterTo,
    refreshTemperatureAt,
  ]);

  // Parses historic noise readings into chart data
  useEffect(() => {
    if (!historicTemperatureReadings) return;
    const noiseData: ChartDataSeries = {};

    historicTemperatureReadings.forEach((sensorReading) => {
      const { device, reading } = sensorReading;

      if (!noiseData[device.DeviceName]) noiseData[device.DeviceName] = [];

      noiseData[device.DeviceName].push({
        timestamp: new Date(reading.ReadingDate),
        value: reading.IsFahrenheit
          ? reading.Temperature
          : celsiusToFahrenheit(reading.Temperature),
      });
    });

    setHistoricTemperatureChartData(noiseData);
  }, [historicTemperatureReadings]);

  // Loads recent router readings given list of router devices
  useEffect(() => {
    if (!routers) return;

    const twoHoursAgo = new Date();
    twoHoursAgo.setHours(twoHoursAgo.getHours() - 2);

    // Loads device readings for each given sensor
    const service = new SmartDeviceService();
    const promises = routers.map((router) =>
      service.getRouterReadings(router.MacAddress, twoHoursAgo),
    );
    setIsLoadingRecentRouters(true);
    setRecentRouterError(undefined);
    Promise.all(promises)
      .then((responses) => {
        let allData: RouterChartData[] = [];
        responses.forEach((data, index) => {
          const readings = data.map((reading) => ({
            device: routers[index],
            reading,
          }));
          allData = allData.concat(readings);
        });
        setRecentRouterReadings(allData);
      })
      .catch((err) => setRecentRouterError(err.message))
      .finally(() => setIsLoadingRecentRouters(false));
  }, [routers, refreshRecentRoutersAt]);

  // Parses recent router readings into chart data
  useEffect(() => {
    if (!recentRouterReadings) return;
    const chartData: ChartDataSeries = {};

    recentRouterReadings.forEach((recent) => {
      const { device, reading } = recent;

      if (!chartData[device.DeviceName]) chartData[device.DeviceName] = [];

      chartData[device.DeviceName].push({
        timestamp: new Date(reading.ReadingDate),
        value: reading.Connected,
      });
    });

    setRecentRouterChartData(chartData);
  }, [recentRouterReadings]);

  // Loads historic router readings
  useEffect(() => {
    if (!routers) return;

    // Loads device readings for each given sensor
    const service = new SmartDeviceService();
    const promises = routers.map((router) =>
      service.getRouterReadings(
        router.MacAddress,
        routerFilterFrom,
        routerFilterTo,
      ),
    );
    setIsLoadingRouter(true);
    setRouterError(undefined);
    Promise.all(promises)
      .then((responses) => {
        let allData: RouterChartData[] = [];
        responses.forEach((data, index) => {
          const readings = data.map((reading) => ({
            device: routers[index],
            reading,
          }));
          allData = allData.concat(readings);
        });
        setHistoricRouterReadings(allData);
      })
      .catch((err) => setRouterError(err.message))
      .finally(() => setIsLoadingRouter(false));
  }, [routers, routerFilterFrom, routerFilterTo, refreshRouterAt]);

  // Parses historic router readings into chart data
  useEffect(() => {
    if (!historicRouterReadings) return;
    const routerData: ChartDataSeries = {};

    historicRouterReadings.forEach((sensorReading) => {
      const { device, reading } = sensorReading;

      if (!routerData[device.DeviceName]) routerData[device.DeviceName] = [];

      routerData[device.DeviceName].push({
        timestamp: new Date(reading.ReadingDate),
        value: reading.Connected,
      });
    });

    setHistoricRouterChartData(routerData);
  }, [historicRouterReadings]);

  const handleNoiseDateChange = useCallback(
    (filterFrom: Date, filterTo: Date) => {
      setNoiseFilterFrom(filterFrom);
      setNoiseFilterTo(filterTo);
    },
    [],
  );
  const handleTemperatureDateChange = useCallback(
    (filterFrom: Date, filterTo: Date) => {
      setTemperatureFilterFrom(filterFrom);
      setTemperatureFilterTo(filterTo);
    },
    [],
  );
  const handleRouterDateChange = useCallback(
    (filterFrom: Date, filterTo: Date) => {
      setRouterFilterFrom(filterFrom);
      setRouterFilterTo(filterTo);
    },
    [],
  );

  const handleRefreshRecentSensors = () => {
    setRefreshRecentSensorsAt(new Date());
  };
  const handleRefreshNoise = () => {
    setRefreshNoiseAt(new Date());
  };
  const handleRefreshTemperature = () => {
    setRefreshTemperatureAt(new Date());
  };
  const handleRefreshRecentRouters = () => {
    setRefreshRecentRoutersAt(new Date());
  };
  const handleRefreshRouters = () => {
    setRefreshRouterAt(new Date());
  };

  const getSensorCharts = () => {
    if (!sensors?.length) return undefined;
    return (
      <>
        <BasicChart
          chartType="line"
          cardTitle="Sound Monitor Current Activity"
          chartTitle="Decibel Readings"
          data={recentNoise}
          loading={isLoadingRecentSensors}
          error={props.error || recentSensorError}
          onRefreshClick={handleRefreshRecentSensors}
          timezone={props.timezone}
        />
        <DateRangeChart
          chartType="line"
          cardTitle="Sound Monitor Historical Activity"
          chartTitle="Decibel Readings"
          data={historicNoiseChartData}
          loading={isLoadingNoise}
          error={props.error || noiseError}
          onRefreshClick={handleRefreshNoise}
          onDateRangeChange={handleNoiseDateChange}
          filterFrom={noiseFilterFrom}
          filterTo={noiseFilterTo}
          minFilter={absoluteMinFilterDate}
          maxFilter={absoluteMaxFilterDate}
          timezone={props.timezone}
        />
        <BasicChart
          chartType="line"
          cardTitle="Temperature Monitor Current Activity"
          chartTitle="Temperature Readings"
          data={recentTemperature}
          loading={isLoadingRecentSensors}
          error={props.error || recentSensorError}
          onRefreshClick={handleRefreshRecentSensors}
          timezone={props.timezone}
        />
        <DateRangeChart
          chartType="line"
          cardTitle="Temperature Monitor Historical Activity"
          chartTitle="Temperature Readings"
          data={historicTemperatureChartData}
          loading={isLoadingTemperature}
          error={props.error || temperatureError}
          onRefreshClick={handleRefreshTemperature}
          onDateRangeChange={handleTemperatureDateChange}
          filterFrom={temperatureFilterFrom}
          filterTo={temperatureFilterTo}
          minFilter={absoluteMinFilterDate}
          maxFilter={absoluteMaxFilterDate}
          timezone={props.timezone}
        />
      </>
    );
  };

  const getRouterCharts = () => {
    if (!routers?.length) return undefined;
    else
      return (
        <>
          <BasicChart
            chartType="bar"
            cardTitle="Router Current Activity"
            chartTitle="Connected"
            data={recentRouterChartData}
            loading={isLoadingRecentRouters}
            error={props.error || recentRouterError}
            onRefreshClick={handleRefreshRecentRouters}
            timezone={props.timezone}
          />
          <DateRangeChart
            chartType="bar"
            cardTitle="Router Historical Activity"
            data={historicRouterChartData}
            loading={isLoadingRouter}
            error={props.error || routerError}
            onRefreshClick={handleRefreshRouters}
            onDateRangeChange={handleRouterDateChange}
            filterFrom={routerFilterFrom}
            filterTo={routerFilterTo}
            minFilter={absoluteMinFilterDate}
            maxFilter={absoluteMaxFilterDate}
            timezone={props.timezone}
          />
        </>
      );
  };

  return (
    <div className="component-device-charts">
      {getSensorCharts()}
      {getRouterCharts()}
    </div>
  );
};

export default DeviceCharts;
