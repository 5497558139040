import React, { FC } from "react";
import styles from "./PageNotFound.module.scss";

interface PageNotFoundProps {}

const PageNotFound: FC<PageNotFoundProps> = () => {
  return (
    <div className={styles.PageNotFound} data-testid="PageNotFound">
      <div>
        <h2>404 Page Not Found</h2>
      </div>
    </div>
  );
};

export default PageNotFound;
