import { ICellRendererParams } from "ag-grid-community";
import React, {
  FC,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import DeviceTableRow from "../../../../models/DeviceTableRow";

import "./MenuCellRenderer.scss";

interface MenuCellRendererProps extends ICellRendererParams {
  menuItems: {
    displayText: string;
    callback: (tableRow: DeviceTableRow) => void;
  }[];
}

const OPEN_CLASS = "ag-grid-open-menu";

const MenuCellRenderer: FC<MenuCellRendererProps> = (props) => {
  const menuRef = useRef() as MutableRefObject<HTMLDivElement>;
  const [isOpen, setIsOpen] = useState(false);

  // Close the menu if click anywere else
  useEffect(() => {
    if (isOpen) {
      const detectOutsideClick = (e: any) => {
        if (!menuRef.current.contains(e.target)) {
          menuRef.current.classList.remove(OPEN_CLASS);
          setIsOpen(false);
        }
      };

      window.addEventListener("click", detectOutsideClick);
      return () => {
        window.removeEventListener("click", detectOutsideClick);
      };
    }
  }, [isOpen]);

  // Open and close the menu
  const toggleMenu = () => {
    const isOpen = menuRef.current.classList.contains(OPEN_CLASS);

    // Close all open menus
    const openMenus = document.getElementsByClassName(OPEN_CLASS);
    for (let i = 0; i < openMenus.length; i++) {
      openMenus[i].classList.remove(OPEN_CLASS);
    }

    // Open this menu if it was not already open
    if (!isOpen) {
      menuRef.current.classList.add(OPEN_CLASS);
    }
    setIsOpen(!isOpen);
  };

  // Menu item clicked
  const handleClick = (callback: (tableRow: DeviceTableRow) => void) => {
    menuRef.current.classList.remove(OPEN_CLASS);
    setIsOpen(false);
    callback(props.data);
  };

  return (
    <div className="component-cell-renderer-menu" ref={menuRef}>
      <button className="ellipsis-wrapper" onClick={toggleMenu}>
        <div className="ellipsis-dot"></div>
        <div className="ellipsis-dot"></div>
        <div className="ellipsis-dot"></div>
        <div className="close">&times;</div>
      </button>
      <div className="menu-wrapper">
        <div className="menu">
          {props.menuItems?.map((item) => (
            <div
              key={item.displayText}
              className="menu-item"
              onClick={() => handleClick(item.callback)}
            >
              {item.displayText}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MenuCellRenderer;
