import React, { FC, useEffect, useState } from "react";
import SpinnerOverlay from "../../../elements/Spinner/SpinnerOverlay";
import DeviceTableRow from "../../../models/DeviceTableRow";
import { isLockType } from "../../../models/DeviceTypeEnum";
import ConnectService from "../../../services/connect.service";
import SmartHomeService from "../../../services/smarthome.service";
import { PropertyStore } from "../../../store/property";
import { TurnKeyService } from "../../../services/turnkey.service";
import ErrorBadge from "../../../elements/Error/ErrorBadge";

import styles from "./RemoveDeviceDialog.module.scss";

interface RemoveDeviceDialogProps {
  dataRow: DeviceTableRow;
  tableData?: DeviceTableRow[];
  onClose?: () => void;
  onSave?: (deviceId: number, gatewayId: number) => void;
  operator?: string;
}

const RemoveDeviceDialog: FC<RemoveDeviceDialogProps> = (props) => {
  const dataRow = props.dataRow;
  const tableData = props.tableData;

  const property = PropertyStore.selectors.usePropertyData()!;
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error>();
  const [reservationCount, setReservationCount] = useState<number>();
  const [isTurnkeyLock, setIsTurnkeyLock] = useState<boolean>();

  // Load the current reservation count for this unit
  useEffect(() => {
    const isLock = isLockType(dataRow.typeId);
    const isTurnkey =
      isLockType(dataRow.typeId) &&
      dataRow.vendor?.toLowerCase().startsWith("turnkey");
    setIsTurnkeyLock(isTurnkey);

    let isLockReplaceable = false;
    if (isLock) {
      const otherActiveLocks =
        tableData?.filter(
          (x) =>
            x.active &&
            isLockType(x.typeId) &&
            x.getDeviceId() !== dataRow.getDeviceId(),
        ).length ?? 0;

      // always allow lock to be deleted if it is inactive and other active locks exist
      isLockReplaceable = otherActiveLocks > 0 && !dataRow.active;
    }

    const abortController = new AbortController();
    const connectService = new ConnectService();

    // All non-turnkey locks must have 0 future reservations to be deleted...
    // ...UNLESS it is inactive and another active lock exists
    if (isLock && !isTurnkey) {
      if (isLockReplaceable) {
        setReservationCount(0);
      } else {
        setIsLoading(true);
        setError(undefined);
        connectService
          .getReservationCount(property.id, abortController.signal)
          .then((reservationCount) => setReservationCount(reservationCount))
          .catch((err) => {
            setError(err);
            setReservationCount(-1);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    } else {
      setReservationCount(0);
    }

    return () => {
      abortController.abort();
    };
  }, [property, dataRow, tableData]);

  const handleClose = function () {
    props.onClose?.();
  };

  const handleRemoveDevice = function () {
    if (isTurnkeyLock) removeTurnKeyLock();
    else removeDevice();
  };

  const removeDevice = function () {
    const smartHomeService = new SmartHomeService();
    const [deviceId, gatewayId] = [
      props.dataRow.getDeviceId()!,
      props.dataRow.getGatewayId()!,
    ];

    setIsLoading(true);
    setError(undefined);
    smartHomeService
      .deleteDeviceAndGateway(deviceId, gatewayId)
      .then((success) => {
        if (success) {
          props.onSave?.(deviceId, gatewayId);
        } else {
          setError(new Error("Unable to remove device"));
        }
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const removeTurnKeyLock = function () {
    const turnKeyService = new TurnKeyService();
    const [deviceId, gatewayId, externalId] = [
      props.dataRow.getDeviceId()!,
      props.dataRow.getGatewayId()!,
      props.dataRow.externalId!,
    ];

    setIsLoading(true);
    setError(undefined);

    turnKeyService
      .teardownLock(externalId, gatewayId, deviceId, props.operator)
      .then((success) => {
        if (success) props.onSave?.(deviceId, gatewayId);
        else setError(new Error("Unable to remove device. Please try again."));
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const getMainContent = () => {
    if (reservationCount !== 0) {
      return (
        <div className="title-message">
          This lock cannot be removed until all the future reservations have
          been processed.
        </div>
      );
    } else {
      return (
        <div className="title">
          Are you sure you want to remove this {props.dataRow.type || "device"}?
        </div>
      );
    }
  };

  return (
    <div className={styles.DialogContent}>
      {getMainContent()}
      {reservationCount === 0 && (
        <div className="action">
          <div className="cancel">
            <button
              id="btn-cancel-remove"
              className="btn-alt"
              onClick={handleClose}
            >
              Cancel
            </button>
          </div>
          <div className="remove">
            <button
              id="btn-commit-remove"
              className="btn-alert"
              onClick={handleRemoveDevice}
            >
              Remove
            </button>
          </div>
        </div>
      )}
      <ErrorBadge error={error} />
      {isLoading && <SpinnerOverlay />}
    </div>
  );
};

export default RemoveDeviceDialog;
