import React, { FC, useEffect, useState } from "react";
import { Icon, Image } from "@vacasa/react-components-lib";
import BasicButton from "../../../elements/Button/BasicButton";
import SmartDeviceService from "../../../services/smart-device.service";
import {
  AlertSubscriber,
  UpdateAlertSubscriberRequest,
} from "../../../models/SmartDeviceApi";

import "./EditAlertSubscriber.scss";
import { PropertyStore } from "../../../store/property";

interface EditAlertSubscriberProps {
  subscriber: AlertSubscriber;
  onSubmit?: (newSubscriber: AlertSubscriber) => void;
  onCancel?: () => void;
}

const EditAlertSubscriber: FC<EditAlertSubscriberProps> = (props) => {
  const property = PropertyStore.selectors.usePropertyData()!;

  const [isSaving, setIsSaving] = useState(false);
  const [apiSaveError, setApiSaveError] = useState<string>();
  const [email, setEmail] = useState(props.subscriber.SendEmail);
  const [sms, setSms] = useState(props.subscriber.SendSms);
  const [subscriptionNoise, setSubscriptionNoise] = useState(
    props.subscriber.SensorNoiseAlert,
  );
  const [subscriptionTemperature, setSubscriptionTemperature] = useState(
    props.subscriber.SensorTemperatureAlert,
  );
  const [subscriptionRouter, setSubscriptionRouter] = useState(
    props.subscriber.RouterConnectedAlert,
  );
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    const atLeastOneMethod = email || sms;
    const atLeastOneSubscription =
      subscriptionNoise || subscriptionTemperature || subscriptionRouter;
    const atLeastOneChange = [
      email !== props.subscriber.SendEmail,
      sms !== props.subscriber.SendSms,
      subscriptionNoise !== props.subscriber.SensorNoiseAlert,
      subscriptionTemperature !== props.subscriber.SensorTemperatureAlert,
      subscriptionRouter !== props.subscriber.RouterConnectedAlert,
    ].some((x) => x);

    setIsFormValid(
      atLeastOneMethod && atLeastOneSubscription && atLeastOneChange,
    );
  }, [
    email,
    sms,
    subscriptionNoise,
    subscriptionTemperature,
    subscriptionRouter,
    props.subscriber,
  ]);

  const handleEmailChange = (e: any) => {
    setEmail(e.target.checked);
  };

  const handleSmsChange = (e: any) => {
    setSms(e.target.checked);
  };

  const toggleNoise = () => setSubscriptionNoise(!subscriptionNoise);
  const toggleTemperature = () =>
    setSubscriptionTemperature(!subscriptionTemperature);
  const toggleRouter = () => setSubscriptionRouter(!subscriptionRouter);

  const handleCancel = () => {
    props.onCancel?.();
  };

  const handleSubmit = () => {
    if (isFormValid) {
      const payload: UpdateAlertSubscriberRequest = {
        DeviceGroup: property.id,
        LoginId: props.subscriber.Login.Id,
        SendEmail: email,
        SendSms: sms,
        SensorNoiseAlert: subscriptionNoise,
        SensorTemperatureAlert: subscriptionTemperature,
        RouterConnectedAlert: subscriptionRouter,
      };
      updateSubscriber(payload);
    }
  };

  const updateSubscriber = async (data: UpdateAlertSubscriberRequest) => {
    const service = new SmartDeviceService();
    try {
      setIsSaving(true);
      setApiSaveError(undefined);
      const result = await service.updateAlertSubscriber(data);
      props.onSubmit?.(result);
    } catch (err) {
      setApiSaveError((err as Error).message);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="component-update-alert-subscriber">
      <div className="component-title">Edit Alert Subscriber</div>
      <div>
        <span className="name">
          {props.subscriber.Login.FirstName} {props.subscriber.Login.LastName}
        </span>
        <div className="form-option">
          <input type="checkbox" checked={email} onChange={handleEmailChange} />
          <span className="label">Email</span>
        </div>
        <div className="form-option">
          <input type="checkbox" checked={sms} onChange={handleSmsChange} />
          <span className="label">SMS</span>
        </div>
      </div>
      <div className="subscribe-channel">
        <div className="label-title">Subscriptions</div>
        <div
          className={`badge SensorNoiseAlert ${
            subscriptionNoise ? "checked" : "unchecked"
          }`}
          onClick={toggleNoise}
        >
          <span className="icon">
            <Icon.Radio />
          </span>
          <span className="title">Noise Sensor</span>
        </div>
        <div
          className={`badge SensorTemperatureAlert ${
            subscriptionTemperature ? "checked" : "unchecked"
          }`}
          onClick={toggleTemperature}
        >
          <span className="icon">
            <Icon.Thermometer />
          </span>
          <span className="title">Temperature</span>
        </div>
        <div
          className={`badge RouterConnectedAlert ${
            subscriptionRouter ? "checked" : "unchecked"
          }`}
          onClick={toggleRouter}
        >
          <span className="icon">
            <Icon.Wifi />
          </span>
          <span className="title">Router</span>
        </div>
      </div>
      <BasicButton
        disabled={!props.subscriber || isSaving}
        display="outline"
        onClick={handleCancel}
      >
        Cancel
      </BasicButton>
      <span style={{ float: "right" }}>
        {isSaving ? (
          <span className="spinner">
            <Image.Spinner height={36} width={36} />
          </span>
        ) : (
          <BasicButton
            type="submit"
            disabled={!isFormValid || isSaving}
            display="fill"
            onClick={handleSubmit}
          >
            Update Alert Subscriber
          </BasicButton>
        )}
      </span>
      <div className="error-message">{apiSaveError}</div>
    </div>
  );
};

export default EditAlertSubscriber;
