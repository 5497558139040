import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PropertyState } from ".";
import { Property } from "../../models/ConnectApi";

const initialState: PropertyState = {
  isLoading: false,
};

export const slice = createSlice({
  name: "property",
  initialState,
  reducers: {
    loadProperty: (state) => {
      state.isLoading = true;
      state.data = undefined;
      state.error = undefined;
    },
    setProperty: (state, action: PayloadAction<Property>) => {
      state.isLoading = false;
      state.data = action.payload;
      state.error = undefined;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});
