import { Dispatch, SetStateAction, useRef, useState } from "react";

// Groups commonly used fields when loading data from an api
export const useAsyncData = function <T>(): [
  {
    isLoading: boolean;
    data: T | undefined;
    error: string | undefined;
  },
  {
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    setData: Dispatch<SetStateAction<T | undefined>>;
    setError: Dispatch<SetStateAction<string | undefined>>;
  },
] {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<T>();
  const [error, setError] = useState<string>();

  const getters = {
    isLoading,
    data,
    error,
  };
  const setters = useRef({
    setIsLoading,
    setData,
    setError,
  });

  return [getters, setters.current];
};
