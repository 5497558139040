import React, { FC, useState } from "react";
import { Image } from "@vacasa/react-components-lib";
import BasicButton from "../../../elements/Button/BasicButton";

import "./RemoveAlertSubscriber.scss";
import SmartDeviceService from "../../../services/smart-device.service";
import {
  AlertSubscriber,
  RemoveAlertSubscriberRequest,
} from "../../../models/SmartDeviceApi";
import { PropertyStore } from "../../../store/property";

interface RemoveAlertSubscriberProps {
  subscriber?: AlertSubscriber;
  onSubmit?: () => void;
  onCancel?: () => void;
}

const RemoveAlertSubscriber: FC<RemoveAlertSubscriberProps> = (props) => {
  const property = PropertyStore.selectors.usePropertyData()!;
  const [isSaving, setIsSaving] = useState(false);
  const [apiSaveError, setApiSaveError] = useState<string>();

  const handleCancel = () => {
    props.onCancel?.();
  };

  const handleSubmit = () => {
    if (props.subscriber) {
      const payload: RemoveAlertSubscriberRequest = {
        DeviceGroup: property.id,
        LoginId: props.subscriber.Login.Id,
      };
      removeSubscriber(payload);
    }
  };

  const removeSubscriber = async (data: RemoveAlertSubscriberRequest) => {
    const service = new SmartDeviceService();
    try {
      setIsSaving(true);
      setApiSaveError(undefined);
      await service.removeAlertSubscriber(data);
      props.onSubmit?.();
    } catch (err) {
      setApiSaveError((err as Error).message);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="component-remove-alert-subscriber">
      <div className="component-title">Remove Alert Subscriber</div>
      <div className="message">
        Are you sure you want to remove this subscriber?
      </div>
      <BasicButton
        disabled={!props.subscriber || isSaving}
        display="outline"
        onClick={handleCancel}
      >
        Cancel
      </BasicButton>
      <span style={{ float: "right" }}>
        {isSaving ? (
          <span className="spinner">
            <Image.Spinner height={36} width={36} />
          </span>
        ) : (
          <BasicButton
            type="submit"
            disabled={!props.subscriber || isSaving}
            display="warning"
            onClick={handleSubmit}
          >
            Remove Alert Subscriber
          </BasicButton>
        )}
      </span>
      <div className="error-message">{apiSaveError}</div>
    </div>
  );
};

export default RemoveAlertSubscriber;
