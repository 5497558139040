import React, { FC, useEffect, useState } from "react";
import { Image, Tooltip } from "@vacasa/react-components-lib";
import { FormValue } from "../../models/Form";
import { IoHelpCircleOutline } from "react-icons/io5";

import "./Input.scss";

export interface InputTextProps {
  id?: string;
  label?: string;
  value?: FormValue<string>;
  placeholder?: string;
  maxLength?: number;
  disabled?: boolean;
  required?: boolean;
  width?: number;
  formatter?: (rawValue: string) => string;
  validator?: (
    value: string,
    signal?: AbortSignal,
  ) => Promise<string | undefined> | string | undefined;
  display?: "compact";
  helpText?: string;
}

const InputText: FC<InputTextProps> = ({ required, validator, ...props }) => {
  const [value, setValue] = useState(props.value?.value);
  const [validationError, setValidationError] = useState(
    props.value?.isValid ? undefined : "default_invalid",
  );
  const [isDirty, setIsDirty] = useState(false);
  const [isAsyncValidating, setIsAsyncValidating] = useState(false);

  useEffect(() => {
    props.value?.onChange(value, !validationError && !isAsyncValidating);
  }, [props.value, value, validationError, isAsyncValidating]);

  // Validate value whenever it is updated
  useEffect(() => {
    const abortController = new AbortController();

    if (required && !value?.trim()) {
      setValidationError("required");
    } else if (value && validator) {
      const validationMsg = validator(value, abortController.signal);
      if (validationMsg) {
        if (typeof validationMsg === "string") {
          setValidationError(validationMsg);
        } else {
          setIsAsyncValidating(true);
          validationMsg
            .then((msg) => setValidationError(msg || undefined))
            .catch((err) =>
              setValidationError(`Unable to verify: ${err.message}`),
            )
            .finally(() => setIsAsyncValidating(false));
        }
      }
    }

    return () => {
      abortController.abort();
    };
  }, [value, required, validator]);

  const handleChange = function (event: any) {
    setValidationError(undefined);
    setIsDirty(true);

    let newValue: string = event.target.value;

    if (props.formatter) newValue = props.formatter(newValue);
    if (props.maxLength) newValue = newValue.substring(0, props.maxLength);

    setValue(newValue);
  };

  const handleBlur = () => {
    setIsDirty(true);
    if (
      (!validationError || validationError === "default_invalid") &&
      required &&
      !value
    )
      setValidationError("required");
  };

  const attributes = {
    id: props.id,
    type: "text",
    value: value,
    placeholder: props.placeholder,
    disabled: props.disabled,
    onChange: handleChange,
    onBlur: handleBlur,
    className: `input-text ${!!validationError && isDirty ? "invalid" : ""}`,
    style: {
      width: props.width,
    },
  };

  return (
    <div className="component-input-text component-input">
      <div
        className={`label ${props.display} ${props.disabled ? "disabled" : ""}`}
      >
        <div>{props.label}</div>
        {props.helpText && (
          <Tooltip message={props.helpText}>
            <div className="help-text">
              <IoHelpCircleOutline />
            </div>
          </Tooltip>
        )}
      </div>
      <input {...attributes} />
      <span className="spinner-section">
        {isAsyncValidating && (
          <Image.Spinner className="spinner" height={26} width={26} />
        )}
      </span>
      <div className="validation-error">{isDirty && validationError}</div>
    </div>
  );
};

export default InputText;
