import React, { FC } from "react";
import { formatDateTime } from "../../../core/format";
import DeviceTableRow from "../../../models/DeviceTableRow";
import styles from "./DeviceDetailsDialog.module.scss";

interface DeviceDetailsDialogProps {
  dataRow: DeviceTableRow;
}

const DeviceDetailsDialog: FC<DeviceDetailsDialogProps> = (props) => {
  const emptyValue = "N/A";

  const formatKey = (key: string): string => {
    return key.replace(/_|-/g, " ");
  };

  const formatValue = (value: any) => {
    if (value === null || value === undefined) return "null";
    else if (typeof value === "object") return JSON.stringify(value);
    return value;
  };

  return (
    <div
      className={styles.DeviceDetailsDialog}
      data-testid="DeviceDetailsDialog"
    >
      <div className="details-dialog">
        <b className="details-dialog-title">Device Details</b>
        {Object.entries(props.dataRow.vendorData ?? {}).map(([key, value]) => {
          return (
            <div key={key}>
              <div className="row">
                <label className="col detail-key">{formatKey(key)}:</label>
                <label className="col detail-value">{formatValue(value)}</label>
              </div>
            </div>
          );
        })}
        <div>
          <div className="row">
            <label className="col detail-key">Installed At:</label>
            <label className="col detail-value">
              {props.dataRow.createdAt
                ? formatDateTime(props.dataRow.createdAt)
                : emptyValue}
            </label>
          </div>
        </div>
        <div>
          <div className="row">
            <label className="col detail-key">Updated At:</label>
            <label className="col detail-value">
              {props.dataRow.createdAt
                ? formatDateTime(props.dataRow.createdAt)
                : emptyValue}
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeviceDetailsDialog;
