import React, { FC } from "react";
import { Icon } from "@vacasa/react-components-lib";
import { AlertSubscriber } from "../../../../models/SmartDeviceApi";

import "./Subscriber.scss";

interface SubscriberProps {
  subscriber: AlertSubscriber;
  onDelete: (subscriber: AlertSubscriber) => void;
  onEdit: (subscriber: AlertSubscriber) => void;
}

const Subscriber: FC<SubscriberProps> = (props) => {
  const methods: string[] = [];
  if (props.subscriber.SendEmail) methods.push("Email");
  if (props.subscriber.SendSms) methods.push("SMS");

  const subscriptionMethod = methods.join(" & ") || "None";

  const handleEditClick = () => {
    props.onEdit(props.subscriber);
  };

  const handleDeleteClick = () => {
    props.onDelete(props.subscriber);
  };

  return (
    <div className="component-subscriber">
      <div className="col">
        <div className="name">
          {props.subscriber.Login.FirstName} {props.subscriber.Login.LastName}
        </div>
        <div className="methods">Subscription - {subscriptionMethod}</div>
      </div>
      <div className="col subscriber-id">ID: {props.subscriber.Login.Id}</div>
      <div className="col subscriber-email">
        {props.subscriber.Login.EmailAddress}
      </div>
      <div className="col subscriber-phone">{props.subscriber.Login.Phone}</div>
      <div className="col subscribe-channel">
        {props.subscriber.SensorNoiseAlert && (
          <div className="subscription-badge SensorNoiseAlert">
            <span className="icon">
              <Icon.Radio />
            </span>
            <span className="title">Noise Sensor</span>
          </div>
        )}
        {props.subscriber.SensorTemperatureAlert && (
          <div className="subscription-badge SensorTemperatureAlert">
            <span className="icon">
              <Icon.Thermometer />
            </span>
            <span className="title">Temperature</span>
          </div>
        )}
        {props.subscriber.RouterConnectedAlert && (
          <div className="subscription-badge RouterConnectedAlert">
            <span className="icon">
              <Icon.Wifi />
            </span>
            <span className="title">Router</span>
          </div>
        )}
      </div>
      <div className="col">
        <button className="icon-button" onClick={handleEditClick}>
          <span className="icon edit">
            <Icon.Edit />
          </span>
        </button>
        <button className="icon-button" onClick={handleDeleteClick}>
          <span className="icon remove">
            <Icon.Trash2 />
          </span>
        </button>
      </div>
    </div>
  );
};

export default Subscriber;
