import { DeviceTypeEnum } from "./DeviceTypeEnum";
import {
  BackendResource,
  Device,
  DeviceMake,
  DeviceModel,
  DeviceType,
  Gateway,
  Vendor,
} from "./SmartHomeApi";

/**
 * Represents a single row in the Device Table grid
 */
class DeviceTableRow {
  private device?: BackendResource<Device>;
  private gateway?: BackendResource<Gateway>;

  public type?: string;
  public make?: string = "--";
  public model?: string = "--";
  public vendor?: string;
  public status?: string;

  private constructor() {}

  public static fromBackendData(
    device: BackendResource<Device>,
    gateway: BackendResource<Gateway> | undefined,
  ): DeviceTableRow {
    const tableRow = new DeviceTableRow();
    tableRow.device = device;
    tableRow.gateway = gateway;

    return tableRow;
  }

  public static compare(a: DeviceTableRow, b: DeviceTableRow): number {
    const activeDiff =
      b.gateway!.attributes.active - a.gateway!.attributes.active;
    if (activeDiff) return activeDiff;

    return (
      DeviceTableRow.getTypeSortValue(a) - DeviceTableRow.getTypeSortValue(b)
    );
  }

  private static getTypeSortValue(device: DeviceTableRow): number {
    switch (device.typeId) {
      case DeviceTypeEnum.Lock:
      case DeviceTypeEnum.Hub:
      case DeviceTypeEnum.SmartLock:
        return 0;
      default:
        return 1;
    }
  }

  public get active(): boolean {
    return (
      !!this.gateway?.attributes.active && !!this.device?.attributes.active
    );
  }
  public get typeId(): number {
    return this.device!.attributes.device_type!;
  }
  public get makeId(): number {
    return this.device!.attributes.device_make!;
  }
  public get modelId(): number {
    return this.device!.attributes.device_model!;
  }
  public get vendorId(): number | undefined {
    return this.gateway?.attributes.vendor;
  }
  public get externalId(): string | undefined {
    return this.gateway?.attributes.external_id;
  }
  public get createdAt(): string | undefined {
    return this.gateway?.attributes.created_at;
  }
  public get updatedAt(): string | undefined {
    return this.gateway?.attributes.updated_at;
  }
  public get alternateId(): string | undefined {
    return this.gateway?.attributes.alternate_id;
  }
  public get vendorData(): any {
    return this.gateway?.attributes.vendor_data;
  }

  getDeviceId(): number | undefined {
    return this.device?.id;
  }
  getGatewayId(): number | undefined {
    return this.gateway?.id;
  }

  setTypeText(types?: BackendResource<DeviceType>[]): void {
    if (types)
      this.type = types.find((x) => x.id === this.typeId)?.attributes.name;
    if (!this.type) this.type = `ID {${this.typeId}}`;
  }
  setMakeText(makes?: BackendResource<DeviceMake>[]): void {
    const unspecified = makes?.find(this.isNotSpecified)?.id ?? 1;
    if (this.makeId === undefined || this.makeId === unspecified) {
      return;
    }
    if (makes)
      this.make = makes.find((x) => x.id === this.makeId)?.attributes.name;
    if (!this.make) this.make = `ID {${this.makeId}}`;
  }
  setModelText(models?: BackendResource<DeviceModel>[]): void {
    const unspecified = models?.find(this.isNotSpecified)?.id ?? 1;
    if (this.modelId === undefined || this.modelId === unspecified) {
      return;
    }

    if (models)
      this.model = models.find((x) => x.id === this.modelId)?.attributes.name;
    if (!this.model) this.model = `ID {${this.modelId}}`;
  }
  setVendorText(vendors?: BackendResource<Vendor>[]): void {
    if (vendors)
      this.vendor = vendors.find((x) => x.id === this.vendorId)?.attributes
        .display_name;
    if (!this.vendor) this.vendor = `ID {${this.vendorId}}`;
  }

  cloneDevice(): BackendResource<Device> {
    if (!this.device) throw Error("Expected object to be non-null");

    const clone = {
      type: this.device.type,
      id: this.device.id,
      attributes: {
        ...this.device.attributes,
      },
    };

    const extraAttributes = [
      "failing",
      "failing_message",
      "failing_tag",
      "failing_times",
      "failing_notes",
    ];
    extraAttributes.forEach((attribute) => {
      const cloneRef = clone.attributes as any;
      if (!cloneRef[attribute]) {
        delete cloneRef[attribute];
      }
    });

    return clone;
  }

  cloneGateway(): BackendResource<Gateway> {
    if (!this.gateway) throw Error("Expected object to be non-null");

    const clone = {
      type: this.gateway.type,
      id: this.gateway.id,
      attributes: {
        ...this.gateway.attributes,
      },
    };

    const extraAttributes = [
      "failing",
      "failing_message",
      "failing_tag",
      "failing_times",
      "failing_notes",
    ];
    extraAttributes.forEach((attribute) => {
      const cloneRef = clone.attributes as any;
      if (!cloneRef[attribute]) {
        delete cloneRef[attribute];
      }
    });

    return clone;
  }

  private isNotSpecified(
    resource: BackendResource<DeviceMake | DeviceModel>,
  ): boolean {
    return resource.attributes.name.toLowerCase() === "not specified";
  }
}

export default DeviceTableRow;
