import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ImplicitFlow } from "@vacasa/react-components-lib";
import {
  IDP_CLIENT_ID,
  IDP_REDIRECT_URI,
  IDP_SCOPES,
  IDP_AUDIENCE,
  ENVIRONMENT,
} from "./core/constants";
import store from "./store";
import { Provider } from "react-redux";

import "./App.scss";

import PageNotFound from "./components/PageNotFound/PageNotFound";
import DataResolver from "./components/DataResolver/DataResolver";
import HomeDeviceGrid from "./components/HomeDeviceGrid/HomeDeviceGrid";
import DeviceMonitoring from "./components/DeviceMonitoring/DeviceMonitoring";
import DeviceMonitoringAlerts from "./components/DeviceMonitoringAlerts/DeviceMonitoringAlerts";
import PostAuthorization from "./components/AuthorizationRedirect/PostAuthorization";
import PreAuthorization from "./components/AuthorizationRedirect/PreAuthorization";
import HomePage from "./components/HomePage";
import ReportGrid from "./components/Reporting/Reporting";

function App() {
  return (
    <PreAuthorization>
      <ImplicitFlow
        directoryHint="onelogin"
        clientId={IDP_CLIENT_ID}
        redirectUrl={IDP_REDIRECT_URI}
        scopes={IDP_SCOPES}
        audience={IDP_AUDIENCE}
        tokenRefresh={true}
        // on local authenticate on stage
        env={ENVIRONMENT === "local" ? "stage" : (ENVIRONMENT as any)}
      >
        <Provider store={store}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<PostAuthorization />} />
              <Route path="/:propertyId" element={<DataResolver />}>
                <Route path="" element={<HomePage />} />
                <Route path="devices" element={<HomeDeviceGrid />} />
                <Route
                  path="device-monitoring"
                  element={<DeviceMonitoring />}
                />
                <Route
                  path="device-monitoring/alerts"
                  element={<DeviceMonitoringAlerts />}
                />
                <Route path="reporting" element={<ReportGrid />} />
              </Route>
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </BrowserRouter>
        </Provider>
      </ImplicitFlow>
    </PreAuthorization>
  );
}

export default App;
