import React, { FC, useCallback, useEffect, useState } from "react";
import {
  formatMacAddressForBackend,
  formatMacAddressForDisplay,
} from "../../../../core/format";
import BasicButton from "../../../../elements/Button/BasicButton";
import FormInput from "../../../../elements/Form";
import { useFormValue } from "../../../../hooks/FormValue";
import {
  RegisteredDevice,
  RegisteredDeviceType,
} from "../../../../models/SmartDeviceApi";
import SmartDeviceService from "../../../../services/smart-device.service";
import { PropertyStore } from "../../../../store/property";

import "./ConfigureDevice.scss";

interface ConfigureDeviceProps {
  deviceType: RegisteredDeviceType;
  onSubmit?: (device: RegisteredDevice) => void;
  onCancel?: () => void;
}

const ConfigureDevice: FC<ConfigureDeviceProps> = (props) => {
  const property = PropertyStore.selectors.usePropertyData()!;

  const [isFormValid, setIsFormValid] = useState(false);
  const macAddressInput = useFormValue<string>("", false);
  const deviceNameInput = useFormValue<string>("", false);
  const decibelThreshold = useFormValue<number>(
    property.attributes.housing_type === "house" ? 93 : 90,
    true,
  );
  const temperatureLowThreshold = useFormValue<number>(50, true);
  const temperatureHighThreshold = useFormValue<number>(80, true);
  const connectedThreshold = useFormValue<number>(
    property.attributes.max_occupancy * 3,
    true,
  );

  // Validates the device form
  useEffect(() => {
    if (!macAddressInput.isValid) {
      return setIsFormValid(false);
    } else if (props.deviceType === RegisteredDeviceType.Sensor) {
      return setIsFormValid(
        temperatureLowThreshold.isValid &&
          temperatureHighThreshold.isValid &&
          decibelThreshold.isValid &&
          deviceNameInput.isValid,
      );
    } else if (props.deviceType === RegisteredDeviceType.Router) {
      return setIsFormValid(connectedThreshold.isValid);
    } else {
      return setIsFormValid(false);
    }
  }, [
    props.deviceType,
    macAddressInput.isValid,
    deviceNameInput.isValid,
    decibelThreshold.isValid,
    temperatureLowThreshold.isValid,
    temperatureHighThreshold.isValid,
    connectedThreshold.isValid,
  ]);

  const getMacAddressValidationError = useCallback(
    function (
      macAddress: string,
      signal?: AbortSignal,
    ): Promise<string | undefined> | string | undefined {
      const service = new SmartDeviceService();
      const formatted = formatMacAddressForBackend(macAddress);

      if (macAddress && macAddress.length !== 17) {
        return "Invalid mac address";
      } else if (formatted) {
        return service
          .getDevice(formatted, props.deviceType, signal)
          .then((sensor) => {
            if (sensor) {
              const isThisUnit = sensor.DeviceGroup === property.id;
              return `Device registered to ${
                isThisUnit ? "this" : "another"
              } property`;
            }
            return undefined;
          })
          .catch((err) => `Unable to verify: ${err.message}`);
      }
      return undefined;
    },
    [property, props.deviceType],
  );

  const handleCancel = () => {
    props.onCancel?.();
  };

  const handleSubmit = () => {
    if (isFormValid) {
      let deviceName: string;
      if (props.deviceType === RegisteredDeviceType.Sensor) {
        deviceName = deviceNameInput.value!.trim();
      } else {
        deviceName = props.deviceType;
      }

      const device: RegisteredDevice = {
        DeviceType: props.deviceType,
        DeviceGroup: property.id,
        MacAddress: formatMacAddressForBackend(macAddressInput.value)!,
        DeviceName: deviceName,
        AlertsPauseUntilDate: null,
        UpdatedByEmailAddress: "", // api will generate its own value
        UpdatedDate: "", // api will generate its own value
        RegistrationDate: "", // api will generate its own value
        NoiseAlertDecibelThreshold: decibelThreshold.value!, // api will ignore if not sensor
        TemperatureLowThreshold: temperatureLowThreshold.value!, // api will ignore if not sensor
        TemperatureHighThreshold: temperatureHighThreshold.value!, // api will ignore if not sensor
        ConnectedAlertThreshold: connectedThreshold.value!, // api will ignore if not router
      };
      props.onSubmit?.(device);
    }
  };

  const getFieldsForDeviceType = () => {
    if (props.deviceType === RegisteredDeviceType.Sensor) {
      return (
        <>
          <div className="input-name">
            <FormInput.Text
              label="Sensor Location"
              placeholder="Kitchen, Living room, Dining room, etc"
              value={deviceNameInput}
              width={307}
              display="compact"
              required
            />
          </div>
          <div className="input-type-data">
            <FormInput.Number
              label="Decibel Threshold"
              value={decibelThreshold}
              min={70}
              max={120}
              width={150}
              display="compact"
              helpText="The default for stand alone properties is 93db
                            and properties with shared walls is 90db.  We do not recommend
                            changing these settings unless there is an excess of alerts that
                            you feel is unwarranted for the property and the levels need to increase."
              required
            />
          </div>
          <div className="input-type-data">
            <FormInput.Number
              label="Temperature Low Threshold"
              value={temperatureLowThreshold}
              min={20}
              max={70}
              width={150}
              display="compact"
              helpText="The default temperature threshold is a minimum of 50 and a maximum of 80 degrees. You can override as needed."
              required
            />
          </div>
          <div className="input-type-data">
            <FormInput.Number
              label="Temperature High Threshold"
              value={temperatureHighThreshold}
              min={50}
              max={100}
              width={150}
              display="compact"
              helpText="The default temperature threshold is a minimum of 50 and a maximum of 80 degrees. You can override as needed."
              required
            />
          </div>
        </>
      );
    } else if (props.deviceType === RegisteredDeviceType.Router) {
      return (
        <div className="input-type-data">
          <FormInput.Number
            label="Connected Threshold"
            value={connectedThreshold}
            min={1}
            max={100}
            width={150}
            display="compact"
            helpText="Our default setting is to allow 3x the number of
                        devices per person that the property sleeps.  We do not
                        recommend changing these settings unless there is an excess
                        of alerts that you feel is unwarranted for the property and
                        the levels need to increase."
            required
          />
        </div>
      );
    }
  };

  return (
    <div className="component-add-monitoring-device-configuration">
      <div className="input-macaddress">
        <FormInput.Text
          label="Mac Address"
          formatter={formatMacAddressForDisplay}
          validator={getMacAddressValidationError}
          value={macAddressInput}
          width={307}
          display="compact"
          helpText="MAC Address should consist of exactly 12 numbers and letters (0-9 & A-F)."
          required
        />
      </div>
      {getFieldsForDeviceType()}
      <div className="configure-device-footer">
        <BasicButton display="outline" onClick={handleCancel}>
          Cancel
        </BasicButton>
        <BasicButton
          display="fill"
          onClick={handleSubmit}
          disabled={!isFormValid}
          style={{ float: "right" }}
        >
          Register
        </BasicButton>
      </div>
    </div>
  );
};

export default ConfigureDevice;
