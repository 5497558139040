import React, { FC, useState } from "react";
import { Property } from "../../../../../models/ConnectApi";

import "./TurnKeyStepWrapper.scss";
import LockType from "./components/LockType/LockType";
import FactoryReset from "./components/FactoryReset/FactoryReset";
import LockLocation from "./components/LockLocation/LockLocation";
import MemoryReset from "./components/MemoryReset/MemoryReset";
import ContactInfo from "./components/ContactInfo/ContactInfo";
import IdentificationNumber from "./components/IdentificationNumber/IdentificationNumber";
import MasterCode from "./components/MasterCode/MasterCode";
import Timezone from "./components/Timezone/Timezone";
import { LockSetupResult } from "../../../../../models/TurnKeyApi";
import SaveTurnKeyLock from "./components/SaveTurnKeyLock/SaveTurnKeyLock";
import SetTime from "./components/SetTime/SetTime";
import TestCode from "./components/TestCode/TestCode";
import Done from "./components/Done/Done";

interface TurnKeyStepWrapperProps {
  property: Property;
  operator?: string;
  onPrevious: () => void;
  onCompletion: () => void;
}

export enum TurnKeyStep {
  LOCK_TYPE,
  FACTORY_RESET,
  MEMORY_RESET,
  LOCK_LOCATION,
  CONTACT_INFO,
  IDENTIFICATION_NUMBER,
  MASTER_CODE,
  TIME_ZONE,
  SAVE_TURNKEY_LOCK,
  SET_BASE_TIME,
  SET_CURRENT_TIME,
  TEST_ONE_TIME_CODE,
  TEST_GUEST_CODE,
  TEST_OWNER_CODE,
  DONE,
}

const TurnKeyStepWrapper: FC<TurnKeyStepWrapperProps> = ({
  property,
  operator,
  onPrevious,
  onCompletion,
}) => {
  const [step, setStep] = useState(TurnKeyStep.LOCK_TYPE);

  // Form Values
  const [lockType, setLockType] = useState("");
  const [lockLocation, setLockLocation] = useState("");
  const [contactFirstName, setContactFirstName] = useState("");
  const [contactLastName, setContactLastName] = useState("");
  const [lockIdNumber, setLockIdNumber] = useState("");
  const [masterCode, setMasterCode] = useState("");
  const [timezone, setTimezone] = useState("");
  const [registeredLock, setRegisteredLock] = useState<LockSetupResult>();
  const [hasBaseTime, setHasBaseTime] = useState(false);
  const [hasCurrentTime, setHasCurrentTime] = useState(false);

  const handlePrevious = () => {
    const previousStep = step - 1;
    if (previousStep < 0) return onPrevious();

    switch (step) {
      case TurnKeyStep.SAVE_TURNKEY_LOCK:
        if (registeredLock) return; // cannot backtrack once the lock is registered
        break;
      case TurnKeyStep.SET_BASE_TIME:
        return; // Cannot backtrack from here
      default:
        break;
    }
    setStep(previousStep);
  };

  const handleNext = () => {
    if (step === TurnKeyStep.DONE) onCompletion();
    else setStep(step + 1);
  };

  const handleType = (type: string) => {
    setLockType(type);
    handleNext();
  };

  const handleLocation = (location: string) => {
    setLockLocation(location);
    handleNext();
  };

  const handleContact = (firstname: string, lastname: string) => {
    setContactFirstName(firstname);
    setContactLastName(lastname);
    handleNext();
  };

  const handleIdNumber = (idNumber: string) => {
    setLockIdNumber(idNumber);
    handleNext();
  };

  const handleMasterCode = (code: string) => {
    setMasterCode(code);
    handleNext();
  };

  const handleTimezone = (zone: string) => {
    setTimezone(zone);
    handleNext();
  };

  const handleRegisterLock = (lock: LockSetupResult) => {
    setRegisteredLock(lock);
    handleNext();
  };

  const handleBaseTime = () => {
    setHasBaseTime(true);
    handleNext();
  };

  const handleCurrentTime = () => {
    setHasCurrentTime(true);
    handleNext();
  };

  return (
    <div className="turnkey-setup">
      {step === TurnKeyStep.LOCK_TYPE && (
        <LockType
          value={lockType}
          onSubmit={handleType}
          onPrevious={handlePrevious}
        />
      )}
      {step === TurnKeyStep.FACTORY_RESET && (
        <FactoryReset onSubmit={handleNext} onPrevious={handlePrevious} />
      )}
      {step === TurnKeyStep.MEMORY_RESET && (
        <MemoryReset onSubmit={handleNext} onPrevious={handlePrevious} />
      )}
      {step === TurnKeyStep.LOCK_LOCATION && (
        <LockLocation
          value={lockLocation}
          onSubmit={handleLocation}
          onPrevious={handlePrevious}
        />
      )}
      {step === TurnKeyStep.CONTACT_INFO && (
        <ContactInfo
          firstname={contactFirstName}
          lastname={contactLastName}
          onSubmit={handleContact}
          onPrevious={handlePrevious}
        />
      )}
      {step === TurnKeyStep.IDENTIFICATION_NUMBER && (
        <IdentificationNumber
          value={lockIdNumber}
          onSubmit={handleIdNumber}
          onPrevious={handlePrevious}
        />
      )}
      {step === TurnKeyStep.MASTER_CODE && (
        <MasterCode
          value={masterCode}
          onSubmit={handleMasterCode}
          onPrevious={handlePrevious}
        />
      )}
      {step === TurnKeyStep.TIME_ZONE && (
        <Timezone
          value={timezone}
          onSubmit={handleTimezone}
          onPrevious={handlePrevious}
        />
      )}
      {step === TurnKeyStep.SAVE_TURNKEY_LOCK && (
        <SaveTurnKeyLock
          propertyId={property.id}
          unitCode={property.attributes.code}
          address={`${property.attributes.address.address_1}, ${property.attributes.address.city}, ${property.attributes.address.state}`}
          lockType={lockType}
          lockLocation={lockLocation}
          contactFirstName={contactFirstName}
          contactLastName={contactLastName}
          lockIdNumber={lockIdNumber}
          lockMasterCode={masterCode}
          timezone={timezone}
          operator={operator}
          onSubmit={handleRegisterLock}
          onPrevious={handlePrevious}
        />
      )}
      {step === TurnKeyStep.SET_BASE_TIME && (
        <SetTime
          value={hasBaseTime}
          timeName="BASE"
          timeValue={registeredLock!.baseTime}
          masterCode={masterCode}
          onSubmit={handleBaseTime}
        />
      )}
      {step === TurnKeyStep.SET_CURRENT_TIME && (
        <SetTime
          value={hasCurrentTime}
          timeName="CURRENT"
          timeValue={new Date()}
          timeZone={timezone}
          masterCode={masterCode}
          onSubmit={handleCurrentTime}
          onPrevious={handlePrevious}
        />
      )}
      {step === TurnKeyStep.TEST_ONE_TIME_CODE && (
        <TestCode
          codeType="ONE TIME"
          codeValue={registeredLock!.oneTimeCode}
          onSubmit={handleNext}
          onPrevious={handlePrevious}
        />
      )}
      {step === TurnKeyStep.TEST_GUEST_CODE && (
        <TestCode
          codeType="GUEST"
          codeValue={registeredLock!.guestCode}
          onSubmit={handleNext}
          onPrevious={handlePrevious}
        />
      )}
      {step === TurnKeyStep.TEST_OWNER_CODE && (
        <TestCode
          codeType="OWNER"
          codeValue={registeredLock!.ownerCode}
          onSubmit={handleNext}
          onPrevious={handlePrevious}
        />
      )}
      {step === TurnKeyStep.DONE && <Done onSubmit={handleNext} />}
    </div>
  );
};

export default TurnKeyStepWrapper;
