import { ICellRendererParams } from "ag-grid-community";
import React, { FC } from "react";
import DeviceTableRow from "../../../models/DeviceTableRow";

interface ButtonCellRendererProps extends ICellRendererParams {
  icon?: any;
  onClick?: (data: DeviceTableRow) => void;
}

const ButtonCellRenderer: FC<ButtonCellRendererProps> = (props) => {
  const handleClick = () => {
    props?.onClick?.(props?.data);
  };
  return (
    <button
      style={{
        margin: 0,
        padding: 0,
        verticalAlign: "middle",
        backgroundColor: "transparent",
      }}
      onClick={handleClick}
    >
      <span className="cell-renderer-button-icon">{props.icon}</span>
    </button>
  );
};

export default ButtonCellRenderer;
