import { FC } from "react";
import { ICellRendererParams } from "ag-grid-community";

import { ADMIN_URL } from "../../../core/constants";

const TicketRenderer: FC<ICellRendererParams> = (props) => {
  return (
    <a
      href={`${ADMIN_URL}/admin/dashboard/tickets/view?TicketID=${props.value}`}
      target="_blank"
    >
      {props.value}
    </a>
  );
};

export default TicketRenderer;
