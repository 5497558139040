/**
 * Validates an env variable is present
 * @param variable environment var name
 */
function validateAndGetEnvVar(variable: any): string {
  const value = process.env[variable];
  if (value) return value;
  const message = `Variable ${variable} is undefined or empty`;

  // Do not throw an error when unit testing
  if (process.env.NODE_ENV !== "test") throw new Error(message);
  else return variable;
}

export const IDP_SCOPES =
  "units:read units:write reservations:read users:read device-data:read device-data:write smart-home:write smart-home:reports";
export const IDP_GATEWAY = process.env["REACT_APP_IDP_GATEWAY"]; // empty on stage/prod
export const IDP_AUDIENCE = validateAndGetEnvVar("REACT_APP_IDP_AUDIENCE");
export const IDP_REDIRECT_URI = validateAndGetEnvVar(
  "REACT_APP_IDP_REDIRECT_URI",
);
export const IDP_CLIENT_ID = validateAndGetEnvVar("REACT_APP_IDP_CLIENT_ID");
export const SMART_HOME_API = validateAndGetEnvVar("REACT_APP_SMARTHOME_API");
export const CONNECT_API = validateAndGetEnvVar("REACT_APP_CONNECT_API");
export const SOUND_MONITORING_API = validateAndGetEnvVar(
  "REACT_APP_SOUND_MONITORING_API",
);
export const TURNKEY_API = validateAndGetEnvVar("REACT_APP_TURNKEY_API");
export const ENVIRONMENT = validateAndGetEnvVar(
  "REACT_APP_CUSTOM_NODE_ENV_SHORT",
);

export const NON_LOCK_VENDORS = [
  "Vacasa SmartDevice - Noise Monitor",
  "Vacasa SmartDevice - Router",
  "Ariane",
  "DMP",
  "NoiseAware - Indoor Monitor",
  "NoiseAware - Outdoor Monitor",
];
if (ENVIRONMENT !== "prod") {
  NON_LOCK_VENDORS.push("DMP Test Account");
}

export const DEFAULT_HTTP_TIMEOUT = 15000;

// This variable is also defined in src/styles/_variables.scss
export const MOBILE_SCREEN_MAX_WIDTH = 800;

export const ADMIN_URL = validateAndGetEnvVar("REACT_APP_ADMIN_URL");
