import { AxiosHeaders } from "axios";
import { SMART_HOME_API } from "../core/constants";
import {
  BackendResource,
  Compatibility,
  Device,
  DeviceMake,
  DeviceModel,
  DeviceStatus,
  DeviceType,
  Gateway,
  IReports,
  Vendor,
} from "../models/SmartHomeApi";
import AbstractService from "./abstract.service";

export class SmartHomeService extends AbstractService {
  constructor() {
    super(SMART_HOME_API);
  }

  /**
   * Gets all Devices assigned to a property
   *
   * @param propertyId property unit ID
   * @param pageSize number of records to include per page
   * @param pageIndex pagination index starting at 0
   * @returns
   */
  public async getDevices(propertyId: string, pageSize = 100, pageIndex = 0) {
    const params = new URLSearchParams({
      "filter[property_id]": propertyId,
      "page[number]": (pageIndex + 1).toString(),
      "page[size]": pageSize.toString(),
    });
    const url = `/devices?${params.toString()}`;

    return (await this.get<{ data: BackendResource<Device>[] }>(url))!;
  }

  /**
   * Creates a new device
   * @param device Full device object
   * @returns http result
   */
  private async createDevice(device: Device) {
    const url = `/devices`;
    const payload = {
      data: {
        type: "devices",
        attributes: device,
      },
    };
    return await this.post<{ data: BackendResource<Device> }>(url, payload);
  }

  /**
   * Updates a device
   * @param device Full device object
   * @returns http result
   */
  private async updateDevice(device: BackendResource<Device>) {
    if (device?.id === undefined)
      throw new Error("Expected object to be non-null");

    const url = `/devices/${device.id}`;
    const payload = {
      data: device,
    };
    return await this.patch<{ data: BackendResource<Device> }>(url, payload);
  }

  /**
   * Deletes a given device
   * @param deviceId ID of device to delete
   * @returns
   */
  private async deleteDevice(deviceId: number, operator?: string) {
    const url = `/devices/${deviceId}`;
    const headers = new AxiosHeaders();
    if (operator) headers.set("operator-id", operator);

    await this.delete(url, { headers });
  }

  /**
   * Gets all Device Gateways assigned to a property
   *
   * @param propertyId property unit ID
   * @param pageSize number of records to include per page
   * @param pageIndex pagination index starting at 0
   * @returns
   */
  public async getGateways(propertyId: string, pageSize = 100, pageIndex = 0) {
    const params = new URLSearchParams({
      "filter[property_id]": propertyId,
      "page[number]": (pageIndex + 1).toString(),
      "page[size]": pageSize.toString(),
    });
    const url = `/device_gateways?${params.toString()}`;

    return (await this.get<{ data: BackendResource<Gateway>[] }>(url))!;
  }

  /**
   * Creates a device gateway
   * @param gateway Full gateway object
   * @returns
   */
  private async createGateway(gateway: Gateway) {
    const url = `/device_gateways`;
    const payload = {
      data: {
        type: "device_gateways",
        attributes: gateway,
      },
    };
    return await this.post<{ data: BackendResource<Gateway> }>(url, payload);
  }

  /**
   * Updates a device gateway
   * @param gateway Full gateway object
   * @returns
   */
  private async updateGateway(gateway: BackendResource<Gateway>) {
    if (gateway?.id === undefined)
      throw Error("Expected object to be non-null");

    const url = `/device_gateways/${gateway.id}`;
    const payload = {
      data: gateway,
    };
    return await this.patch<{ data: BackendResource<Gateway> }>(url, payload);
  }

  /**
   * Deletes a given device gateway
   * @param deviceGatewayId ID of device gateway to delete
   * @returns
   */
  private async deleteGateway(deviceGatewayId: number, operator?: string) {
    const url = `/device_gateways/${deviceGatewayId}`;
    const headers = new AxiosHeaders();
    if (operator) headers.set("operator-id", operator);

    await this.delete(url, { headers });
  }

  /**
   * Get all defined device types
   *
   * @param pageSize number of records to include per page
   * @param pageIndex pagination index starting at 0
   * @returns
   */
  public async getDeviceTypes(
    pageSize = 200,
    pageIndex = 0,
    includeDeleted = false,
  ) {
    const params = new URLSearchParams({
      "page[number]": (pageIndex + 1).toString(),
      "page[size]": pageSize.toString(),
    });
    if (includeDeleted) params.append("filter[deleted_at.any]", "true");

    const url = `/device_types?${params.toString()}`;

    return (await this.get<{ data: BackendResource<DeviceType>[] }>(url))!;
  }

  /**
   * Get all defined device makes
   *
   * @param pageSize number of records to include per page
   * @param pageIndex pagination index starting at 0
   * @returns
   */
  public async getDeviceMakes(pageSize = 200, pageIndex = 0) {
    const params = new URLSearchParams({
      "page[number]": (pageIndex + 1).toString(),
      "page[size]": pageSize.toString(),
    });
    const url = `/device_makes?${params.toString()}`;

    return (await this.get<{ data: BackendResource<DeviceMake>[] }>(url))!;
  }

  /**
   * Get all defined device models
   *
   * @param pageSize number of records to include per page
   * @param pageIndex pagination index starting at 0
   * @returns
   */
  public async getDeviceModels(pageSize = 200, pageIndex = 0) {
    const params = new URLSearchParams({
      "page[number]": (pageIndex + 1).toString(),
      "page[size]": pageSize.toString(),
    });
    const url = `/device_models?${params.toString()}`;

    return (await this.get<{ data: BackendResource<DeviceModel>[] }>(url))!;
  }

  /**
   * Get all defined vendors
   *
   * @param pageSize number of records to include per page
   * @param pageIndex pagination index starting at 0
   * @returns
   */
  public async getVendors(pageSize = 250, pageIndex = 0) {
    const params = new URLSearchParams({
      "page[number]": (pageIndex + 1).toString(),
      "page[size]": pageSize.toString(),
    });
    const url = `/vendors?${params.toString()}`;

    return (await this.get<{ data: BackendResource<Vendor>[] }>(url))!;
  }

  /**
   * Verifies Device Status
   *
   * @param externalId Device Gateway external ID
   * @param vendor Device Gateway vendor ID
   * @returns
   */
  public async verifyDeviceStatus(
    externalId: string,
    vendor: number,
    signal?: AbortSignal,
  ) {
    const url = `/device_status`;

    const payload = {
      data: {
        type: "device_status",
        attributes: {
          vendor_id: vendor,
          device_id: externalId,
        },
      },
    };
    return await this.post<{ data: DeviceStatus[] }>(url, payload, { signal });
  }

  /**
   * Sequentially creates a Gateway then Device so that they can be linked.
   *
   * @param device
   * @param gateway
   * @returns
   */
  public async createDeviceAndGateway(
    device: Device,
    gateway: Gateway,
  ): Promise<{
    device: BackendResource<Device>;
    gateway: BackendResource<Gateway>;
  }> {
    return await this.createGateway(gateway).then((res) => {
      const gatewayResource = res!.data;
      device.device_gateway = gatewayResource.id;

      const parseDeviceReponse = (deviceResource: BackendResource<Device>) => {
        return {
          device: deviceResource,
          gateway: gatewayResource,
        };
      };

      return this.createDevice(device)
        .then((res2) => parseDeviceReponse(res2!.data))
        .catch((_err) => {
          // retry 1 time before reporting error
          return this.createDevice(device).then((res3) =>
            parseDeviceReponse(res3!.data),
          );
        });
    });
  }

  /**
   * Deletes a gateway and device in parallel
   * @param deviceId
   * @param gatewayId
   * @returns
   */
  public deleteDeviceAndGateway(
    deviceId: number,
    gatewayId: number,
    operator?: string,
  ): Promise<boolean> {
    return Promise.allSettled([
      this.deleteDevice(deviceId, operator),
      this.deleteGateway(gatewayId, operator),
    ]).then((results) => {
      if (results.every((res) => res.status === "fulfilled")) return true;
      else if (results.every((res) => res.status === "rejected")) return false;
      else {
        // Only one of the requests failed. Retry once before quitting.
        if (results[0].status === "rejected")
          return this.deleteDevice(deviceId, operator)
            .then((_) => true)
            .catch((err) => {
              throw err;
            });
        else
          return this.deleteGateway(gatewayId, operator)
            .then((_) => true)
            .catch((err) => {
              throw err;
            });
      }
    });
  }

  public updateDeviceAndGateway(
    device: BackendResource<Device>,
    gateway: BackendResource<Gateway>,
  ): Promise<{
    device: BackendResource<Device>;
    gateway: BackendResource<Gateway>;
  }> {
    return Promise.allSettled([
      this.updateDevice(device),
      this.updateGateway(gateway),
    ]).then((results) => {
      if (results.every((res) => res.status === "fulfilled")) {
        return {
          device: (results[0] as PromiseFulfilledResult<any>).value.data,
          gateway: (results[1] as PromiseFulfilledResult<any>).value.data,
        };
      } else if (results.every((res) => res.status === "rejected"))
        throw new Error("Unable to update device");
      else {
        // Only one of the requests failed. Retry once before quitting.
        if (results[0].status === "rejected")
          return this.updateDevice(device)
            .then((updatedDevice) => {
              return {
                device: updatedDevice!.data,
                gateway: (results[1] as PromiseFulfilledResult<any>).value.data,
              };
            })
            .catch((err) => {
              throw err;
            });
        else
          return this.updateGateway(gateway)
            .then((updatedGateway) => {
              return {
                device: (results[0] as PromiseFulfilledResult<any>).value.data,
                gateway: updatedGateway!.data,
              };
            })
            .catch((err) => {
              throw err;
            });
      }
    });
  }

  public async getCompatibility(propertyId: string) {
    const params = new URLSearchParams({
      "filter[property_id]": propertyId,
    });
    const url = `/property_limitations?${params.toString()}`;
    return (await this.get<{ data: BackendResource<Compatibility>[] }>(url))!;
  }

  public async createCompatibility(
    compatibility: Compatibility,
    operator?: string,
  ) {
    const url = `/property_limitations`;
    const headers = this.getDefaultHeaders();
    if (operator) headers["operator-id"] = operator;

    const payload = {
      data: {
        type: "property_limitations",
        attributes: compatibility,
      },
    };

    return await this.post<{ data: BackendResource<Compatibility> }>(
      url,
      payload,
      { headers },
    );
  }

  public async updateCompatibility(
    updatedCompatibility: BackendResource<Compatibility>,
    operator?: string,
  ) {
    const url = `/property_limitations/${updatedCompatibility.id}`;
    const headers = this.getDefaultHeaders();
    if (operator) headers["operator-id"] = operator;

    const payload = {
      data: updatedCompatibility,
    };

    return this.patch<{ data: BackendResource<Compatibility> }>(url, payload, {
      headers,
    });
  }

  public async deleteCompatibility(
    compatibility: BackendResource<Compatibility>,
    operator?: string,
  ) {
    const url = `/property_limitations/${compatibility.id}`;
    const headers = this.getDefaultHeaders();
    if (operator) headers["operator-id"] = operator;

    await this.delete(url, { headers });
    return { data: compatibility };
  }

  public async getReports(propertyId: string): Promise<IReports> {
    const url = `/units/${propertyId}/reports`;
    const headers = this.getDefaultHeaders();
    const result = await this.get<IReports>(url, { headers });
    return result as IReports;
  }
}

export default SmartHomeService;
