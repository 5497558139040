import axios, {
  AxiosRequestConfig,
  AxiosResponse,
  Method,
  isAxiosError,
} from "axios";
import AuthService from "./auth.service";
import { DEFAULT_HTTP_TIMEOUT } from "../core/constants";
import { HttpResponseError, HttpTimeoutError } from "../core/errors";

abstract class AbstractService {
  private axiosClient;

  constructor(baseURL: string) {
    this.axiosClient = axios.create({
      baseURL,
      timeout: DEFAULT_HTTP_TIMEOUT,
    });
  }

  protected async get<T>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<T | null> {
    const res = await this.send<T>("get", url, undefined, config);
    if (this.is2xx(res)) return res.data;
    else if (res.status === 404) return null;
    throw new HttpResponseError(res);
  }

  protected async post<T>(
    url: string,
    body: any,
    config?: AxiosRequestConfig,
  ): Promise<T> {
    const res = await this.send<T>("post", url, body, config);
    if (this.is2xx(res)) return res.data;
    throw new HttpResponseError(res);
  }

  protected async put<T>(
    url: string,
    body: any,
    config?: AxiosRequestConfig,
  ): Promise<T> {
    const res = await this.send<T>("put", url, body, config);
    if (this.is2xx(res)) return res.data;
    throw new HttpResponseError(res);
  }

  protected async patch<T>(
    url: string,
    body: any,
    config?: AxiosRequestConfig,
  ): Promise<T> {
    const res = await this.send<T>("patch", url, body, config);
    if (this.is2xx(res)) return res.data;
    throw new HttpResponseError(res);
  }

  protected async delete<T>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<T> {
    const res = await this.send<T>("delete", url, undefined, config);
    if (this.is2xx(res)) return res.data;
    throw new HttpResponseError(res);
  }

  protected async send<T>(
    method: Method,
    url: string,
    data: any,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T, any>> {
    try {
      if (!config) config = {};
      if (!config.headers) config.headers = this.getDefaultHeaders();
      else {
        config.headers = {
          ...this.getDefaultHeaders(),
          ...config.headers,
        };
      }

      const req: AxiosRequestConfig = {
        method,
        url,
        data,
        ...config,
      };
      const res = await this.axiosClient.request(req);

      return res;
    } catch (err) {
      if (isAxiosError(err) && err.response) {
        return err.response;
      }

      const error = err as any;
      if (error.code === "ECONNABORTED" && error.message.includes("timeout")) {
        throw new HttpTimeoutError(error);
      } else if (!(err instanceof Error)) {
        throw new Error(
          `unexpected error during http request: ${JSON.stringify(err)}`,
          {
            cause: err,
          },
        );
      }
      throw err;
    }
  }

  protected getDefaultHeaders(): any {
    const token = AuthService.accessToken;
    return {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
  }

  private is2xx(res: AxiosResponse): boolean {
    return 200 <= res.status && res.status < 300;
  }
}

export default AbstractService;
