import { useSelector } from "react-redux";
import { StoreData } from "..";

export const usePropertyLoading = () => {
  return useSelector((state: StoreData) => state.property.isLoading);
};
export const usePropertyData = () => {
  return useSelector((state: StoreData) => state.property.data);
};
export const usePropertyError = () => {
  return useSelector((state: StoreData) => state.property.error);
};
