import { useCallback, useMemo, useState } from "react";
import { FormValue } from "../models/Form";

// Hook
export const useFormValue = function <T>(
  defaultValue: T | undefined,
  defaultValid: boolean,
): FormValue<T> {
  const [value, setValue] = useState(defaultValue);
  const [isValid, setIsValid] = useState(defaultValid);

  const onChange = useCallback(
    (newValue: T | undefined, isValid: boolean) => {
      setValue(newValue);
      setIsValid(isValid);
    },
    [setValue, setIsValid],
  );

  return useMemo(
    () => ({
      value,
      isValid,
      onChange,
      reset: () => {
        setValue(defaultValue);
        setIsValid(defaultValid);
      },
      isModified: () => {
        return defaultValue !== value;
      },
    }),
    [defaultValue, defaultValid, onChange, value, isValid],
  );
};
