import { FC } from "react";
import { Navigate } from "react-router-dom";
import PageNotFound from "../PageNotFound/PageNotFound";

const PostAuthorization: FC = () => {
  const authRedirect = localStorage.getItem("auth-redirect");

  let redirect: { pathname: string; search: string } | null = null;
  try {
    if (authRedirect) redirect = JSON.parse(authRedirect);
  } catch {}

  if (!redirect || redirect.pathname === "/") {
    return <PageNotFound />;
  } else {
    // Note: Admin will always redirect to ../HomePage.tsx
    return <Navigate replace to={`${redirect.pathname}${redirect.search}`} />;
  }
};

export default PostAuthorization;
