import React, { FC, useState } from "react";
import {
  BackendResource,
  DeviceMake,
} from "../../../../../models/SmartHomeApi";
import SelectList from "../../../../../elements/Form/SelectList";
import { parseResource } from "../../lib/parse-resource";
import { useFormValue } from "../../../../../hooks/FormValue";
import StepFooter from "../StepFooter/StepFooter";

interface SelectMakeStepProps {
  deviceMakes?: BackendResource<DeviceMake>[];
  onPrevious: () => void;
  onSubmit: (deviceMake: BackendResource<DeviceMake>) => void;
}

const SelectMakeStep: FC<SelectMakeStepProps> = ({
  deviceMakes,
  onPrevious,
  onSubmit,
}) => {
  const deviceMakeForm = useFormValue<number>(undefined, false);
  const [deviceMakeOptions] = useState(deviceMakes?.map(parseResource));

  const handlePrevious = () => {
    deviceMakeForm.reset();
    onPrevious();
  };

  const handleSubmit = function () {
    if (deviceMakeForm.isValid) {
      const make = deviceMakes?.find((x) => x.id === deviceMakeForm.value);
      if (make) {
        onSubmit(make);
      }
    }
  };

  return (
    <div className="select-list-wrapper">
      <SelectList
        title="New Smart Device"
        description="SELECT DEVICE MAKE"
        value={deviceMakeForm}
        options={deviceMakeOptions ?? []}
        displayFilter={true}
        filterPlaceholder="search makes"
      />
      <StepFooter
        onSubmit={handleSubmit}
        onPrevious={handlePrevious}
        disabled={!deviceMakeForm.isValid}
      />
    </div>
  );
};

export default SelectMakeStep;
