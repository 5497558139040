import React, { FC, useEffect, useState } from "react";
import { Icon, Image } from "@vacasa/react-components-lib";
import { useFormValue } from "../../../hooks/FormValue";
import BasicButton from "../../../elements/Button/BasicButton";
import FormInput from "../../../elements/Form";
import { useDebounce } from "../../../hooks/Debounce";

import "./AddAlertSubscriber.scss";
import SmartDeviceService from "../../../services/smart-device.service";
import {
  AddAlertSubscriberRequest,
  AlertSubscriber,
  Login,
} from "../../../models/SmartDeviceApi";
import { CanceledError } from "axios";
import { PropertyStore } from "../../../store/property";

interface AddAlertSubscriberProps {
  existingSubscribers?: AlertSubscriber[];
  onSubmit?: (data: AlertSubscriber) => void;
}

const AddAlertSubscriber: FC<AddAlertSubscriberProps> = (props) => {
  const property = PropertyStore.selectors.usePropertyData()!;

  const [isSaving, setIsSaving] = useState(false);
  const [apiSaveError, setApiSaveError] = useState<string>();

  const [isFormValid, setIsFormValid] = useState(false);
  const username = useFormValue<string>("", true);
  const debouncedUsername = useDebounce(username.value, 500);
  const [methodEmail, setMethodEmail] = useState(false);
  const [methodSms, setMethodSms] = useState(false);
  const [subscriptionNoise, setSubscriptionNoise] = useState(false);
  const [subscriptionTemperature, setSubscriptionTemperature] = useState(false);
  const [subscriptionRouter, setSubscriptionRouter] = useState(false);

  const [userData, setUserData] = useState<Login>();
  const [isDuplicateUser, setIsDuplicateUser] = useState(false);
  const [isUserDataLoading, setIsUserDataLoading] = useState(false);
  const [userDataError, setUserDataError] = useState<string>();

  // Loads user based on username
  useEffect(() => {
    const abortController = new AbortController();
    const service = new SmartDeviceService();

    const trimmed = debouncedUsername?.trim();
    if (trimmed && trimmed[0] !== "." && trimmed[trimmed.length - 1] !== ".") {
      setIsUserDataLoading(true);
      setUserDataError(undefined);
      service
        .getLoginByEmailAddress(`${trimmed}@vacasa.com`, abortController.signal)
        .then((user) => setUserData(user ?? undefined))
        .catch((err) => {
          if (!(err instanceof CanceledError)) setUserDataError(err.message);
        })
        .finally(() => setIsUserDataLoading(false));
    } else {
      setIsUserDataLoading(false);
      setUserDataError(undefined);
    }

    return () => {
      abortController.abort();
    };
  }, [debouncedUsername]);

  // Validates form
  useEffect(() => {
    const atLeastOneMethod = methodSms || methodEmail;
    const atLeastOneSubscription =
      subscriptionNoise || subscriptionTemperature || subscriptionRouter;

    setIsFormValid(
      !!userData &&
        atLeastOneMethod &&
        atLeastOneSubscription &&
        !isDuplicateUser,
    );
  }, [
    userData,
    methodEmail,
    methodSms,
    subscriptionNoise,
    subscriptionTemperature,
    subscriptionRouter,
    isDuplicateUser,
  ]);

  // Checks whether user is a duplicate subscriber
  useEffect(() => {
    if (!userData) {
      setIsDuplicateUser(false);
      return;
    }

    const index = props.existingSubscribers?.findIndex(
      (x) => x.Login.Id === userData.Id,
    );
    setIsDuplicateUser(index !== undefined && index >= 0);
  }, [userData, props.existingSubscribers]);

  const handleSmsChange = (e: any) => setMethodSms(e.target.checked);
  const handleEmailChange = (e: any) => setMethodEmail(e.target.checked);
  const toggleNoise = () => setSubscriptionNoise(!subscriptionNoise);
  const toggleTemperature = () =>
    setSubscriptionTemperature(!subscriptionTemperature);
  const toggleRouter = () => setSubscriptionRouter(!subscriptionRouter);

  // Parses form and submits data
  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (isFormValid) {
      const payload: AddAlertSubscriberRequest = {
        DeviceGroup: property.id,
        LoginId: userData!.Id,
        SendEmail: methodEmail,
        SendSms: methodSms,
        SensorNoiseAlert: subscriptionNoise,
        SensorTemperatureAlert: subscriptionTemperature,
        RouterConnectedAlert: subscriptionRouter,
      };
      addSubscriber(payload);
    }
  };

  const addSubscriber = async (data: AddAlertSubscriberRequest) => {
    const service = new SmartDeviceService();
    try {
      setIsSaving(true);
      setApiSaveError(undefined);
      const result = await service.addAlertSubscriber(data);
      props.onSubmit?.(result);
    } catch (err) {
      setApiSaveError((err as Error).message);
    } finally {
      setIsSaving(false);
    }
  };

  const getUserDetails = () => {
    if (!username.value) return undefined;
    else if (isUserDataLoading) {
      return (
        <div>
          <Image.Spinner width={36} height={36} />
        </div>
      );
    } else if (userDataError) {
      return (
        <div className="no-user">
          <span className="error-message">{userDataError}</span>
        </div>
      );
    } else if (!userData) {
      return <div className="no-user">User not found</div>;
    } else if (isDuplicateUser) {
      return (
        <div className="user-detail">
          <div className="subscribe-method">
            <div className="name">
              {userData.FirstName} {userData.LastName}
            </div>
            <div className="no-user">
              <div>This user is already set up as a subscriber.</div>
              <div>
                If you&apos;d like to update the alerts for this user, please
                edit the existing subscriber.
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="user-detail">
          <div className="subscribe-method">
            <div className="name">
              {userData.FirstName} {userData.LastName}
            </div>
            <div>
              <input
                type="checkbox"
                checked={methodSms}
                onChange={handleSmsChange}
                disabled={isSaving}
              />
              <span className="phone">{userData.Phone}</span>
            </div>
            <div>
              <input
                type="checkbox"
                checked={methodEmail}
                onChange={handleEmailChange}
                disabled={isSaving}
              />
              <span className="email">{userData.EmailAddress}</span>
            </div>
          </div>
          <div className="subscribe-channel">
            <div className="label-title">Subscriptions</div>
            <div
              className={`badge SensorNoiseAlert ${
                subscriptionNoise ? "checked" : "unchecked"
              }`}
              onClick={toggleNoise}
            >
              <span className="icon">
                <Icon.Radio />
              </span>
              <span className="title">Noise Sensor</span>
            </div>
            <div
              className={`badge SensorTemperatureAlert ${
                subscriptionTemperature ? "checked" : "unchecked"
              }`}
              onClick={toggleTemperature}
            >
              <span className="icon">
                <Icon.Thermometer />
              </span>
              <span className="title">Temperature</span>
            </div>
            <div
              className={`badge RouterConnectedAlert ${
                subscriptionRouter ? "checked" : "unchecked"
              }`}
              onClick={toggleRouter}
            >
              <span className="icon">
                <Icon.Wifi />
              </span>
              <span className="title">Router</span>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="component-add-alert-subscriber">
      <div className="component-title">Add Alert Subscriber</div>
      <form onSubmit={handleSubmit}>
        <div className="input-username">
          <FormInput.Text
            label="Username"
            value={username}
            width={150}
            disabled={isSaving}
          />
          <span className="input-end">@vacasa.com</span>
        </div>
        {getUserDetails()}
        <div className="footer">
          <BasicButton
            type="submit"
            disabled={!isFormValid || isSaving}
            display="fill"
          >
            Add Alert Subscriber
          </BasicButton>
          {isSaving && (
            <span className="spinner">
              <Image.Spinner height={36} width={36} />
            </span>
          )}
          <div className="error-message">{apiSaveError}</div>
        </div>
      </form>
    </div>
  );
};

export default AddAlertSubscriber;
