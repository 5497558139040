import { Icon } from "@vacasa/react-components-lib";
import React, { FC } from "react";
import {
  formatDateTime,
  formatMacAddressForDisplay,
} from "../../../../../core/format";
import {
  RegisteredDevice,
  RegisteredDeviceType,
} from "../../../../../models/SmartDeviceApi";

import "./DeviceDetails.scss";

interface DeviceDetailProps {
  title: string;
  device: RegisteredDevice;
  children?: React.ReactNode;
}

const DeviceDetail: FC<DeviceDetailProps> = (props) => {
  const getHeader = () => {
    let icon = <Icon.AlertOctagon />;

    if (props.device.DeviceType === RegisteredDeviceType.Sensor) {
      icon = <Icon.Radio />;
    } else if (props.device.DeviceType === RegisteredDeviceType.Router) {
      icon = <Icon.Wifi />;
    }

    return (
      <div className="device-detail-header">
        <span className="icon">{icon}</span>
        <span className="title">{props.title}</span>
      </div>
    );
  };

  const getThresholdText = () => {
    if (props.device.DeviceType === RegisteredDeviceType.Sensor)
      return "Decibel Threshold";
    else if (props.device.DeviceType === RegisteredDeviceType.Router)
      return "Connected Threshold";
    else return "";
  };

  const getThresholdValue = () => {
    if (props.device.DeviceType === RegisteredDeviceType.Sensor)
      return `${props.device.NoiseAlertDecibelThreshold} dB`;
    else if (props.device.DeviceType === RegisteredDeviceType.Router)
      return `${props.device.ConnectedAlertThreshold}`;
    else return "";
  };

  const getCustomDetail = () => {
    if (props.device.DeviceType === RegisteredDeviceType.Sensor) {
      return (
        <div className="row">
          <div className="col">
            <div className="label">Min Temperature</div>
            <div className="unweighted">
              {props.device.TemperatureLowThreshold}°F
            </div>
          </div>
          <div className="col">
            <div className="label">Max Temperature</div>
            <div className="unweighted float-right">
              {props.device.TemperatureHighThreshold}°F
            </div>
          </div>
        </div>
      );
    } else return undefined;
  };

  return (
    <div className="component-device-item-detail">
      {getHeader()}
      <div className="device-detail-container">
        <div className="row">
          <div className="col">
            <div className="column-title">Mac Address</div>
          </div>
          <div className="col">
            <div className="macaddress float-right">
              {formatMacAddressForDisplay(props.device.MacAddress)}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="label">Device Name</div>
            <div>{props.device.DeviceName}</div>
          </div>
          <div className="col">
            <div className="label">Registered To</div>
            <div className="unweighted float-right">
              {props.device.DeviceGroup}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="label">Registration Date</div>
            <div>{formatDateTime(props.device.RegistrationDate)}</div>
          </div>
          <div className="col">
            <div className="label">{getThresholdText()}</div>
            <div className="unweighted float-right">{getThresholdValue()}</div>
          </div>
        </div>
        {getCustomDetail()}
        {props.device.AlertsPauseUntilDate && (
          <div className="row">
            <div className="col">
              <div className="label">Alerts Paused Until</div>
              <div>{formatDateTime(props.device.AlertsPauseUntilDate)}</div>
            </div>
          </div>
        )}
        <div className="col note-text">
          <div className="row">
            <div className="col">
              Updated by {props.device.UpdatedByEmailAddress}
            </div>
            <div className="col float-right">
              {formatDateTime(props.device.UpdatedDate)}
            </div>
          </div>
        </div>
      </div>
      <div className="detail-child-wrapper">{props.children}</div>
    </div>
  );
};

export default DeviceDetail;
