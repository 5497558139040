import React, { FC } from "react";
import BasicButton from "../../../../../elements/Button/BasicButton";

interface StepFooterProps {
  disabled?: boolean;
  onPrevious?: () => void;
  onSubmit: () => void;
  submitText?: string;
  previousText?: string;
}

const StepFooter: FC<StepFooterProps> = ({
  disabled = false,
  onPrevious,
  onSubmit,
  submitText = "Next",
  previousText = "Back",
}) => {
  const handlePrevious = () => {
    onPrevious?.();
  };

  const handleSubmit = () => {
    onSubmit();
  };

  return (
    <div className="select-form-footer">
      {onPrevious && (
        <div className="back">
          <BasicButton display="alt" onClick={handlePrevious}>
            {previousText}
          </BasicButton>
        </div>
      )}
      <div className={onPrevious ? "next" : "full-width-next"}>
        <BasicButton display="fill" onClick={handleSubmit} disabled={disabled}>
          {submitText}
        </BasicButton>
      </div>
    </div>
  );
};

export default StepFooter;
