import { Icon } from "@vacasa/react-components-lib";
import React, { FC, useEffect, useState } from "react";
import {
  formatDateTime,
  formatMacAddressForDisplay,
} from "../../../../core/format";
import { AlertType, ReadingAlert } from "../../../../models/SmartDeviceApi";

import "./Alert.scss";

interface AlertProps {
  alert: ReadingAlert;
}

interface NotifiedSubscriber {
  id: number;
  fullName: string;
  email: boolean;
  emailSendDate?: string;
  sms: boolean;
  smsSendDate?: string;
}

const Alert: FC<AlertProps> = (props) => {
  const [notifiedSubscribers, setNotifiedSubscribers] =
    useState<NotifiedSubscriber[]>();

  // Parses notified users to more convenient model
  useEffect(() => {
    if (!props.alert.Notifications) {
      setNotifiedSubscribers(undefined);
      return;
    }

    const subscriberMap: { [key: number]: NotifiedSubscriber } = {};
    props.alert.Notifications.forEach((notification) => {
      const user = notification.Recipient;

      if (!subscriberMap[user.Id]) {
        subscriberMap[user.Id] = {
          id: user.Id,
          fullName: `${user.FirstName} ${user.LastName}`,
          email: false,
          sms: false,
        };
      }
      const email = notification.NsType === "email";
      const sms = notification.NsType === "sms";

      subscriberMap[user.Id].email = subscriberMap[user.Id].email || email;
      subscriberMap[user.Id].sms = subscriberMap[user.Id].sms || sms;

      // For SMS there should be a sent date, and then a delivered date.
      // An error, such as invalid phone number, can occur after the sent date.
      // For Email there is no delivered date.
      let completedDate: string | undefined;
      if (notification.NsDeliveredDate) {
        completedDate = notification.NsDeliveredDate;
      } else if (notification.NsSentDate && email) {
        completedDate = notification.NsSentDate;
      }

      if (email) subscriberMap[user.Id].emailSendDate = completedDate;
      if (sms) subscriberMap[user.Id].smsSendDate = completedDate;
    });
    setNotifiedSubscribers(Object.values(subscriberMap));
  }, [props.alert]);

  const getTypeBadge = () => {
    let icon = <Icon.AlertOctagon />;
    if (props.alert.AlertType === AlertType.SensorNoiseAlert)
      icon = <Icon.Radio />;
    else if (props.alert.AlertType === AlertType.SensorTemperatureAlert)
      icon = <Icon.Thermometer />;
    else if (props.alert.AlertType === AlertType.RouterConnectedAlert)
      icon = <Icon.Wifi />;

    return <div className={`badge ${props.alert.AlertType}`}>{icon}</div>;
  };

  const getAlertValue = () => {
    if (props.alert.AlertType === AlertType.SensorNoiseAlert) {
      const firstContributor = props.alert.Contributors?.[0];
      return firstContributor?.Readings?.[0]?.Decibels + " dB";
    } else if (props.alert.AlertType === AlertType.SensorTemperatureAlert) {
      const firstContributor = props.alert.Contributors?.[0];
      return firstContributor?.Readings?.[0]?.Temperature + " F";
    } else if (props.alert.AlertType === AlertType.RouterConnectedAlert) {
      const firstContributor = props.alert.Contributors?.[0];
      return firstContributor?.Readings?.[0]?.Connected + " connected";
    }
  };
  const getThresholdText = () => {
    if (props.alert.AlertType === AlertType.SensorNoiseAlert) {
      const firstContributor = props.alert.Contributors?.[0];
      return `Threshold ${firstContributor?.NoiseAlertDecibelThreshold} dB`;
    } else if (props.alert.AlertType === AlertType.SensorTemperatureAlert) {
      const firstContributor = props.alert.Contributors?.[0];
      return `Range [${firstContributor?.TemperatureLowThreshold}, ${firstContributor?.TemperatureHighThreshold}] F`;
    } else if (props.alert.AlertType === AlertType.RouterConnectedAlert) {
      const firstContributor = props.alert.Contributors?.[0];
      return `Threshold ${firstContributor?.ConnectedAlertThreshold}`;
    }
  };

  const getSubscriberBadge = function (subscriber: NotifiedSubscriber) {
    return (
      <div className="subscriber-badge" key={subscriber.id}>
        {subscriber.email && (
          <span className="icon mail">
            <Icon.Mail />
          </span>
        )}
        {subscriber.sms && (
          <span className="icon sms">
            <Icon.Smartphone />
          </span>
        )}
        <span>{subscriber.fullName}</span>
      </div>
    );
  };

  return (
    <div className="component-device-monitoring-alert">
      <div className="alert-details">
        <div className="alert-info">
          {getTypeBadge()}
          <span className="timestamp">
            {formatDateTime(props.alert.AlertDate)}
          </span>
        </div>
        <div className="macAddress">
          {formatMacAddressForDisplay(
            props.alert.Contributors?.[0]?.MacAddress,
          )}
        </div>
        <div className="device-detail">
          <span>
            {props.alert.Contributors?.[0]?.DeviceName} : {getAlertValue()}{" "}
          </span>
          <span>({getThresholdText()})</span>
        </div>
      </div>
      <div className="subscriber-details">
        {notifiedSubscribers?.map((sub) => getSubscriberBadge(sub))}
      </div>
    </div>
  );
};

export default Alert;
