import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DeviceTypeState } from ".";
import { BackendResource, DeviceType } from "../../models/SmartHomeApi";

const initialState: DeviceTypeState = {
  isLoading: false,
  data: undefined,
};

export const slice = createSlice({
  name: "device-type",
  initialState,
  reducers: {
    loadData: (state) => {
      state.isLoading = true;
      state.error = undefined;
    },
    setData: (state, action: PayloadAction<BackendResource<DeviceType>[]>) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
      state.data = [];
    },
    clear: (state) => {
      state.isLoading = false;
      state.data = [];
      state.error = undefined;
    },
  },
});
