import React, { FC, useEffect, useState } from "react";
import StepFooter from "../../../StepFooter/StepFooter";
import { useKeyListener } from "../../../../../../../hooks/KeyListeners";

interface ContactInfoProps {
  firstname: string;
  lastname: string;
  onSubmit: (firstname: string, lastname: string) => void;
  onPrevious: () => void;
}

const ContactInfo: FC<ContactInfoProps> = ({
  firstname: initialFirstName,
  lastname: initialLastName,
  onSubmit,
  onPrevious,
}) => {
  const [firstname, setFirstname] = useState<string>(initialFirstName);
  const [lastname, setLastname] = useState<string>(initialLastName);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    setIsValid(!!firstname && !!lastname);
  }, [firstname, lastname]);

  const handleFirstname = (event: any) => {
    const name = event.target.value;
    setFirstname(name?.trim() ?? "");
  };

  const handleLastname = (event: any) => {
    const name = event.target.value;
    setLastname(name?.trim() ?? "");
  };

  const handleSubmit = () => {
    if (firstname && lastname) onSubmit(firstname, lastname);
  };
  useKeyListener("Enter", handleSubmit);

  const handlePrevious = () => {
    onPrevious();
  };

  return (
    <div>
      <div className="step-header">
        <div className="title">Contact Info</div>
        <div className="description">Enter contact information</div>
      </div>
      <div className="step-body">
        <div>
          <input
            id="inp-first-name"
            type="text"
            name="first-name"
            placeholder="First name"
            value={firstname}
            onChange={handleFirstname}
            autoFocus
          ></input>
        </div>
        <div>
          <input
            id="inp-last-name"
            type="text"
            name="last-name"
            placeholder="Last name"
            value={lastname}
            onChange={handleLastname}
          ></input>
        </div>
      </div>
      <StepFooter
        onSubmit={handleSubmit}
        onPrevious={handlePrevious}
        disabled={!isValid}
      />
    </div>
  );
};

export default ContactInfo;
