import React, { FC } from "react";
import "./Spinner.scss";

interface SpinnerOverlayProps {
  fullScreen?: boolean;
}

const SpinnerOverlay: FC<SpinnerOverlayProps> = (props) => {
  const customStyle = {
    position: props.fullScreen ? ("fixed" as const) : undefined,
  };

  return (
    <div className="component-spinner-overlay" style={customStyle}>
      <div className="loading-spinner-wheel"></div>
    </div>
  );
};

export default SpinnerOverlay;
