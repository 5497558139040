import React, { FC, useState } from "react";
import {
  RegisteredDeviceType,
  RegisteredDevice,
} from "../../../models/SmartDeviceApi";
import SmartDeviceService from "../../../services/smart-device.service";

import SelectDeviceType from "./SelectDeviceType/SelectDeviceType";

import "./AddMonitoringDevice.scss";
import ConfigureDevice from "./ConfigureDevice/ConfigureDevice";
import SpinnerOverlay from "../../../elements/Spinner/SpinnerOverlay";
import { Icon } from "@vacasa/react-components-lib";

interface AddMonitoringDeviceProps {
  compatibleDevices: RegisteredDeviceType[];
  onSubmit?: (monitor: RegisteredDevice) => void;
  onCancel?: () => void;
}

const AddMonitoringDevice: FC<AddMonitoringDeviceProps> = (props) => {
  const [isSaving, setIsSaving] = useState(false);
  const [apiSaveError, setApiSaveError] = useState<string>();

  let autoSelectedStep = 0;
  let autoSelectedType: RegisteredDeviceType | undefined = undefined;
  if (props.compatibleDevices?.length === 1) {
    autoSelectedStep = 1;
    autoSelectedType = props.compatibleDevices[0];
  }

  const [step, setStep] = useState(autoSelectedStep);
  const [deviceType, setDeviceType] = useState<
    RegisteredDeviceType | undefined
  >(autoSelectedType);

  const getTitleContent = () => {
    if (step === 0) return <span>Register New Device</span>;

    let icon: React.ReactNode;
    let text: string;
    switch (deviceType) {
      case RegisteredDeviceType.Sensor:
        icon = <Icon.Radio />;
        text = "Register Sensor";
        break;
      case RegisteredDeviceType.Router:
        icon = <Icon.Wifi />;
        text = "Register Router";
        break;
      default:
        icon = <Icon.AlertOctagon />;
        text = "Register Device";
        break;
    }

    return (
      <span>
        <span className="device-icon">{icon}</span>
        <span>{text}</span>
      </span>
    );
  };

  const handleDeviceTypeSelect = (type: RegisteredDeviceType) => {
    setDeviceType(type);
    setStep(1);
  };

  const handleSubmit = (device: RegisteredDevice) => {
    const service = new SmartDeviceService();

    setIsSaving(true);
    setApiSaveError(undefined);
    service
      .registerDevice(device)
      .then((registeredDevice) => props.onSubmit?.(registeredDevice))
      .catch((err) => setApiSaveError(err.message))
      .finally(() => setIsSaving(false));
  };

  const handleCancel = () => {
    props.onCancel?.();
  };

  return (
    <div className="component-add-monitoring-device">
      <div className="add-monitoring-device-header">
        <div className="title">{getTitleContent()}</div>
      </div>
      {step === 0 && (
        <SelectDeviceType
          compatibleDevices={props.compatibleDevices}
          onSubmit={handleDeviceTypeSelect}
        />
      )}
      {step === 1 && (
        <ConfigureDevice
          deviceType={deviceType!}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      )}
      {isSaving && <SpinnerOverlay />}
      <div className="error-message">{apiSaveError}</div>
    </div>
  );
};

export default AddMonitoringDevice;
