import React, { FC } from "react";
import StepFooter from "../../../StepFooter/StepFooter";

interface TestCodeProps {
  codeType: string;
  codeValue: string;
  onSubmit: () => void;
  onPrevious: () => void;
}

const TestCode: FC<TestCodeProps> = ({
  codeType,
  codeValue,
  onSubmit,
  onPrevious,
}) => {
  const getFormattedCode = (): string => {
    return `${codeValue.substring(0, 3)} ${codeValue.substring(
      3,
      6,
    )} ${codeValue.substring(6)}`;
  };

  return (
    <div>
      <div className="step-header">
        <div className="title">Verify {codeType} code</div>
      </div>
      <div className="step-body">
        <div>Please enter the number below: </div>
        <div className="code-block">{getFormattedCode()}</div>
        <div>
          If the lock code works, the SCHLAGE button on your lock will flash
          green and the lock will beep.
        </div>
      </div>
      <StepFooter onSubmit={onSubmit} onPrevious={onPrevious} />
    </div>
  );
};

export default TestCode;
