import AuthService, { SCOPE } from "../../services/auth.service";

//matches smarthome controller scopes
const REQ_SCOPES = [
  SCOPE.UNITS_WRITE,
  SCOPE.RESERVAITIONS_WRITE,
  SCOPE.UNIT_TEST,
  SCOPE.SMARTHOME_WRITE,
  SCOPE.SMARTHOME_REPORTS,
];

export function canView() {
  return AuthService.hasScope(REQ_SCOPES);
}
