import { Image } from "@vacasa/react-components-lib";
import React, { FC, useEffect, useState } from "react";
import BasicButton from "../../../../../elements/Button/BasicButton";
import FormInput from "../../../../../elements/Form";
import { useFormValue } from "../../../../../hooks/FormValue";
import {
  RegisteredDevice,
  RegisteredDeviceType,
  RegisteredRouter,
  RegisteredSensor,
} from "../../../../../models/SmartDeviceApi";
import SmartDeviceService from "../../../../../services/smart-device.service";
import DeviceDetail from "../DeviceDetails/DeviceDetails";

import "./EditDevice.scss";

interface EditDeviceProps {
  device: RegisteredDevice;
  onSubmit?: (device: RegisteredDevice) => void;
  onCancel?: () => void;
}

const EditDevice: FC<EditDeviceProps> = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const [isFormValid, setIsFormValid] = useState(false);
  const deviceName = useFormValue(props.device.DeviceName ?? "", true);
  const decibelThreshold = useFormValue(
    (props.device as RegisteredSensor).NoiseAlertDecibelThreshold ?? 0,
    true,
  );
  const temperatureLowThreshold = useFormValue(
    (props.device as RegisteredSensor).TemperatureLowThreshold ?? 0,
    true,
  );
  const temperatureHighThreshold = useFormValue(
    (props.device as RegisteredSensor).TemperatureHighThreshold ?? 0,
    true,
  );
  const connectedThreshold = useFormValue(
    (props.device as RegisteredRouter).ConnectedAlertThreshold ?? 0,
    true,
  );

  useEffect(() => {
    let isValid = false;
    let changeMade = false;
    if (deviceName.isValid) {
      if (props.device.DeviceType === RegisteredDeviceType.Sensor) {
        isValid =
          decibelThreshold.isValid &&
          temperatureLowThreshold.isValid &&
          temperatureHighThreshold.isValid;
        changeMade =
          decibelThreshold.value !== props.device.NoiseAlertDecibelThreshold ||
          temperatureLowThreshold.value !==
            props.device.TemperatureLowThreshold ||
          temperatureHighThreshold.value !==
            props.device.TemperatureHighThreshold ||
          deviceName.value !== props.device.DeviceName;
      } else if (props.device.DeviceType === RegisteredDeviceType.Router) {
        isValid = connectedThreshold.isValid;
        changeMade =
          connectedThreshold.value !== props.device.ConnectedAlertThreshold;
      }
    }

    setIsFormValid(isValid && changeMade);
  }, [
    deviceName,
    decibelThreshold,
    temperatureLowThreshold,
    temperatureHighThreshold,
    connectedThreshold,
    props.device,
  ]);

  const handleSubmit = () => {
    if (isFormValid) {
      const service = new SmartDeviceService();

      setIsLoading(true);
      setErrorMessage(undefined);

      const updatedDevice = { ...props.device } as any;
      updatedDevice.DeviceName = deviceName.value!;

      if (props.device.DeviceType === RegisteredDeviceType.Sensor) {
        updatedDevice.NoiseAlertDecibelThreshold = decibelThreshold.value;
        updatedDevice.TemperatureLowThreshold = temperatureLowThreshold.value;
        updatedDevice.TemperatureHighThreshold = temperatureHighThreshold.value;
      } else if (props.device.DeviceType === RegisteredDeviceType.Router) {
        updatedDevice.ConnectedAlertThreshold = connectedThreshold.value!;
      }

      service
        .updateDevice(updatedDevice)
        .then((updatedDevice) => props.onSubmit?.(updatedDevice))
        .catch((err) => setErrorMessage(err.message))
        .finally(() => setIsLoading(false));
    }
  };

  const handleCancel = () => {
    props.onCancel?.();
  };

  const getTitle = () => {
    if (props.device.DeviceType === RegisteredDeviceType.Sensor)
      return "Edit Sound Monitor";
    else if (props.device.DeviceType === RegisteredDeviceType.Router)
      return "Edit Router";

    return "Edit Device";
  };

  const getSensorFormContent = () => {
    return (
      <>
        <div>
          <FormInput.Text
            label="Device Name"
            display="compact"
            value={deviceName}
            required
          />
        </div>
        <div>
          <FormInput.Number
            label="Decibel Threshold"
            display="compact"
            value={decibelThreshold}
            helpText="The default for stand alone properties is 93db
                        and properties with shared walls is 90db.  We do not recommend
                        changing these settings unless there is an excess of alerts that
                        you feel is unwarranted for the property and the levels need to increase."
            required
          />
        </div>
        <div>
          <FormInput.Number
            label="Temperature Low Threshold"
            display="compact"
            value={temperatureLowThreshold}
            helpText="The default temperature threshold is a minimum of 50 and a maximum of 80 degrees. You can override as needed."
            required
          />
        </div>
        <div>
          <FormInput.Number
            label="Temperature High Threshold"
            display="compact"
            value={temperatureHighThreshold}
            helpText="The default temperature threshold is a minimum of 50 and a maximum of 80 degrees. You can override as needed."
            required
          />
        </div>
      </>
    );
  };

  const getRouterFormContent = () => {
    return (
      <div>
        <FormInput.Number
          label="Connected Threshold"
          display="compact"
          value={connectedThreshold}
          helpText="Our default setting is to allow 3x the number of
                    devices per person that the property sleeps.  We do not
                    recommend changing these settings unless there is an excess
                    of alerts that you feel is unwarranted for the property and
                    the levels need to increase."
          required
        />
      </div>
    );
  };

  const getFormContent = () => {
    return (
      <form>
        {props.device.DeviceType === RegisteredDeviceType.Sensor &&
          getSensorFormContent()}
        {props.device.DeviceType === RegisteredDeviceType.Router &&
          getRouterFormContent()}
      </form>
    );
  };

  return (
    <div className="component-edit-device">
      <DeviceDetail device={props.device} title={getTitle()}>
        {getFormContent()}
      </DeviceDetail>
      <div className="edit-device-footer">
        <div>
          <BasicButton display="outline" onClick={handleCancel}>
            Cancel
          </BasicButton>
          {isLoading ? (
            <Image.Spinner className="spinner" width={36} height={36} />
          ) : (
            <BasicButton
              display="fill"
              onClick={handleSubmit}
              disabled={!isFormValid}
              style={{ float: "right" }}
            >
              Save
            </BasicButton>
          )}
        </div>
        <div className="error-message">{errorMessage}</div>
      </div>
    </div>
  );
};

export default EditDevice;
