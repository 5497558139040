import React, { FC, useEffect, useState } from "react";
import StepFooter from "../../../StepFooter/StepFooter";
import {
  TkLockInfoLocationPng,
  TkMasterCodePng,
} from "../../../../../../../assets";
import { useKeyListener } from "../../../../../../../hooks/KeyListeners";

interface MasterCodeProps {
  value: string;
  onSubmit: (value: string) => void;
  onPrevious: () => void;
}

const MasterCode: FC<MasterCodeProps> = ({
  value: initialValue,
  onSubmit,
  onPrevious,
}) => {
  const [value, setValue] = useState<string>(initialValue);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    setIsValid(/^[0-9]{6}$/.test(value));
  }, [value]);

  const handleInput = (e: any) => {
    // strip non-numeric chars from input & limit length to 5
    const value: string =
      e.target.value?.trim()?.replace(/[^\d]/, "")?.substring(0, 6) ?? "";

    // set state & update ui w/out altered value
    setValue(value);
    e.target.value = value;
  };

  const handleSubmit = () => {
    if (value && isValid) {
      onSubmit(value);
    }
  };
  useKeyListener("Enter", handleSubmit);

  const handlePrevious = () => {
    onPrevious();
  };

  return (
    <div className="step-scroll-container">
      <div className="step-header">
        <div className="title">Enter master code</div>
      </div>
      <div className="step-body">
        <div>
          Next, you'll need to locate the six-digit private master code (PMC).
          Enter the six-digit code below
        </div>
        <div>
          <input
            id="inp-master-code"
            type="text"
            name="master-code"
            placeholder="Private master code (PMC)"
            value={value}
            onChange={handleInput}
            autoFocus
          ></input>
        </div>
        <div>
          <img src={TkMasterCodePng} alt="code location" />
          <img src={TkLockInfoLocationPng} alt="sticker location" />
          <i>
            The private master code is located on a sticker inside the back of
            the lock
          </i>
        </div>
      </div>
      <StepFooter
        onSubmit={handleSubmit}
        onPrevious={handlePrevious}
        disabled={!isValid}
      />
    </div>
  );
};

export default MasterCode;
