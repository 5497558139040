export interface LockInfo {
  site_address: string;
  lock_name: string;
  installed_on: string;
  timezone: string;
  timezone_area: string;
  contact: string;
  lock_number: string;
  private_master_code: string;
  base_date_time: Date;
  newLock?: boolean;
  propertyId: any;
  lock_model: string;
  operator: string;
}

export interface RegisteredLock {
  lockId: number;
  error?: string;
}

export interface LockSetupResult {
  lockId: number;
  guestCode: string;
  ownerCode: string;
  oneTimeCode: string;
  baseTime: Date;
}

export interface CodeGenRequest {
  lockId: number;
  startDate: Date;
  endDate: Date;
  userType: CodeType;
  nameForCode: string;
}

export interface CodeGenResult {
  code?: string;
  error?: string;
}

export enum CodeType {
  "Owner" = "Personnel",
  "Guest" = "Guest",
  "OneTime" = "Facility",
}
