import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { Image } from "@vacasa/react-components-lib";
import { DeviceTypeEnum, isLockType } from "../../../models/DeviceTypeEnum";
import {
  RegisteredDevice,
  RegisteredDeviceType,
} from "../../../models/SmartDeviceApi";
import {
  BackendResource,
  Compatibility,
  Device,
} from "../../../models/SmartHomeApi";
import { StoreData } from "../../../store";
import CompatibilityRow from "./CompatibilityRow/CompatibilityRow";

import "./DeviceCompatibility.scss";
import { DeviceTypeStore } from "../../../store/device-type";

interface DeviceCompatibilityProps {
  locks?: BackendResource<Device>[];
  sensors?: RegisteredDevice[];
}

const LOCK_REASONS = [
  "Building/HOA does not allow",
  "Lock not compatible with door",
  "Is part of a super unit setup",
  "Owner does not want installed",
];
const NOISE_REASONS = [
  "Building/HOA Does not allow",
  "No WIFI to connect noise monitor to",
  "Already has NoiseAware setup",
  "Is part of a super unit setup",
  "Owner does not want installed",
];
const ROUTER_REASONS = [
  "Building/HOA provided WIFI installed already",
  "Internet Setup Not Compatible",
  "Is part of a super unit setup",
  "No WIFI installed at property",
  "Owner does not want installed",
  "Shares Wifi with the property next door",
];

const DeviceCompatibility: FC<DeviceCompatibilityProps> = (props) => {
  const { sensors, locks } = props;
  const [isReady, setIsReady] = useState(false);

  const [searchParams] = useSearchParams();
  const [operatorId, setOperatorId] = useState<string>();

  const propertyId = useSelector(
    (state: StoreData) => state.compatibility.propertyId,
  );
  const isLoading = useSelector(
    (state: StoreData) =>
      state.compatibility.isLoading || state.deviceType.isLoading,
  );
  const data = useSelector((state: StoreData) => state.compatibility.data);
  const error = useSelector(
    (state: StoreData) => state.compatibility.error || state.deviceType.error,
  );

  const noiseSensorType = DeviceTypeStore.selectors.useNoiseSensorTypeId();
  const wifiRouterType = DeviceTypeStore.selectors.useWifiRouterTypeId();

  const [lock, setLock] = useState<BackendResource<Compatibility>>();
  const [hasLock, setHasLock] = useState(false);

  const [noiseSensor, setNoiseSensor] =
    useState<BackendResource<Compatibility>>();
  const [hasNoiseSensor, setHasNoiseSensor] = useState(false);

  const [router, setRouter] = useState<BackendResource<Compatibility>>();
  const [hasRouter, setHasRouter] = useState(false);

  // Finds incompatible devices and their reasons
  useEffect(() => {
    setLock(undefined);
    setNoiseSensor(undefined);
    setRouter(undefined);

    data?.forEach((datum) => {
      switch (datum.attributes.device_type) {
        case DeviceTypeEnum.Lock:
          setLock(datum);
          break;
        case noiseSensorType:
          setNoiseSensor(datum);
          break;
        case wifiRouterType:
          setRouter(datum);
          break;
      }
    });
    setIsReady(true);
  }, [data, wifiRouterType, noiseSensorType]);

  // Determines whether noise sensors and routers exist
  useEffect(() => {
    setHasNoiseSensor(false);
    setHasRouter(false);
    sensors?.forEach((sensor) => {
      if (sensor.DeviceType === RegisteredDeviceType.Sensor)
        setHasNoiseSensor(true);
      if (sensor.DeviceType === RegisteredDeviceType.Router) setHasRouter(true);
    });
  }, [sensors]);

  // Determines whether active locks exist
  useEffect(() => {
    setHasLock(false);
    if (locks) {
      for (let i = 0; i < (locks?.length ?? 0); i++) {
        if (
          locks[i].attributes.active &&
          isLockType(locks[i].attributes.device_type ?? 0)
        ) {
          setHasLock(true);
          break;
        }
      }
    }
  }, [locks]);

  // parse query params
  useEffect(() => {
    const operator = searchParams.get("operator");
    setOperatorId(operator ?? undefined);
  }, [searchParams]);

  const getMainData = () => {
    const className = "compatibility-data";
    if (!isReady || isLoading) {
      return (
        <div className={className}>
          <Image.Spinner height={36} width={36} />
        </div>
      );
    } else if (props.locks !== undefined && data) {
      return (
        <div className={className}>
          <CompatibilityRow
            title="Smart Lock"
            compatibility={lock}
            type={DeviceTypeEnum.Lock}
            propertyId={propertyId!}
            disabled={hasLock && !lock}
            options={LOCK_REASONS}
            operator={operatorId}
          />
        </div>
      );
    } else if (props.sensors !== undefined && data) {
      return (
        <div className={className}>
          <CompatibilityRow
            title="Noise Monitor"
            compatibility={noiseSensor}
            type={noiseSensorType as DeviceTypeEnum}
            propertyId={propertyId!}
            disabled={hasNoiseSensor && !noiseSensor}
            options={NOISE_REASONS}
            operator={operatorId}
          />
          <CompatibilityRow
            title="Wifi Router"
            compatibility={router}
            type={wifiRouterType as DeviceTypeEnum}
            propertyId={propertyId!}
            disabled={hasRouter && !router}
            options={ROUTER_REASONS}
            operator={operatorId}
          />
        </div>
      );
    }
  };

  return (
    <div className="component-device-compatibility">
      <div className="compatibility-header">
        <div className="col">Device</div>
        <div className="col">Compatible with Unit?</div>
        <div className="col">Note</div>
        <div className="col"></div>
      </div>
      {error ? (
        <div className="compatibility-error">{error}</div>
      ) : (
        getMainData()
      )}
    </div>
  );
};

export default DeviceCompatibility;
