import jwtDecode from "jwt-decode";

export enum SCOPE {
  UNITS_WRITE = "units:write",
  UNITS_READ = "units:read",
  RESERVAITIONS_READ = "reservations:read",
  RESERVAITIONS_WRITE = "reservations:write",
  UNIT_TEST = "unit test",
  SMARTHOME_WRITE = "smart-home:write",
  SMARTHOME_REPORTS = "smart-home:reports",
}

class AuthService {
  static get accessToken() {
    return localStorage.getItem("accessToken");
  }
  static get idToken() {
    return localStorage.getItem("idToken");
  }
  static get userEmail() {
    const idToken = AuthService.idToken;
    if (idToken) return jwtDecode<any>(idToken).email;
    return undefined;
  }
  static getScopes(): SCOPE[] | null {
    const token = AuthService.accessToken;
    if (!token) return null;
    return jwtDecode<any>(token).scopes as SCOPE[];
  }

  //just only has to have one of the requiredScope
  static hasScope(requiredScope: SCOPE | SCOPE[]) {
    const availableScopes = AuthService.getScopes();
    if (!availableScopes) return false;
    const scopes = Array.isArray(requiredScope)
      ? requiredScope
      : [requiredScope];
    return scopes.some((s) => availableScopes.includes(s));
  }
}

export default AuthService;
