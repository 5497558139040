import React, { FC } from "react";

import "./Button.scss";

export interface BasicButtonProps {
  id?: string;
  type?: "button" | "reset" | "submit";
  disabled?: boolean;
  display?: "fill" | "basic" | "outline" | "alt" | "warning";
  size?: "regular" | "mini";
  hidden?: boolean;
  onClick?: (e: MouseEvent) => void;
  children?: React.ReactNode;
  style?: any;
}

const BasicButton: FC<BasicButtonProps> = (props) => {
  const displayMode = props.display ?? "basic";
  const displaySize = props.size ?? "regular";
  const attributes = {
    id: props.id,
    type: props.type,
    disabled: props.disabled,
    hidden: props.hidden,
    className: `btn ${displayMode} ${displaySize}`,
    onClick: (e: any) => props.onClick?.(e),
    style: props.style,
  };

  return <button {...attributes}>{props.children}</button>;
};

export default BasicButton;
