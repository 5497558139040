import React, { FC, useEffect, useState } from "react";
import { Outlet, useParams, useSearchParams } from "react-router-dom";
import ConnectService from "../../services/connect.service";
import NavBar, { NavItem } from "./NavBar/NavBar";
import { useDispatch, useSelector } from "react-redux";
import { StoreData } from "../../store";

import "./DataResolver.scss";

import SpinnerOverlay from "../../elements/Spinner/SpinnerOverlay";
import SmartHomeService from "../../services/smarthome.service";
import { PropertyStore } from "../../store/property";
import { FeatureFlagStore } from "../../store/feature-flags";
import { DeviceCompatibilityStore } from "../../store/device-compatibility";
import { DeviceTypeStore } from "../../store/device-type";
import { canView as canViewReports } from "../Reporting/ReportAuth";

interface DataResolverProps {}

const NAV_ITEM: Record<string, NavItem> = {
  devices: { display: "Home Access", link: "devices" },
  deviceMonitoring: { display: "Home Monitoring", link: "device-monitoring" },
  reporting: { display: "Reporting", link: "reporting" },
};

const DataResolver: FC<DataResolverProps> = () => {
  const [viewReports, setViewReports] = useState<boolean>(false);

  const NAV_ITEMS: NavItem[] = [NAV_ITEM.devices, NAV_ITEM.deviceMonitoring];
  if (viewReports) NAV_ITEMS.push(NAV_ITEM.reporting);

  const dispatch = useDispatch();
  const isLoadingProperty = PropertyStore.selectors.usePropertyLoading();
  const property = PropertyStore.selectors.usePropertyData();
  const errorMessage = PropertyStore.selectors.usePropertyError();

  const isLoadingCompatibility = useSelector(
    (state: StoreData) => state.compatibility.isLoading,
  );
  const isLoadingDeviceTypes = useSelector(
    (state: StoreData) => state.deviceType.isLoading,
  );

  const { propertyId } = useParams();
  const [searchParams] = useSearchParams();

  // check if property exists
  useEffect(() => {
    setViewReports(canViewReports());
    const service = new ConnectService();
    if (propertyId) {
      dispatch(PropertyStore.actions.loadProperty());
      service
        .getProperty(propertyId)
        .then((data) => {
          if (data) dispatch(PropertyStore.actions.setProperty(data));
          else
            dispatch(
              PropertyStore.actions.setError("Unable to verify property"),
            );
        })
        .catch((err) =>
          dispatch(
            PropertyStore.actions.setError(
              err.message || "Unable to verify property",
            ),
          ),
        );
    }
  }, [propertyId, dispatch]);

  // sets feature flags
  useEffect(() => {
    const features = searchParams.get("features");
    if (features) dispatch(FeatureFlagStore.actions.setAllFeatures(features));
  }, [searchParams, dispatch]);

  // Loads list of device types
  useEffect(() => {
    dispatch(DeviceTypeStore.actions.loadData());
    const smartHomeService = new SmartHomeService();
    smartHomeService
      .getDeviceTypes(200, 0, true)
      .then((response) =>
        dispatch(DeviceTypeStore.actions.setData(response.data)),
      )
      .catch((err) => dispatch(DeviceTypeStore.actions.setError(err.message)));
  }, [dispatch]);

  // loads the property's device compatibility
  useEffect(() => {
    if (!propertyId) return;

    dispatch(DeviceCompatibilityStore.actions.loadData(propertyId));
    const smartHomeService = new SmartHomeService();
    smartHomeService
      .getCompatibility(propertyId)
      .then((response) =>
        dispatch(DeviceCompatibilityStore.actions.setData(response.data)),
      )
      .catch((err) =>
        dispatch(DeviceCompatibilityStore.actions.setError(err.message)),
      );
  }, [propertyId, dispatch]);

  if (!isLoadingProperty && (!property || !propertyId)) {
    return (
      <div style={{ textAlign: "center" }}>
        <h3>Unit Not Found</h3>
        <p className="error-message">{errorMessage}</p>
      </div>
    );
  }

  return (
    <div className="DataResolverContainer">
      <div className="DataResolverHeader">
        <NavBar navItems={NAV_ITEMS}></NavBar>
      </div>

      <div className="DataResolverContent">
        {isLoadingProperty || isLoadingCompatibility || isLoadingDeviceTypes ? (
          <SpinnerOverlay fullScreen={true} />
        ) : (
          <Outlet />
        )}
      </div>
    </div>
  );
};

export default DataResolver;
