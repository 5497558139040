import React, { FC } from "react";
import { Image } from "@vacasa/react-components-lib";

import AddButton from "../../../elements/Button/AddButton";

import "./AlertSubscribers.scss";
import { AlertSubscriber } from "../../../models/SmartDeviceApi";
import Subscriber from "./Subscriber/Subscriber";

interface AlertSubscribersProps {
  isLoading: boolean;
  data?: AlertSubscriber[];
  error?: string;
  onAddSubscriber?: () => void;
  onEditSubscriber?: (subscriber: AlertSubscriber) => void;
  onDeleteSubscriber?: (subscriber: AlertSubscriber) => void;
}

const AlertSubscribers: FC<AlertSubscribersProps> = (props) => {
  const handleAddClick = () => {
    props.onAddSubscriber?.();
  };

  const handleEdit = (subscriber: AlertSubscriber) => {
    props.onEditSubscriber?.(subscriber);
  };

  const handleDelete = (subscriber: AlertSubscriber) => {
    props.onDeleteSubscriber?.(subscriber);
  };

  const getSubscriberList = () => {
    if (props.isLoading) {
      return (
        <div>
          <div>loading...</div>
          <Image.Spinner height={36} width={36} />
        </div>
      );
    } else if (props.error) {
      return <div>{props.error}</div>;
    } else if (!props.data?.length) return <div>No data</div>;

    return props.data.map((sub) => {
      return (
        <Subscriber
          subscriber={sub}
          key={sub.Login.Id}
          onEdit={handleEdit}
          onDelete={handleDelete}
        />
      );
    });
  };

  return (
    <div className="component-alert-subscribers">
      <div className="subscriber-detail">
        <div className="subscriber-table">{getSubscriberList()}</div>
      </div>
      <div className="footer">
        <AddButton
          id="btn-add-subscriber"
          display="outline"
          onClick={handleAddClick}
        >
          Add New Subscriber
        </AddButton>
      </div>
    </div>
  );
};

export default AlertSubscribers;
