import React, { FC, useEffect, useState } from "react";
import { Icon } from "@vacasa/react-components-lib";
import SmartDeviceService from "../../services/smart-device.service";
import {
  AlertSubscriber,
  ReadingAlert,
  RegisteredDevice,
  RegisteredDeviceType,
} from "../../models/SmartDeviceApi";
import { useAsyncData } from "../../hooks/AsyncData";

import DeviceCharts, { SensorItem } from "./Chart/DeviceCharts";
import MonitoringDevices from "./MonitoringDevices/MonitoringDevices";
import MonitoringDeviceAlerts from "./MonitoringDeviceAlerts/MonitoringDeviceAlerts";
import AlertSubscribers from "./AlertSubscribers/AlertSubscribers";
import AddButton from "../../elements/Button/AddButton";
import Card from "./Card";
import AddMonitoringDevice from "./AddMonitoringDevice/AddMonitoringDevice";
import AddAlertSubscriber from "./AddAlertSubscriber/AddAlertSubscriber";
import RemoveAlertSubscriber from "./RemoveAlertSubscriber/RemoveAlertSubscriber";
import EditAlertSubscriber from "./EditAlertSubscriber/EditAlertSubscriber";

import "./DeviceMonitoring.scss";

import DataPlaceholder from "../../elements/Templates/DataPlaceholder/DataPlaceholder";
import AnchoredDialog, {
  AnchorPoint,
} from "../../elements/Dialog/AnchoredDialog";
import SpinnerOverlay from "../../elements/Spinner/SpinnerOverlay";
import DeviceCompatibility from "../../elements/Templates/DeviceCompatibility/DeviceCompatibility";
import {
  useNoiseSensorCompatibility,
  useRouterCompatibility,
} from "../../store/device-compatibility/selector";
import { PropertyStore } from "../../store/property";
import UnauthorizedMessage from "../../elements/Templates/UnauthorizedMessage/UnauthorizedMessage";
import { AmenityStore } from "../../store/amenities";
import ConnectService from "../../services/connect.service";
import { useDispatch } from "react-redux";
import {
  AmenityType,
  CreateAmenityBody,
  UpdateAmenityBody,
} from "../../models/ConnectApi";
import { HttpResponseError } from "../../core/errors";

interface DeviceMonitoringProps {}

const DeviceMonitoring: FC<DeviceMonitoringProps> = (_props) => {
  const dispatch = useDispatch();

  const property = PropertyStore.selectors.usePropertyData()!;
  const propertyId = property?.id;
  const timezone = property?.attributes.timezone;

  const [isAuthorized, setIsAuthorized] = useState(true);

  const areAmenitiesLoaded = AmenityStore.selectors.useIsLoaded();
  const noiseSensorAmenity = AmenityStore.selectors.useNoiseSensorAmenity();

  const canAddNoiseSensor = useNoiseSensorCompatibility();
  const canAddRouter = useRouterCompatibility();
  const [compatibleDevices, setCompatibleDevices] = useState<
    RegisteredDeviceType[]
  >([]);

  const [devices, setDevices] = useAsyncData<RegisteredDevice[]>();
  const [refreshDevices, setRefreshDevices] = useState(new Date());

  const [alerts, setAlerts] = useAsyncData<ReadingAlert[]>();
  const [refreshAlerts, setRefreshAlerts] = useState(new Date());

  const [subscribers, setSubscribers] = useAsyncData<AlertSubscriber[]>();
  const [refreshSubscribers, setRefreshSubscribers] = useState(new Date());

  const [sensorsForChart, setSensorsForChart] = useState<SensorItem[]>();
  const [routersForChart, setRoutersForChart] = useState<SensorItem[]>();

  const [isAddingDevice, setIsAddingDevice] = useState(false);
  const [isAddingSubscriber, setIsAddingSubscriber] = useState(false);
  const [editingSubscriber, setEditingSubscriber] = useState<AlertSubscriber>();
  const [removingSubscriber, setRemovingSubscriber] =
    useState<AlertSubscriber>();

  const [addDeviceAnchor, setAddDeviceAnchor] = useState<AnchorPoint>("CENTER");
  const [addDeviceOffset, setAddDeviceOffset] = useState<[number, number]>([
    0, 0,
  ]);

  // Load all registered devices
  useEffect(() => {
    if (!property) return;

    const service = new SmartDeviceService();
    const abortController = new AbortController();

    setDevices.setIsLoading(true);
    setDevices.setError(undefined);

    service
      .getDevicesByPropertyId(property.id, abortController.signal)
      .then((results) => setDevices.setData(results))
      .catch((err) => {
        setDevices.setError(err.message);
        if (err instanceof HttpResponseError && err.status === 403) {
          setIsAuthorized(false);
        }
      })
      .finally(() => setDevices.setIsLoading(false));

    //return () => abortController.abort();
  }, [property, setDevices, refreshDevices]);

  // Loads recent alerts
  useEffect(() => {
    if (!property) return;

    const lookbackHours = 4;
    const from = new Date();
    from.setHours(from.getHours() - lookbackHours);

    const service = new SmartDeviceService();
    const abortController = new AbortController();

    setAlerts.setIsLoading(true);
    setAlerts.setError(undefined);

    service
      .getAlerts(
        property.id,
        undefined,
        from,
        undefined,
        abortController.signal,
      )
      .then((data) => setAlerts.setData(data.data.slice(-3)))
      .catch((err) => {
        setAlerts.setError(err.message);
        if (err instanceof HttpResponseError && err.status === 403) {
          setIsAuthorized(false);
        }
      })
      .finally(() => setAlerts.setIsLoading(false));

    //return () => abortController.abort();
  }, [property, setAlerts, refreshAlerts]);

  // Loads all subscribers
  useEffect(() => {
    if (!property) return;

    const service = new SmartDeviceService();
    const abortController = new AbortController();

    setSubscribers.setIsLoading(true);
    setSubscribers.setError(undefined);

    service
      .getAlertSubscribersByDeviceGroup(property.id, abortController.signal)
      .then((noiseAlerts) => setSubscribers.setData(noiseAlerts))
      .catch((err) => {
        setSubscribers.setError(err.message);
        if (err instanceof HttpResponseError && err.status === 403) {
          setIsAuthorized(false);
        }
      })
      .finally(() => setSubscribers.setIsLoading(false));

    //return () => abortController.abort();
  }, [property, setSubscribers, refreshSubscribers]);

  // Loads unit amenities
  useEffect(() => {
    if (!propertyId || areAmenitiesLoaded) return;
    dispatch(AmenityStore.actions.loadAmenities());

    const service = new ConnectService();
    service
      .getUnitAmenities(propertyId)
      .then((amenities) =>
        dispatch(AmenityStore.actions.setAmenities(amenities)),
      )
      .catch((err) => dispatch(AmenityStore.actions.setError(err.message)));
  }, [propertyId, areAmenitiesLoaded, dispatch]);

  // Set sensorsForChart whenever new sensors are found
  useEffect(() => {
    const sensors =
      devices.data?.filter(
        (x) => x.DeviceType === RegisteredDeviceType.Sensor,
      ) ?? [];
    if (sensors.length !== sensorsForChart?.length) {
      setSensorsForChart(
        sensors.map((x) => {
          return {
            MacAddress: x.MacAddress,
            DeviceName: x.DeviceName || "unknown",
          };
        }),
      );
    }
  }, [devices.data, sensorsForChart]);

  // Set routersForChart whenever new routers are found
  useEffect(() => {
    const routers =
      devices.data?.filter(
        (x) => x.DeviceType === RegisteredDeviceType.Router,
      ) ?? [];
    if (routers.length !== routersForChart?.length) {
      setRoutersForChart(
        routers.map((x) => {
          return {
            MacAddress: x.MacAddress,
            DeviceName: x.DeviceName || "unknown",
          };
        }),
      );
    }
  }, [devices.data, routersForChart]);

  // Maintains updated list of compatible device types
  useEffect(() => {
    const allowedDevices: RegisteredDeviceType[] = [];
    if (canAddNoiseSensor) allowedDevices.push(RegisteredDeviceType.Sensor);
    if (canAddRouter) allowedDevices.push(RegisteredDeviceType.Router);

    setCompatibleDevices(allowedDevices);
  }, [canAddNoiseSensor, canAddRouter]);

  // Automatically adds or removes noise sensor amenity
  useEffect(() => {
    if (!areAmenitiesLoaded) return;
    const hasNoiseSensor =
      sensorsForChart !== undefined && sensorsForChart.length > 0;

    const service = new ConnectService();
    const payload: UpdateAmenityBody = {
      amenity_value: hasNoiseSensor ? 1 : 0,
      internal_notes: null,
      instructions: null,
      notes: null,
      provider_id: null,
    };
    if (hasNoiseSensor && noiseSensorAmenity === undefined) {
      // create amenity
      const amenity: CreateAmenityBody = {
        amenity_id: AmenityType.NoiseSensor,
        unit_id: +propertyId,
        ...payload,
      };
      service
        .createAmenity(amenity)
        .then((result) => dispatch(AmenityStore.actions.upsertAmenity(result)));
    } else if (noiseSensorAmenity) {
      // update amenity
      const requiresUpdate = noiseSensorAmenity.attributes.amenity_value
        ? !hasNoiseSensor
        : hasNoiseSensor;
      if (requiresUpdate) {
        service
          .updateAmenity(noiseSensorAmenity.id, payload)
          .then((result) =>
            dispatch(AmenityStore.actions.upsertAmenity(result)),
          );
      }
    }
  }, [
    areAmenitiesLoaded,
    noiseSensorAmenity,
    sensorsForChart,
    propertyId,
    dispatch,
  ]);

  // Refresh data
  const handleRefreshDevices = () => setRefreshDevices(new Date());
  const handleRefreshAlerts = () => setRefreshAlerts(new Date());
  const handleRefreshSubscribers = () => setRefreshSubscribers(new Date());

  // CREATE Device
  const handleAddDeviceClick = function (e: MouseEvent, source: number) {
    if (source === 1) {
      // display dialog where the upper right button is located
      setAddDeviceOffset([356, 29]);
      setAddDeviceAnchor("TOP");
    } else if (source === 0) {
      // display dialog wherever the button below list of devices is
      setAddDeviceOffset([-288, e.clientY - 150]);
      setAddDeviceAnchor("TOP");
    } else {
      // center dialog on screen
      setAddDeviceOffset([0, 0]);
      setAddDeviceAnchor("CENTER");
    }
    setIsAddingDevice(true);
  };
  const handleAddDeviceClose = function () {
    setIsAddingDevice(false);
  };
  const handleAddDevice = function (newDevice: RegisteredDevice) {
    if (devices.data) setDevices.setData([...devices.data, newDevice]);
    else setDevices.setData([newDevice]);
    handleAddDeviceClose();
  };

  // EDIT Device
  const handleEditDevice = function (device: RegisteredDevice) {
    const index = devices.data?.findIndex(
      (x) =>
        x.MacAddress === device.MacAddress &&
        device.DeviceType === x.DeviceType,
    );
    if (index !== undefined && index >= 0) {
      const dataCopy = [...devices.data!];
      dataCopy[index] = device;
      setDevices.setData(dataCopy);
    }
  };

  // UNREGISTER Device
  const handleRemoveDevice = function (device: RegisteredDevice) {
    const index = devices.data?.findIndex(
      (x) =>
        x.MacAddress === device.MacAddress &&
        device.DeviceType === x.DeviceType,
    );
    if (index !== undefined && index >= 0) {
      const dataCopy = [...devices.data!];
      dataCopy.splice(index, 1);
      setDevices.setData(dataCopy);
    }
  };

  // CREATE Subscriber
  const handleAddSubscriberClick = function () {
    setIsAddingSubscriber(true);
  };
  const handleAddSubscriberClose = function () {
    setIsAddingSubscriber(false);
  };
  const handleAddSubscriber = function (subscriber: AlertSubscriber) {
    if (subscribers.data)
      setSubscribers.setData([...subscribers.data, subscriber]);
    else setSubscribers.setData([subscriber]);
    handleAddSubscriberClose();
  };

  // EDIT Subscriber
  const handleEditSubscriberClick = function (subscriber: AlertSubscriber) {
    setEditingSubscriber(subscriber);
  };
  const handleEditSubscriberClose = function () {
    setEditingSubscriber(undefined);
  };
  const handleEditSubscriber = function (subscriber: AlertSubscriber) {
    if (editingSubscriber) {
      subscriber.Login = editingSubscriber.Login;
      const index = subscribers.data?.findIndex(
        (x) => x.Login.Id === editingSubscriber.Login.Id,
      );
      if (index !== undefined && index >= 0) {
        const dataCopy = [...subscribers.data!];
        dataCopy[index] = subscriber;
        setSubscribers.setData(dataCopy);
        setEditingSubscriber(undefined);
      }
    }
  };

  // DELETE Subscriber
  const handleDeleteSubscriberClick = function (subscriber: AlertSubscriber) {
    setRemovingSubscriber(subscriber);
  };
  const handleDeleteSubscriberClose = function () {
    setRemovingSubscriber(undefined);
  };
  const handleDeleteSubscriber = function () {
    if (removingSubscriber) {
      const index = subscribers.data?.findIndex(
        (x) => x.Login.Id === removingSubscriber.Login.Id,
      );
      if (index !== undefined && index >= 0) {
        const dataCopy = [...subscribers.data!];
        dataCopy.splice(index, 1);
        setSubscribers.setData(dataCopy);
        setRemovingSubscriber(undefined);
      }
    }
  };
  const getTimezone = () => {
    if (timezone === undefined) return "";
    return (
      <div className="timezoneDisplay">Times shown in {timezone} timezone</div>
    );
  };

  const getMainContent = () => {
    return (
      <div>
        {getTimezone()}
        <DeviceCharts
          sensors={sensorsForChart}
          routers={routersForChart}
          error={devices.error}
          timezone={timezone}
        />
        <div className="columns">
          <div className="column">
            <Card
              icon={<Icon.Radio />}
              title="Device Management"
              onRefresh={handleRefreshDevices}
            >
              <MonitoringDevices
                {...devices}
                addDisabled={!canAddNoiseSensor && !canAddRouter}
                onAddDevice={(e) => handleAddDeviceClick(e, 0)}
                onEditDevice={handleEditDevice}
                onUnregisterDevice={handleRemoveDevice}
                timezone={timezone}
              />
            </Card>
          </div>
          <div className="column">
            <Card
              icon={<Icon.Bell />}
              title="Device Alerts"
              onRefresh={handleRefreshAlerts}
            >
              <MonitoringDeviceAlerts {...alerts} />
            </Card>
          </div>
        </div>
        <Card
          icon={<Icon.UserCheck />}
          title="Alert Subscribers"
          onRefresh={handleRefreshSubscribers}
        >
          <AlertSubscribers
            {...subscribers}
            onAddSubscriber={handleAddSubscriberClick}
            onEditSubscriber={handleEditSubscriberClick}
            onDeleteSubscriber={handleDeleteSubscriberClick}
          />
        </Card>
        <DeviceCompatibility sensors={devices.data} />
      </div>
    );
  };

  if (devices.isLoading) return <SpinnerOverlay fullScreen={true} />;
  else if (!isAuthorized)
    return <UnauthorizedMessage tabName="Home Monitoring" />;
  return (
    <div className="component-sound-monitoring">
      <div className="add-device-row">
        <div className="action">
          <AddButton
            id="btn-add-sound-device"
            display="alt"
            onClick={(e) => handleAddDeviceClick(e, 1)}
            hidden={
              !devices.data?.length ||
              isAddingDevice ||
              devices.isLoading ||
              (!canAddNoiseSensor && !canAddRouter)
            }
          >
            Add Monitoring Device
          </AddButton>
        </div>
      </div>
      {!devices.data?.length ? (
        <>
          <DeviceCompatibility sensors={[]} />
          <DataPlaceholder
            title="No Monitoring Devices Added Yet"
            message="Tap the “add a Device” button below to add the first Smart Home device to this unit"
            btnText="Add a Device"
            btnDisabled={!canAddNoiseSensor && !canAddRouter}
            btnHoverText={
              !canAddNoiseSensor && !canAddRouter
                ? "In order to add a device, you'll need to change compatible with unit to Yes."
                : undefined
            }
            onBtnClick={(e) => handleAddDeviceClick(e, 2)}
            errorMessage={devices.error}
          />
        </>
      ) : (
        getMainContent()
      )}
      {isAddingDevice && (
        <AnchoredDialog
          onClose={handleAddDeviceClose}
          anchor={addDeviceAnchor}
          offset={addDeviceOffset}
        >
          <AddMonitoringDevice
            compatibleDevices={compatibleDevices}
            onSubmit={handleAddDevice}
            onCancel={handleAddDeviceClose}
          />
        </AnchoredDialog>
      )}
      {isAddingSubscriber && (
        <AnchoredDialog onClose={handleAddSubscriberClose}>
          <AddAlertSubscriber
            existingSubscribers={subscribers.data}
            onSubmit={handleAddSubscriber}
          />
        </AnchoredDialog>
      )}
      {editingSubscriber && (
        <AnchoredDialog onClose={handleEditSubscriberClose}>
          <EditAlertSubscriber
            subscriber={editingSubscriber}
            onSubmit={handleEditSubscriber}
            onCancel={handleEditSubscriberClose}
          />
        </AnchoredDialog>
      )}
      {removingSubscriber && (
        <AnchoredDialog onClose={handleDeleteSubscriberClose}>
          <RemoveAlertSubscriber
            subscriber={removingSubscriber}
            onSubmit={handleDeleteSubscriber}
            onCancel={handleDeleteSubscriberClose}
          />
        </AnchoredDialog>
      )}
    </div>
  );
};

export default DeviceMonitoring;
