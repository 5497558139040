import React, { FC, useEffect, useRef, useState } from "react";
import { useEscapeKeyListener } from "../../hooks/KeyListeners";
import useWindowDimensions from "../../hooks/Window";

import "./Dialog.scss";

export type AnchorPoint =
  | "TOP"
  | "TOP-LEFT"
  | "LEFT"
  | "BOTTOM-LEFT"
  | "BOTTOM"
  | "BOTTOM-RIGHT"
  | "RIGHT"
  | "TOP-RIGHT"
  | "CENTER";
interface AnchoredDialogProps {
  anchor?: AnchorPoint;
  offset?: [number, number];
  onClose?: () => void;
  children?: React.ReactNode;
}

/**
 * Dialog that knows how to position itself on the screen
 *
 * @param props
 * @returns
 */
const AnchoredDialog: FC<AnchoredDialogProps> = (props) => {
  const anchorClass = "anchor-" + (props.anchor?.toLowerCase() ?? "center");
  const [isReady, setIsReady] = useState(false);

  const window = useWindowDimensions();
  const dialogRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  const [resizedAt, setResizedAt] = useState(new Date());
  const [positionX, setPositionX] = useState(0);
  const [positionY, setPositionY] = useState(0);

  const handleClose = () => {
    setIsReady(false);
    setTimeout(() => {
      props.onClose?.();
    }, 200);
  };
  useEscapeKeyListener(handleClose);

  useEffect(() => {
    document.body.classList.add("no-scroll");

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, []);

  // Based on size of self and window size move popup to be fully visible
  useEffect(() => {
    // Get position of top left pixel before offset.
    const element = dialogRef.current;
    if (!element) return;

    let currentX = 0;
    let currentY = 0;
    switch (props.anchor) {
      case "TOP":
        currentX = window.width / 2 - element.clientWidth / 2;
        break;
      case "TOP-RIGHT":
        currentX = window.width - element.clientWidth;
        break;
      case "LEFT":
        currentY = window.height / 2 - element.clientHeight / 2;
        break;
      case "CENTER":
        currentX = window.width / 2 - element.clientWidth / 2;
        currentY = window.height / 2 - element.clientHeight / 2;
        break;
      case "RIGHT":
        currentX = window.width - element.clientWidth;
        currentY = window.height / 2 - element.clientHeight / 2;
        break;
      case "BOTTOM-LEFT":
        currentY = window.height - element.clientHeight;
        break;
      case "BOTTOM":
        currentX = window.width / 2 - element.clientWidth / 2;
        currentY = window.height - element.clientHeight;
        break;
      case "BOTTOM-RIGHT":
        currentX = window.width - element.clientWidth;
        currentY = window.height - element.clientHeight;
        break;
    }

    // Calculate position after offset
    let offsetX = props.offset?.[0] ?? 0;
    let offsetY = props.offset?.[1] ?? 0;

    const startX = currentX + offsetX;
    const endX = startX + element.clientWidth;
    const startY = currentY + offsetY;
    const endY = startY + element.clientHeight;

    if (startX < 0) offsetX -= startX;
    else if (endX > window.width) offsetX -= endX - window.width;

    if (startY < 0) offsetY -= startY;
    else if (endY > window.height) offsetY -= endY - window.height;

    setPositionX(offsetX);
    setPositionY(offsetY);
    setIsReady(true);
  }, [dialogRef, window, resizedAt, props.offset, props.anchor]);

  const handleChildResize = () => {
    // Let child component re-render before calculating position
    setTimeout(() => {
      setResizedAt(new Date());
    }, 0);
  };

  const customStyle = {
    marginLeft: positionX,
    marginTop: positionY,
    maxWidth: !isReady ? 0 : 1000,
    maxHeight: !isReady ? 0 : 1000,
  };

  return (
    <div className="component-anchor-dialog">
      <div
        className={`dialog-content-wrapper ${anchorClass}`}
        style={customStyle}
      >
        <div className="dialog-content-container" ref={dialogRef}>
          <div className="dialog-header">
            <div className="close" onClick={handleClose}>
              &times;
            </div>
          </div>
          <div className="dialog-content" onMouseUp={handleChildResize}>
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnchoredDialog;
