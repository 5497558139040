import { Icon } from "@vacasa/react-components-lib";
import React, { FC } from "react";

interface CardProps {
  icon: React.ReactNode;
  title: string;
  children?: React.ReactNode;
  onRefresh?: () => void;
}

const Card: FC<CardProps> = (props) => {
  const handleRefreshData = () => {
    props.onRefresh?.();
  };

  return (
    <div className="card">
      <div className="card-container">
        <div className="header">
          <div className="icon">{props.icon}</div>
          <div className="title">{props.title}</div>
          <div className="refresh-btn">
            <button onClick={handleRefreshData}>
              <Icon.RefreshCW className="icon-refresh" />
            </button>
          </div>
        </div>
        {props.children}
      </div>
    </div>
  );
};

export default Card;
