import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";

import { IReport } from "../../models/SmartHomeApi";
import useWindowDimensions from "../../hooks/Window";
import { MOBILE_SCREEN_MAX_WIDTH } from "../../core/constants";

import TicketRenderer from "./CellRenderers/TicketRenderer";
import { RowClassParams, RowStyle } from "ag-grid-community";
import UsableRenderer from "./CellRenderers/UsableRenderer";
import ButtonCellRenderer from "../HomeDeviceGrid/CellRenderers/ButtonCellRenderer";
import { Icon } from "@vacasa/react-components-lib";
import AnchoredDialog from "../../elements/Dialog/AnchoredDialog";
import DeviceDetailsDialog from "./DeviceDetailsDialog/DeviceDetailsDialog";

interface ReportingGridProps {
  //   reportType: string;
  reportData: IReport[];
}
const ReportTypeGrid: FC<ReportingGridProps> = ({ reportData }) => {
  const window = useWindowDimensions();
  const gridRef = useRef<any>();
  const [gridReadyAt, setGridReadyAt] = useState(new Date());
  const [rowDetails, setRowDetails] = useState<IReport>();

  // Adjust grid size based on window size
  useEffect(() => {
    gridRef?.current?.api?.sizeColumnsToFit();
    gridRef?.current?.api?.setDomLayout?.("autoHeight");
  }, [window, gridReadyAt]);

  // AG Grid settings
  const colDefaults = useMemo(() => {
    if (window.width <= MOBILE_SCREEN_MAX_WIDTH) {
      return {
        sortable: false,
        unSortIcon: false,
        suppressMovable: true,
      };
    } else {
      return {
        sortable: true,
        //sortingOrder: ["asc", "desc", null],
        unSortIcon: true,
      };
    }
  }, [window]);

  const showDeviceDetailsModal = useCallback((reportData: IReport) => {
    setRowDetails(reportData);
  }, []);

  const hideDeviceDetailsModal = function () {
    setRowDetails(undefined);
  };

  // AG Grid columns
  const columnsToDisplay = useMemo(() => {
    const detailColumn = {
      width: 5,
      sortable: false,
      cellRenderer: ButtonCellRenderer,
      cellRendererParams: {
        icon: <Icon.Plus width={18} height={18} style={{ top: 5 }} />,
        onClick: showDeviceDetailsModal,
      },
    };

    const ticketColumn = {
      headerName: "TICKET",
      field: "external_reference_id",
      cellRenderer: TicketRenderer,
    };

    const accessCodeColumn = {
      headerName: "ACCESS CODE",
      field: "access_code",
    };
    const createdByColumn = {
      headerName: "CREATED BY",
      field: "created_by_name",
    };
    const usableColumn = {
      headerName: "USABLE",
      cellRenderer: UsableRenderer,
      sortable: false,
    };
    const startColumn = { headerName: "START TIME", field: "start_time" };
    const endColumn = { headerName: "END TIME", field: "end_time" };

    const baseColumns = [
      detailColumn,
      ticketColumn,
      usableColumn,
      accessCodeColumn,
      createdByColumn,
      startColumn,
      endColumn,
    ];
    if (window.width <= MOBILE_SCREEN_MAX_WIDTH) return baseColumns; // return less columns for mobile display
    return [...baseColumns]; // return all columns
  }, [window, showDeviceDetailsModal]);

  const onGridReady = () => {
    setGridReadyAt(new Date());
  };

  const getRowStyle = (params: RowClassParams): RowStyle => {
    const styles = {} as RowStyle;
    if (params.data.lock_deleted) styles.background = "#808080";
    else if (params.data.isDeleted) styles.background = "#bac7ba";
    return styles;
  };

  return (
    <div className="report-grid">
      {/* <h2>{reportType}</h2> */}
      <div className="ag-theme-alpine" style={{ width: "100%" }}>
        <div style={{ height: "100%", width: "100%" }}>
          <AgGridReact
            getRowStyle={getRowStyle}
            rowHeight={70}
            rowData={reportData}
            columnDefs={columnsToDisplay}
            defaultColDef={colDefaults}
            onGridReady={onGridReady}
            ensureDomOrder={true}
            enableCellTextSelection={true}
            ref={gridRef}
          ></AgGridReact>
        </div>
      </div>
      {rowDetails && (
        <AnchoredDialog onClose={hideDeviceDetailsModal}>
          <DeviceDetailsDialog dataRow={rowDetails} />
        </AnchoredDialog>
      )}
    </div>
  );
};
export default ReportTypeGrid;
