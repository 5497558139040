import { Property } from "../../models/ConnectApi";
import { slice } from "./reducer";
import * as selectors from "./selector";

export interface PropertyState {
  isLoading: boolean;
  data?: Property;
  error?: string;
}

export const PropertyStore = {
  reducer: slice.reducer,
  actions: slice.actions,
  selectors,
};
