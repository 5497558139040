import React, { FC } from "react";
import StepFooter from "../../../StepFooter/StepFooter";

interface FactoryResetProps {
  onSubmit: () => void;
  onPrevious: () => void;
}

const FactoryReset: FC<FactoryResetProps> = ({ onSubmit, onPrevious }) => {
  return (
    <div>
      <div className="step-header">
        <div className="title">Factory Reset</div>
      </div>
      <div className="step-body">
        Before we start, let's perform a factory reset of the lock
        <ol>
          <li>Unplug the battery</li>
          <li>Hold down the Schlage key</li>
          <li>While the Schlage key is still pressed, plug in the battery</li>
        </ol>
        The Schlage button should blink green, the keypad should flash blue, and
        the Schlage button should blink green again.
      </div>
      <StepFooter onSubmit={onSubmit} onPrevious={onPrevious} />
    </div>
  );
};

export default FactoryReset;
