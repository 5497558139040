import { FC } from "react";
import { Icon, Image } from "@vacasa/react-components-lib";
import LineChart from "../../../elements/Chart/LineChart";
import { ChartDataSeries, SeriesOptions } from "../../../elements/Chart/types";
import BarChart from "../../../elements/Chart/BarChart";

export interface BasicChartProps {
  chartType: "line" | "bar";
  cardTitle: string;
  chartTitle?: string;
  data?: ChartDataSeries;
  seriesOptions?: SeriesOptions;
  loading?: boolean;
  error?: string;
  onRefreshClick?: () => void;
  timezone?: string;
}

const BasicChart: FC<BasicChartProps> = (props) => {
  const handleRefreshClick = () => {
    props.onRefreshClick?.();
  };

  const getChartContent = () => {
    if (props.error) {
      return (
        <div className="chart-placeholder">
          <div className="Status">{props.error}</div>
        </div>
      );
    } else if (props.loading || !props.data) {
      return (
        <div className="chart-placeholder">
          <div className="Status">
            <div>loading...</div>
            <Image.Spinner height={36} width={36} />
          </div>
        </div>
      );
    } else if (props.error) {
      return (
        <div className="chart-placeholder">
          <div className="Status">An error occurred</div>
        </div>
      );
    } else {
      const chartProps = {
        title: props.cardTitle,
        seriesOptions: props.seriesOptions,
        data: props.data,
        timezone: props.timezone,
      };

      if (props.chartType === "line")
        return (
          <div className="line-chart-wrapper">
            <LineChart {...chartProps} />
          </div>
        );
      if (props.chartType === "bar")
        return (
          <div className="bar-chart-wrapper">
            <BarChart {...chartProps} />
          </div>
        );
    }
  };

  return (
    <div className="card">
      <div className="current-sensor-activity">
        <div className="header">
          <div className="icon">
            <Icon.Activity />
          </div>
          <div className="title">{props.cardTitle}</div>
          <div className="refresh-btn">
            <button onClick={handleRefreshClick}>
              <Icon.RefreshCW className="icon-refresh"></Icon.RefreshCW>
            </button>
          </div>
        </div>
        <div className="chart-content">{getChartContent()}</div>
      </div>
    </div>
  );
};

export default BasicChart;
