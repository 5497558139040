import React, { FC, useEffect, useState } from "react";
import SelectList from "../../../../../../../elements/Form/SelectList";
import { useFormValue } from "../../../../../../../hooks/FormValue";
import StepFooter from "../../../StepFooter/StepFooter";

interface LockLocationProps {
  value: string;
  onSubmit: (type: string) => void;
  onPrevious: () => void;
}

const LockLocation: FC<LockLocationProps> = ({
  value,
  onSubmit,
  onPrevious,
}) => {
  const lockLocation = useFormValue<string>(value, false);
  const [isValid, setIsValid] = useState(false);
  const [lockLocationOptions] = useState([
    { displayText: "Front Door", value: "Front Door" },
    { displayText: "Back Door", value: "Back Door" },
    { displayText: "Inside Garage Door", value: "Inside Garage Door" },
  ]);

  useEffect(() => {
    setIsValid(!!lockLocation.value && !!lockLocation.value?.trim());
  }, [lockLocation.value]);

  const handlePrevious = () => {
    lockLocation.reset();
    onPrevious();
  };

  const handleSubmit = () => {
    if (lockLocation.value) onSubmit(lockLocation.value);
  };

  return (
    <div>
      <SelectList
        title="Lock Location"
        description="Enter the location where the lock was installed."
        value={lockLocation}
        options={lockLocationOptions}
        displayFilter={false}
      />
      <StepFooter
        onSubmit={handleSubmit}
        onPrevious={handlePrevious}
        disabled={!isValid}
      />
    </div>
  );
};

export default LockLocation;
