import AbstractService from "./abstract.service";
import { CONNECT_API } from "../core/constants";
import {
  Amenity,
  CreateAmenityBody,
  Property,
  UpdateAmenityBody,
} from "../models/ConnectApi";

class ConnectService extends AbstractService {
  constructor() {
    super(CONNECT_API);
  }

  /**
   * Gets the number of future reservations for a given property
   * @param unitId
   * @param signal
   * @returns
   */
  public async getReservationCount(
    unitId: string,
    signal?: AbortSignal,
  ): Promise<number> {
    const url = `/property/${unitId}/future-reservation-count`;

    const result = await this.get<{ data: number }>(url, { signal });
    return result?.data ?? 0;
  }

  /**
   * Gets a given property
   *
   * @param unitId
   * @returns
   */
  public async getProperty(unitId: string): Promise<Property | null> {
    const url = `/property/${unitId}`;

    const result = await this.get<{ data: Property }>(url);
    return result?.data ?? null;
  }

  public async getUnitAmenities(unitId: string): Promise<Amenity[]> {
    const url = `/unit-amenities/${unitId}`;

    const result = await this.get<{ data: Amenity[] }>(url);
    return result!.data;
  }

  public async createAmenity(amenity: CreateAmenityBody): Promise<Amenity> {
    const url = `/unit-amenities`;

    const result = await this.post<{ data: Amenity }>(url, amenity);
    return result.data;
  }

  public async updateAmenity(
    amenityId: string,
    amenity: UpdateAmenityBody,
  ): Promise<Amenity> {
    const url = `/unit-amenities/${amenityId}`;

    const result = await this.put<{ data: Amenity }>(url, amenity);
    return result.data;
  }
}

export default ConnectService;
