import React, { FC, useState } from "react";
import SelectList from "../../../../../elements/Form/SelectList";
import { parseResource } from "../../lib/parse-resource";
import {
  BackendResource,
  DeviceModel,
} from "../../../../../models/SmartHomeApi";
import { useFormValue } from "../../../../../hooks/FormValue";
import StepFooter from "../StepFooter/StepFooter";

interface SelectModelStepProps {
  deviceModels?: BackendResource<DeviceModel>[];
  onPrevious: () => void;
  onSubmit: (model: BackendResource<DeviceModel>) => void;
}

const SelectModelStep: FC<SelectModelStepProps> = ({
  deviceModels,
  onPrevious,
  onSubmit,
}) => {
  const deviceModelForm = useFormValue<number>(undefined, false);
  const [deviceModelOptions] = useState(deviceModels?.map(parseResource));

  const handlePrevious = () => {
    deviceModelForm.reset();
    onPrevious();
  };

  const handleSubmit = function () {
    if (deviceModelForm.isValid) {
      const model = deviceModels?.find((x) => x.id === deviceModelForm.value);
      if (model) {
        onSubmit(model);
      }
    }
  };

  return (
    <div className="select-list-wrapper">
      <SelectList
        title="New Smart Device"
        description="SELECT MODEL"
        value={deviceModelForm}
        options={deviceModelOptions ?? []}
        displayFilter={true}
        filterPlaceholder="search models"
      />
      <StepFooter
        onSubmit={handleSubmit}
        onPrevious={handlePrevious}
        disabled={!deviceModelForm.isValid}
      />
    </div>
  );
};

export default SelectModelStep;
