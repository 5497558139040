import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AmenityState } from ".";
import { Amenity } from "../../models/ConnectApi";

const initialState: AmenityState = {
  isLoading: false,
};

export const slice = createSlice({
  name: "amenity",
  initialState,
  reducers: {
    loadAmenities: (state) => {
      state.isLoading = true;
    },
    upsertAmenity: (state, action: PayloadAction<Amenity>) => {
      if (state.amenities) {
        const index = state.amenities.findIndex(
          (x) => x.id === action.payload.id,
        );
        if (index >= 0) state.amenities.splice(index, 1, action.payload);
        else state.amenities.push(action.payload);
      }
    },
    setAmenities: (state, action: PayloadAction<Amenity[]>) => {
      state.isLoading = false;
      state.amenities = action.payload;
      state.error = undefined;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});
