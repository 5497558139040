import { AxiosError, AxiosResponse } from "axios";
import { DEFAULT_HTTP_TIMEOUT } from "./constants";

export abstract class HttpError<T = any> extends Error {
  public status = 0;
  public domain: string | null = null;
  public request = "";
  public response: T | null = null;
}

export class HttpResponseError<T = any> extends HttpError<T> {
  constructor(res: AxiosResponse) {
    super(`Http Error ${res.status}`);
    this.name = this.constructor.name;
    this.status = res.status;
    this.response = res.data ?? null;
    this.domain = res.config.baseURL ?? null;
    this.request = `${res.config.method?.toUpperCase()} ${
      res.config.url?.split("?")[0] ?? res.config.baseURL
    }`;
  }
}

export class HttpTimeoutError<T> extends HttpError<T> {
  public timeout: number;
  constructor(err: AxiosError) {
    super("Http Error 504");
    this.name = this.constructor.name;
    this.status = 504;
    this.timeout = err.config?.timeout ?? DEFAULT_HTTP_TIMEOUT;
    if (err.config) {
      this.domain = err.config.baseURL ?? null;
      this.request = `${err.config.method?.toUpperCase()} ${
        err.config.url?.split("?")[0] ?? err.config.baseURL
      }`;
    }
  }
}
