import React, { FC, useEffect, useState } from "react";
import SelectList from "../../../../../../../elements/Form/SelectList";
import { useFormValue } from "../../../../../../../hooks/FormValue";
import StepFooter from "../../../StepFooter/StepFooter";

interface TimezoneProps {
  value: string;
  onSubmit: (type: string) => void;
  onPrevious: () => void;
}

const Timezone: FC<TimezoneProps> = ({ value, onSubmit, onPrevious }) => {
  const zone = useFormValue<string>(value, false);
  const [isValid, setIsValid] = useState(false);
  const [zoneOptions] = useState([
    { value: "America/Juneau", displayText: "America/Juneau" },
    { value: "Pacific/Honolulu", displayText: "Hawaii-Aleutian Standard Time" },
    { value: "America/Los_Angeles", displayText: "Pacific Time" },
    { value: "America/Denver", displayText: "Mountain Time" },
    { value: "America/Chicago", displayText: "Central Time" },
    { value: "America/New_York", displayText: "Eastern Time" },
  ]);

  useEffect(() => {
    setIsValid(!!zone.value && !!zone.value?.trim());
  }, [zone]);

  const handlePrevious = () => {
    zone.reset();
    onPrevious();
  };

  const handleSubmit = () => {
    if (zone.value && isValid) onSubmit(zone.value);
  };

  return (
    <div>
      <SelectList
        title="Enter time zone"
        description="Enter the time zone of the property where the lock is located"
        value={zone}
        options={zoneOptions}
        displayFilter={false}
      />
      <StepFooter
        onSubmit={handleSubmit}
        onPrevious={handlePrevious}
        disabled={!isValid}
      />
    </div>
  );
};

export default Timezone;
