import { ReactElement } from "react";
import { Icon } from "@vacasa/react-components-lib";

/**
 * These enums represent the prod values!
 * WifiRouters & Keycards have different values in stage
 */
export enum DeviceTypeEnum {
  Lock = 1, // lock
  Hub = 2, // lock
  Thermostat = 3,
  Alarm = 4,
  SmartLock = 5, // lock
  NoiseSensor = 6,
  WifiRouter = 39, // INCONSISTENT BETWEEN ENVS
  AccessBoard = 41,
  KeyCard = 72, // INCONSISTENT BETWEEN ENVS
}

export const isLockType = (typeId: number) => {
  switch (+typeId) {
    case DeviceTypeEnum.Lock:
    case DeviceTypeEnum.Hub:
    case DeviceTypeEnum.SmartLock:
      return true;
    default:
      return false;
  }
};

export const getDeviceTypeIcon = (
  typeId: number,
  style?: any,
): ReactElement => {
  const width = style?.width ?? 18;
  const height = style?.height ?? 18;
  const attributes = {
    style,
    width,
    height,
    viewBox: `0 0 ${width * 1.5} ${height * 1.5}`,
  };
  switch (typeId) {
    case DeviceTypeEnum.Lock:
    case DeviceTypeEnum.Hub:
    case DeviceTypeEnum.SmartLock:
      return <Icon.Lock {...attributes} />;
    case DeviceTypeEnum.Thermostat:
      return <Icon.Thermometer {...attributes} />;
    case DeviceTypeEnum.Alarm:
      return <Icon.Bell {...attributes} />;
    case DeviceTypeEnum.NoiseSensor:
      return <Icon.Volume2 {...attributes} />;
    case DeviceTypeEnum.WifiRouter:
      return <Icon.Wifi {...attributes} />;
    case DeviceTypeEnum.AccessBoard:
      return <Icon.Shield {...attributes} />;
    case DeviceTypeEnum.KeyCard:
      return <Icon.Keys {...attributes} />;
    default:
      return <Icon.AlertOctagon {...attributes} />;
  }
};
