import React, { FC, useEffect, useState } from "react";
import { HttpError, HttpTimeoutError } from "../../core/errors";
import "./Error.scss";
import { CanceledError } from "axios";

interface ErrorBadgeProps {
  position?: "left" | "center" | "right" | "full-width";
  error?: any;
}

const getErrorMessage = (error: any): string => {
  if (error instanceof Error) {
    return error.message;
  } else if (typeof error === "string") {
    return error;
  } else {
    return JSON.stringify(error);
  }
};

const getErrorDescription = (error: any): string | undefined => {
  if (error instanceof HttpError && error.request) {
    if (error.domain) return `${error.domain}\n${error.request}`;
    return error.request;
  }
  return undefined;
};

const getErrorDetail = (error: any): string | undefined => {
  if (error instanceof HttpError && error.response) {
    return JSON.stringify(error.response, null, 2);
  } else if (error instanceof HttpTimeoutError) {
    return `Request timed out after ${error.timeout / 1000} seconds`;
  }
  return undefined;
};

const ErrorBadge: FC<ErrorBadgeProps> = ({ position = "center", error }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState(getErrorMessage(error));
  const [description, setDescription] = useState(getErrorDescription(error));
  const [detail, setDetail] = useState(getErrorDetail(error));

  useEffect(() => {
    setMessage((msg) => msg ?? getErrorMessage(error));
    setDescription((desc) => desc ?? getErrorDescription(error));
    setDetail((detail) => detail ?? getErrorDetail(error));
  }, [error]);

  if (error === undefined || error === null || error instanceof CanceledError) {
    return null;
  }

  return (
    <div className={`error-badge ${position}`}>
      <div
        className={`error-message ${
          description || detail ? "dynamic" : "static"
        }`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="message">{message}</span>
        <div className={`detail-toggle ${isOpen ? "open" : "closed"}`}>
          {isOpen ? <span>&times;</span> : <span>&#8964;</span>}
        </div>
      </div>
      {(description || detail) && (
        <div className={`error-detail ${isOpen ? "open" : "closed"}`}>
          <div>
            <pre>{description}</pre>
            <pre>
              <code>{detail}</code>
            </pre>
          </div>
        </div>
      )}
    </div>
  );
};

export default ErrorBadge;
