import { useSelector } from "react-redux";
import { StoreData } from "..";

export const useDeviceTypes = () => {
  const deviceTypes = useSelector((state: StoreData) => state.deviceType.data);
  return deviceTypes;
};

export const useNoiseSensorTypeId = () => {
  const deviceTypes = useSelector((state: StoreData) => state.deviceType.data);
  const noiseSensor = deviceTypes?.find(
    (x) => x.attributes.name === "Noise Sensor",
  );
  return noiseSensor?.id;
};

export const useWifiRouterTypeId = () => {
  const deviceTypes = useSelector((state: StoreData) => state.deviceType.data);
  const wifiRouter = deviceTypes?.find(
    (x) => x.attributes.name === "WIFI Router",
  );
  return wifiRouter?.id;
};
