import React, { FC, FormEvent, useState } from "react";
import styles from "./InputExternalIdStep.module.scss";

interface InputExternalIdStepProps {
  onSubmit?: (externalId: string) => void;
}

const InputExternalIdStep: FC<InputExternalIdStepProps> = (props) => {
  const [value, setValue] = useState<string>();

  const handleChange = (event: any) => {
    setValue(event.target.value);
  };

  const handleSubmit = function (event?: FormEvent) {
    event?.preventDefault();
    const externalId = value?.trim();
    if (externalId) props.onSubmit?.(externalId);
  };

  return (
    <div
      className={styles.InputExternalIdStep}
      data-testid="InputExternalIdStep"
    >
      <form className="input-form" onSubmit={handleSubmit}>
        <div className="header">
          <div className="title">New Smart Device</div>
          <div className="message">ENTER EXTERNAL ID</div>
        </div>
        <div className="content">
          <input
            id="inp-external-id"
            type="text"
            name="external-id"
            placeholder="External ID"
            onChange={handleChange}
            autoFocus
          ></input>
        </div>
        <div className="footer">
          <button
            id="btn-external-id-submit"
            className="btn fill"
            type="submit"
            disabled={!value?.trim()}
          >
            Add Your New Device
          </button>
        </div>
      </form>
    </div>
  );
};

export default InputExternalIdStep;
