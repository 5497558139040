import { configureStore } from "@reduxjs/toolkit";
import { PropertyState, PropertyStore } from "./property";
import { FeatureFlagStore, FeatureFlagState } from "./feature-flags";
import {
  DeviceCompatibilityStore,
  DeviceCompatibilityState,
} from "./device-compatibility";
import { AmenityState, AmenityStore } from "./amenities";
import { DeviceTypeState, DeviceTypeStore } from "./device-type";

export interface StoreData {
  property: PropertyState;
  compatibility: DeviceCompatibilityState;
  features: FeatureFlagState;
  amenity: AmenityState;
  deviceType: DeviceTypeState;
}

export default configureStore<StoreData>({
  reducer: {
    property: PropertyStore.reducer,
    compatibility: DeviceCompatibilityStore.reducer,
    features: FeatureFlagStore.reducer,
    amenity: AmenityStore.reducer,
    deviceType: DeviceTypeStore.reducer,
  },
});
