import React, { FC, useEffect, useState } from "react";
import { add, set } from "date-fns";
import StepFooter from "../../../StepFooter/StepFooter";
import {
  LockInfo,
  LockSetupResult,
} from "../../../../../../../models/TurnKeyApi";
import { Image } from "@vacasa/react-components-lib";
import { TurnKeyService } from "../../../../../../../services/turnkey.service";

import "./SaveTurnKeyLock.scss";
import ErrorBadge from "../../../../../../../elements/Error/ErrorBadge";

interface SaveTurnKeyLockProps {
  propertyId: string;
  unitCode: string;
  address: string;
  lockType: string;
  lockLocation: string;
  contactFirstName: string;
  contactLastName: string;
  lockIdNumber: string;
  lockMasterCode: string;
  timezone: string;
  operator?: string;
  onSubmit: (lockData: LockSetupResult) => void;
  onPrevious: () => void;
}

const SaveTurnKeyLock: FC<SaveTurnKeyLockProps> = ({
  propertyId,
  unitCode,
  address,
  lockType,
  lockLocation,
  contactFirstName,
  contactLastName,
  lockIdNumber,
  lockMasterCode,
  timezone,
  operator = "admin-lock-wizard",
  onSubmit,
  onPrevious,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any>();
  const [registeredLock, setRegisteredLock] = useState<LockSetupResult>();

  const getTimeZoneOffset = (zone: string) => {
    switch (zone) {
      case "America/Juneau":
        return "-09:00";
      case "Pacific/Honolulu":
        return "-10:00";
      case "America/Los_Angeles":
        return "-08:00";
      case "America/Denver":
        return "-07:00";
      case "America/Chicago":
        return "-06:00";
      case "America/New_York":
        return "-05:00";
      default:
        throw new Error(`Unsupported time zone: ${zone}`);
    }
  };

  useEffect(() => {
    const createAsync = async () => {
      setIsLoading(true);
      setError(undefined);
      try {
        const baseTime = set(add(new Date(), { days: -1 }), {
          hours: 8,
          minutes: 0,
          seconds: 0,
        });

        const service = new TurnKeyService();
        const data: LockInfo = {
          site_address: address,
          lock_name: `${unitCode}_${lockLocation}`,
          installed_on: lockLocation,
          timezone: getTimeZoneOffset(timezone),
          timezone_area: timezone,
          contact: `${contactFirstName} ${contactLastName}`,
          lock_number: lockIdNumber,
          private_master_code: lockMasterCode,
          base_date_time: baseTime,
          lock_model: lockType,
          newLock: true,
          propertyId: propertyId,
          operator: operator,
        };

        const lock = await service.setupLock(data);
        setRegisteredLock(lock);

        console.log("GEN LOCK SUCCESS", lock);
      } catch (err) {
        console.log("GEN LOCK ERROR", err);

        setError(err);
      } finally {
        setIsLoading(false);
      }
    };
    createAsync();
  }, []);

  const handleSubmit = () => {
    // Do not let the user advance until the lock has been created
    if (!isLoading && registeredLock) {
      onSubmit(registeredLock);
    }
  };

  const handlePrevious = () => {
    // Do not let the user go back unless an error has been reported
    if (!isLoading && error) {
      onPrevious();
    }
  };

  return (
    <div className="register-turnkey-lock">
      <div className="step-header">
        <div className="title">Registering Lock</div>
        <div className="description">Please wait for the lock to register</div>
      </div>
      <div className="step-body">
        {isLoading && (
          <div className="loading-spinner">
            <Image.Spinner height={64} width={64} />
          </div>
        )}
        {!isLoading && error && (
          <div>
            <h3>Lock Creation error!</h3>
            <div>An error occurred in registering your lock!</div>
            <ErrorBadge error={error} position="full-width" />
            <div>
              Please use the back button to correct your data, and try again.
            </div>
          </div>
        )}
        {!isLoading && registeredLock && (
          <div>
            <h3>Lock Creation success!!</h3>
            <div>
              Congratulations! Your lock is now configured on the server. Next,
              we will set up the hardware.
            </div>
          </div>
        )}
      </div>
      {!isLoading && (
        <StepFooter
          onSubmit={handleSubmit}
          onPrevious={
            error ? handlePrevious : undefined
            // Do not let the user go back unless an error has been reported
          }
          disabled={!registeredLock}
        />
      )}
    </div>
  );
};

export default SaveTurnKeyLock;
