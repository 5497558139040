import React, { FC, useEffect, useState } from "react";
import SelectList from "../../../../../../../elements/Form/SelectList";
import { useFormValue } from "../../../../../../../hooks/FormValue";
import StepFooter from "../../../StepFooter/StepFooter";

interface LockTypeProps {
  value: string;
  onSubmit: (type: string) => void;
  onPrevious: () => void;
}

const LockType: FC<LockTypeProps> = ({ value, onSubmit, onPrevious }) => {
  const lockType = useFormValue<string>(value, false);
  const [isValid, setIsValid] = useState(false);
  const [lockTypeOptions] = useState([
    { displayText: "TurnKey BE365 (Deadbolt)", value: "BE365" },
    { displayText: "TurnKey FE595 (Handle)", value: "FE595" },
  ]);

  useEffect(() => {
    setIsValid(!!lockType.value && !!lockType.value?.trim());
  }, [lockType]);

  const handlePrevious = () => {
    lockType.reset();
    onPrevious();
  };

  const handleSubmit = () => {
    if (lockType.value) onSubmit(lockType.value);
  };

  return (
    <div>
      <SelectList
        title="Lock Type"
        description="CHOOSE TURNKEY LOCK TYPE"
        value={lockType}
        options={lockTypeOptions}
        displayFilter={false}
      />
      <StepFooter
        onSubmit={handleSubmit}
        onPrevious={handlePrevious}
        disabled={!isValid}
      />
    </div>
  );
};

export default LockType;
