import React, { FC } from "react";
import { ICellRendererParams } from "ag-grid-community";
import DeviceTableRow from "../../../models/DeviceTableRow";
import { getDeviceTypeIcon } from "../../../models/DeviceTypeEnum";

const DeviceTypeRenderer: FC<ICellRendererParams> = (props) => {
  const device = props.data as DeviceTableRow;

  const icon = getDeviceTypeIcon(device.typeId);
  return (
    <span className="device-type-icon">
      {icon} {device.type}
    </span>
  );
};

export default DeviceTypeRenderer;
