import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FeatureFlagState } from ".";

const initialState: FeatureFlagState = {};

export const slice = createSlice({
  name: "features",
  initialState,
  reducers: {
    enableFeature: (state, action: PayloadAction<string>) => {
      const featureName = action.payload.toLowerCase();
      state[featureName] = true;
    },
    disableFeature: (state, action: PayloadAction<string>) => {
      const featureName = action.payload.toLowerCase();
      state[featureName] = false;
    },
    setAllFeatures: (state, action: PayloadAction<string>) => {
      const featureParams = action.payload;
      if (featureParams) {
        const features = featureParams.split(",");
        features.forEach((feature) => {
          const featureName = feature.trim().toLowerCase();
          if (featureName) {
            const featureValue = featureName.charAt(0) !== "!";
            const keyName = featureValue
              ? featureName
              : featureName.substring(1);

            state[keyName] = featureValue;
          }
        });
      }
    },
  },
});
