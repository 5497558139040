import { ReactElement } from "react";
import { getDeviceTypeIcon } from "../../../../models/DeviceTypeEnum";
import { SelectOption } from "../../../../models/Form";
import { BackendResource } from "../../../../models/SmartHomeApi";

export const parseResource = (resource: BackendResource<any>): SelectOption => {
  let icon: ReactElement | undefined;
  if (resource.type === "device_types") {
    icon = getDeviceTypeIcon(resource.id!);
  }

  return {
    icon,
    value: resource.id,
    displayText: resource.attributes.display_name ?? resource.attributes.name,
  };
};
