import React, { FC, useEffect, useRef, useState } from "react";
import { parse, format, isBefore } from "date-fns";
import { FormValue } from "../../models/Form";
import { Icon, Tooltip } from "@vacasa/react-components-lib";
import { IoHelpCircleOutline } from "react-icons/io5";

import "./Input.scss";

export interface InputDateProps {
  label?: string;
  value?: FormValue<Date>;
  disabled?: boolean;
  required?: boolean;
  width?: number;
  min?: Date;
  max?: Date;
  display?: "compact";
  endOfDay?: boolean;
  helpText?: string;
}
const DATE_FORMAT = "yyyy-MM-dd";
const DATE_FORMAT_DISPLAY = "MM/dd/yy";

const InputDate: FC<InputDateProps> = (props) => {
  const onChange = props.value?.onChange;
  const [value, setValue] = useState(
    props.value?.value ? format(props.value.value, DATE_FORMAT) : "",
  );

  const [validationError, setValidationError] = useState(
    props.value?.isValid ? undefined : "default_invalid",
  );
  const [isDirty, setIsDirty] = useState(false);

  const [min] = useState(
    props.min ? format(props.min, DATE_FORMAT) : undefined,
  );
  const [max] = useState(
    props.max ? format(props.max, DATE_FORMAT) : undefined,
  );

  const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  useEffect(() => {
    if (value) {
      const dateValue = parse(value, DATE_FORMAT, new Date());
      if (props.endOfDay)
        dateValue.setHours(dateValue.getHours() + 23, 59, 59, 999);
      onChange?.(dateValue, !validationError);
    } else {
      onChange?.(undefined, !validationError);
    }
  }, [onChange, props.endOfDay, value, validationError]);

  const isBeforeMin = function (dateStr: string): boolean {
    if (!min) return false;
    const minDate = parse(min, DATE_FORMAT, new Date());
    const selectedDate = parse(dateStr, DATE_FORMAT, new Date());
    return isBefore(selectedDate, minDate);
  };
  const isAfterMax = function (dateStr: string): boolean {
    if (!max) return false;
    const maxDate = parse(max, DATE_FORMAT, new Date());
    const selectedDate = parse(dateStr, DATE_FORMAT, new Date());
    return isBefore(maxDate, selectedDate);
  };

  const handleChange = function (event: any) {
    setValidationError(undefined);
    setIsDirty(true);

    const newValue: string = event.target.value;

    if (props.required && !newValue) setValidationError("required");
    else if (isBeforeMin(newValue))
      setValidationError(
        `must be ≥ ${format(props.min!, DATE_FORMAT_DISPLAY)}`,
      );
    else if (isAfterMax(newValue))
      setValidationError(
        `must be ≤ ${format(props.max!, DATE_FORMAT_DISPLAY)}`,
      );

    setValue(newValue);
  };

  const handleBlur = () => {
    setIsDirty(true);
    if (
      (!validationError || validationError === "default_invalid") &&
      props.required &&
      !value
    )
      setValidationError("required");
  };

  const handleIconClick = () => {
    // Focus on text and have user type date
    inputRef.current?.focus();

    // Click on input after focusing to bring up datepicker
    // (this may or may not work depending on browser)
    inputRef.current?.click();
  };

  const attributes = {
    type: "date",
    value: value,
    min,
    max,
    disabled: props.disabled,
    onChange: handleChange,
    onBlur: handleBlur,
    className: `input-date ${!!validationError && isDirty ? "invalid" : ""}`,
    style: {
      width: props.width,
    },
  };

  return (
    <div className="component-input-date component-input">
      <div
        className={`label ${props.display} ${props.disabled ? "disabled" : ""}`}
      >
        <div>{props.label}</div>
        {props.helpText && (
          <Tooltip message={props.helpText}>
            <div className="help-text">
              <IoHelpCircleOutline />
            </div>
          </Tooltip>
        )}
      </div>
      <div
        className={`input-wrapper ${
          !!validationError && isDirty ? "invalid" : ""
        }`}
      >
        <input {...attributes} ref={inputRef} />
        <Icon.Calendar
          className={`calendar-icon ${props.disabled ? "disabled" : " "}`}
          onClick={handleIconClick}
        />
      </div>
      <div className="validation-error">{isDirty && validationError}</div>
    </div>
  );
};

export default InputDate;
