import React, { FC, useEffect, useState } from "react";
import { FormValue, SelectOption } from "../../models/Form";
import { uniqueId } from "lodash";

import "./Select.scss";

interface SelectListProps {
  id?: string;
  title?: string;
  description?: string;
  displayFilter?: boolean;
  filterPlaceholder?: string;
  value: FormValue<any>;
  options?: SelectOption[];
}

const SelectList: FC<SelectListProps> = (props) => {
  const [id] = useState(props.id || uniqueId("select-list-"));
  const [filterText, setFilterText] = useState<string>();
  const [filteredValues, setFilteredValues] = useState<
    SelectOption[] | undefined
  >(props.options);

  const handleRowClick = function (value: string | number) {
    return () => {
      props.value.onChange(value, value !== undefined && value !== null);
    };
  };

  const handleFilterChange = function (event: any) {
    const text = event.target.value;
    setFilterText(text.trim().toLowerCase());
  };

  useEffect(() => {
    if (filterText) {
      const appliedFilter = props.options?.filter((option) =>
        option.displayText.toLowerCase().includes(filterText),
      );
      setFilteredValues(appliedFilter);
    } else {
      setFilteredValues(props.options);
    }
  }, [props.options, filterText]);

  return (
    <div className="component-select-list component-select">
      <form className="select-form">
        <div className="select-list-header">
          <div className="title">{props.title}</div>
          <div
            className={`message ${
              props.title && props.description ? "spaced" : ""
            }`}
          >
            {props.description}
          </div>
        </div>
        {props.displayFilter && (
          <div className="filter">
            <input
              type="search"
              placeholder={props.filterPlaceholder}
              onChange={handleFilterChange}
              autoFocus
            ></input>
          </div>
        )}
        <div className="content">
          {filteredValues?.map((option) => {
            return (
              <div
                key={option.value}
                className="select-option"
                onClick={handleRowClick(option.value)}
              >
                <div className="display-text">
                  {option.icon && (
                    <div className="select-option-icon">{option.icon}</div>
                  )}
                  <div className="label">{option.displayText}</div>
                </div>
                <div className="radio-input">
                  <input
                    type="radio"
                    value={option.value}
                    name={`selection-group-${id}`}
                    checked={option.value === props.value.value}
                    readOnly={true}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </form>
    </div>
  );
};

export default SelectList;
