import { useSelector } from "react-redux";
import { StoreData } from "..";
import { DeviceTypeEnum } from "../../models/DeviceTypeEnum";
import {
  useNoiseSensorTypeId,
  useWifiRouterTypeId,
} from "../device-type/selector";

export const useLockCompatibility = () => {
  const devices = useSelector((state: StoreData) => state.compatibility.data);
  const lock = devices?.find(
    (x) => x.attributes.device_type === DeviceTypeEnum.Lock,
  );
  return lock ? false : true;
};

export const useNoiseSensorCompatibility = () => {
  const devices = useSelector((state: StoreData) => state.compatibility.data);
  const noiseType = useNoiseSensorTypeId();
  const noiseSensor = devices?.find(
    (x) => x.attributes.device_type === noiseType,
  );
  return noiseSensor ? false : true;
};

export const useRouterCompatibility = () => {
  const devices = useSelector((state: StoreData) => state.compatibility.data);
  const wifiRouter = useWifiRouterTypeId();
  const router = devices?.find((x) => x.attributes.device_type === wifiRouter);
  return router ? false : true;
};
