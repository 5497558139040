import React, { FC, useState } from "react";
import StepFooter from "../../../StepFooter/StepFooter";
import YesNoToggle from "../../../../../../../elements/Form/YesNoToggle";
import { addMilliseconds, format } from "date-fns";
import { formatInTimeZone, getTimezoneOffset } from "date-fns-tz";

interface SetTimeProps {
  value?: boolean;
  timeName: string;
  timeValue: Date;
  timeZone?: string;
  masterCode: string;
  onSubmit: () => void;
  onPrevious?: () => void;
}

const SetTime: FC<SetTimeProps> = ({
  value: initialValue,
  timeName,
  timeValue,
  timeZone,
  masterCode,
  onSubmit,
  onPrevious,
}) => {
  const [isTimeSet, setIsTimeSet] = useState(initialValue);

  const getFormattedTime = (): string => {
    const timeFormat = "yyyy MM dd HHmm";
    let time = timeValue;
    if (timeZone) {
      const desiredOffset = getTimezoneOffset(timeZone, new Date(2020, 1, 1));
      const currentOffset = getTimezoneOffset(timeZone, time);
      const offsetDiff = desiredOffset - currentOffset;
      if (offsetDiff !== 0) {
        time = addMilliseconds(time, offsetDiff);
      }
      return formatInTimeZone(time, timeZone, timeFormat);
    }
    return format(time, timeFormat);
  };

  const handleToggle = (newValue: boolean) => {
    setIsTimeSet(newValue);
  };

  const handleSubmit = () => {
    if (isTimeSet) {
      onSubmit();
    }
  };

  return (
    <div>
      <div className="step-header">
        <div className="title">Set {timeName} time on the lock</div>
      </div>
      <div className="step-body">
        <ol>
          <li>
            Enter your 6 digit Private master Code (PMC), on the lock keypad:
            <div className="code-block">{`${masterCode.substring(
              0,
              3,
            )} ${masterCode.substring(3)}`}</div>
          </li>
          <li>
            Press the SCHLAGE key and wait for the Schlage light to stop
            blinking green
          </li>
          <li>Press the 1 on your keypad</li>
          <li>
            Press the SCHLAGE key again and wait for the Schlage light to stop
            blinking green
          </li>
          <li>
            Enter this code to set the {timeName} time on the lock
            <div className="code-block">{getFormattedTime()}</div>
          </li>
          <li>
            Wait for about 3 seconds and the SCHLAGE button will flash green and
            the lock will play an ascending tone. If the button flashes red,
            start again at Step 1.
          </li>
        </ol>
        <div>I have set the {timeName} time for this lock.</div>
        <YesNoToggle value={isTimeSet ?? false} onChange={handleToggle} />
      </div>
      <StepFooter
        onSubmit={handleSubmit}
        onPrevious={onPrevious}
        disabled={!isTimeSet}
      />
    </div>
  );
};

export default SetTime;
