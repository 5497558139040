import React, { FC } from "react";
import AddButton from "../../Button/AddButton";
import EmptyImage from "../../../assets/Reservation_List_Empty_State.svg";

import "./DataPlaceholder.scss";

interface DataPlaceholderProps {
  title: string;
  message: string;
  btnText: string;
  btnHoverText?: string;
  btnDisabled?: boolean;
  errorMessage?: string;
  onBtnClick?: (e: MouseEvent) => void;
}

const DataPlaceholder: FC<DataPlaceholderProps> = (props) => {
  const handleBtnClick = (e: any) => {
    props.onBtnClick?.(e);
  };

  return (
    <div className="component-data-placehodler">
      <div className="error-message">{props.errorMessage}</div>
      <img className="empty-img" src={EmptyImage} alt="No Devices" />
      <div className="title">{props.title}</div>
      <div className="message">{props.message}</div>
      <div className="action">
        <AddButton
          id="btn-add-first-device"
          display="fill"
          hoverText={props.btnHoverText}
          onClick={handleBtnClick}
          disabled={props.btnDisabled}
        >
          {props.btnText}
        </AddButton>
      </div>
    </div>
  );
};

export default DataPlaceholder;
