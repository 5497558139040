import { Amenity } from "../../models/ConnectApi";
import { slice } from "./reducer";
import * as selectors from "./selector";

export interface AmenityState {
  isLoading: boolean;
  amenities?: Amenity[];
  error?: string;
}

export const AmenityStore = {
  reducer: slice.reducer,
  actions: slice.actions,
  selectors,
};
