import { Image } from "@vacasa/react-components-lib";
import React, { FC, useState } from "react";
import { formatDateTime } from "../../../../../core/format";
import BasicButton from "../../../../../elements/Button/BasicButton";
import { RegisteredDevice } from "../../../../../models/SmartDeviceApi";
import SmartDeviceService from "../../../../../services/smart-device.service";
import DeviceDetail from "../DeviceDetails/DeviceDetails";

import "./ToggleAlerts.scss";

interface ToggleAlertsProps {
  device: RegisteredDevice;
  isEnabled: boolean;
  onSubmit?: (device: RegisteredDevice) => void;
  onCancel?: () => void;
}

const ToggleAlerts: FC<ToggleAlertsProps> = (props) => {
  const defaultPauseUntil = new Date();
  defaultPauseUntil.setHours(defaultPauseUntil.getHours() + 2);

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [pauseUntil] = useState(defaultPauseUntil);

  const handleSubmit = () => {
    const updatedDevice = { ...props.device };
    updatedDevice.AlertsPauseUntilDate = props.isEnabled
      ? pauseUntil.toISOString()
      : null;

    const service = new SmartDeviceService();

    setIsLoading(true);
    setErrorMessage(undefined);

    service
      .updateDevice(updatedDevice)
      .then((updateDevice) => props.onSubmit?.(updateDevice))
      .catch((err) => setErrorMessage(err.message))
      .finally(() => setIsLoading(false));
  };

  const handleCancel = () => {
    props.onCancel?.();
  };

  const getDetails = () => {
    if (props.isEnabled) {
      return (
        <div className="pause-detail">
          <div className="label">Pause Alerts Unitil</div>
          <div className="date-time">{formatDateTime(pauseUntil)}</div>
        </div>
      );
    } else {
      return <div className="resume-detail">Resume Alerts Immediately</div>;
    }
  };

  return (
    <div className="component-toggle-device-alerts">
      <DeviceDetail
        device={props.device}
        title={props.isEnabled ? "Pause Alerts" : "Resume Alerts"}
      >
        {getDetails()}
      </DeviceDetail>
      <div className="toggle-device-alert-footer">
        <div>
          <BasicButton display="outline" onClick={handleCancel}>
            Cancel
          </BasicButton>
          {isLoading ? (
            <Image.Spinner className="spinner" width={36} height={36} />
          ) : (
            <BasicButton
              display="fill"
              onClick={handleSubmit}
              style={{ float: "right" }}
            >
              {props.isEnabled ? "Pause" : "Resume"} alerts
            </BasicButton>
          )}
        </div>
        <div className="error-message">{errorMessage}</div>
      </div>
    </div>
  );
};

export default ToggleAlerts;
