import React, { FC } from "react";
import styles from "../../HomeDeviceGrid/DeviceDetailsDialog/DeviceDetailsDialog.module.scss";
import { IReport } from "../../../models/SmartHomeApi";
import CopyValue from "../../../elements/Copy/Copy";

interface DeviceDetailsDialogProps {
  dataRow: IReport;
}

const formatValue = (value: any) => {
  if (value === null || value === undefined) return "null";
  if (typeof value === "object") return JSON.stringify(value);
  if (typeof value === "boolean") return value ? "true" : "false";
  return value;
};

const DeviceDetailsDialog: FC<DeviceDetailsDialogProps> = ({ dataRow }) => {
  const getRow = (
    key: keyof IReport,
    title: string,
    addCopy = false,
    format = formatValue,
  ) => {
    const dataValue = format(dataRow[key]);
    return (
      <div>
        <div className="row" style={{ justifyContent: "flex-start" }}>
          <label className="col detail-key">{title}:</label>
          <label className="col detail-value">
            {dataValue}
            {addCopy && <CopyValue value={dataValue} />}
          </label>
        </div>
      </div>
    );
  };
  return (
    <div
      className={styles.DeviceDetailsDialog}
      data-testid="DeviceDetailsDialog"
    >
      <div className="details-dialog">
        <b className="details-dialog-title">Lock Details</b>
        {getRow("vendor", "Vendor")}
        {getRow("lock_id", "Id", true)}
        {getRow("lock_active", "Active")}
        {getRow("lock_deleted", "Deleted")}
        {dataRow.lock_deleted &&
          getRow("lock_deletedAt", "Deleted At", false, (value) =>
            new Date(value).toLocaleString(),
          )}
      </div>
    </div>
  );
};

export default DeviceDetailsDialog;
