import { useEffect, FC } from "react";
import { Icon, Image } from "@vacasa/react-components-lib";
import { useFormValue } from "../../../hooks/FormValue";
import FormInput from "../../../elements/Form";
import { ChartDataSeries, SeriesOptions } from "../../../elements/Chart/types";

import LineChart from "../../../elements/Chart/LineChart";
import BarChart from "../../../elements/Chart/BarChart";

export interface DateRangeChartProps {
  chartType: "line" | "bar";
  cardTitle: string;
  chartTitle?: string;
  data?: ChartDataSeries;
  seriesOptions?: SeriesOptions;
  loading?: boolean;
  error?: string;
  filterFrom: Date;
  filterTo: Date;
  maxFilter?: Date;
  minFilter?: Date;
  onRefreshClick?: () => void;
  onDateRangeChange?: (from: Date, to: Date) => void;
  timezone?: string;
}

const DateRangeChart: FC<DateRangeChartProps> = (props) => {
  const {
    loading,
    data,
    error,
    onDateRangeChange,
    filterFrom,
    filterTo,
    timezone,
  } = props;

  const inputFilterFrom = useFormValue(filterFrom, true);
  const inputFilterTo = useFormValue(filterTo, true);

  // Update parent component on new date range
  useEffect(() => {
    if (inputFilterFrom.isValid && inputFilterTo.isValid) {
      onDateRangeChange?.(inputFilterFrom.value!, inputFilterTo.value!);
    }
  }, [inputFilterFrom, inputFilterTo, onDateRangeChange]);

  const handleRefreshData = async (e: any) => {
    e.preventDefault();
    props.onRefreshClick?.();
  };

  const getMainSection = () => {
    if (error) {
      return (
        <div className="chart-placeholder">
          <div className="Status">{error}</div>
        </div>
      );
    } else if (loading || !data) {
      return (
        <div className="chart-placeholder">
          <div className="Status">
            <div>loading...</div>
            <Image.Spinner height={36} width={36} />
          </div>
        </div>
      );
    } else {
      const chartProps = {
        title: props.chartTitle,
        data,
        filterFrom,
        filterTo,
        seriesOptions: props.seriesOptions,
        timezone: timezone,
      };

      if (props.chartType === "line")
        return (
          <div className="line-chart-wrapper">
            <LineChart {...chartProps} />
          </div>
        );
      if (props.chartType === "bar")
        return (
          <div className="bar-chart-wrapper">
            <BarChart {...chartProps} />
          </div>
        );
    }
  };

  return (
    <div className="card">
      <div className="device-historical-activity">
        <div className="header historical-header">
          <div className="icon">
            <Icon.RotateCCW />
          </div>
          <div className="title">{props.cardTitle}</div>
          <div className="refresh-btn">
            <button onClick={handleRefreshData}>
              <Icon.RefreshCW className="icon-refresh"></Icon.RefreshCW>
            </button>
          </div>
          <div className="chart-filter">
            <FormInput.Date
              label="FROM"
              value={inputFilterFrom}
              min={props.minFilter}
              max={props.maxFilter}
              display="compact"
              required
            />
            <FormInput.Date
              label="TO"
              value={inputFilterTo}
              min={props.minFilter}
              max={props.maxFilter}
              endOfDay={true}
              display="compact"
              required
            />
          </div>
        </div>
        <div className="chart-content">{getMainSection()}</div>
      </div>
    </div>
  );
};

export default DateRangeChart;
