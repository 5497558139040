import { Tooltip } from "@vacasa/react-components-lib";
import React, { FC, useEffect, useState } from "react";
import { FormValue } from "../../models/Form";
import { IoHelpCircleOutline } from "react-icons/io5";

import "./Input.scss";

export interface InputNumberProps {
  id?: string;
  label?: string;
  value?: FormValue<number>;
  placeholder?: string;
  min?: number;
  max?: number;
  precision?: number;
  disabled?: boolean;
  required?: boolean;
  width?: number;
  display?: "compact";
  helpText?: string;
}

const adhereToPrecision = (number?: string, precision?: number) => {
  if (number && isNaN(Number(number))) return undefined;
  else if (number && precision !== undefined && precision >= 0) {
    const adjusted = Number(number) * Math.pow(10, precision);
    const truncated = Math.floor(adjusted);
    return (truncated / Math.pow(10, precision)).toString();
  }
  return number;
};

const InputNumber: FC<InputNumberProps> = (props) => {
  const defaultValue = adhereToPrecision(
    props.value?.value?.toString(),
    props.precision,
  );

  const [value, setValue] = useState(defaultValue);
  const [validationError, setValidationError] = useState(
    props.value?.isValid ? undefined : "default_invalid",
  );
  const [isDirty, setIsDirty] = useState(false);

  // Send value to parent
  useEffect(() => {
    const numeric = value ? Number(value) : undefined;
    props.value?.onChange(numeric, !validationError);
  }, [props.value, value, validationError]);

  // update and validate value
  const handleChange = function (event: any) {
    setValidationError(undefined);
    setIsDirty(true);

    const newValue = adhereToPrecision(event.target.value, props.precision);
    if (newValue) {
      const numeric = Number(newValue);
      if (props.min !== undefined && numeric < props.min)
        setValidationError(`must be greater or equal to ${props.min}`);
      else if (props.max !== undefined && numeric > props.max)
        setValidationError(`must be less or equal to ${props.max}`);
    } else if (props.required) setValidationError("required");

    setValue(newValue);
  };

  // display validation error if value is required and empty
  const handleBlur = () => {
    setIsDirty(true);
    if (
      (!validationError || validationError === "default_invalid") &&
      props.required &&
      !value
    )
      setValidationError("required");
  };

  // Prevents decmimal input if precision is 0
  const handleKeyDown = (e: any) => {
    if (props.precision !== undefined && props.precision <= 0) {
      if (e.key === ".") e.preventDefault();
    }
  };

  const attributes = {
    id: props.id,
    type: "number",
    value: value,
    placeholder: props.placeholder,
    min: props.min,
    max: props.max,
    disabled: props.disabled,
    onKeyDown: handleKeyDown,
    onChange: handleChange,
    onBlur: handleBlur,
    className: `input-number ${!!validationError && isDirty ? "invalid" : ""}`,
    style: {
      width: props.width,
    },
  };

  return (
    <div className="component-input-number component-input">
      <div
        className={`label ${props.display} ${props.disabled ? "disabled" : ""}`}
      >
        <div>{props.label}</div>
        {props.helpText && (
          <Tooltip message={props.helpText}>
            <div className="help-text">
              <IoHelpCircleOutline />
            </div>
          </Tooltip>
        )}
      </div>
      <input {...attributes} />
      <div className="validation-error">{isDirty && validationError}</div>
    </div>
  );
};

export default InputNumber;
