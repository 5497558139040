/* eslint-disable */
import { add, format } from "date-fns";
import AbstractService from "./abstract.service";
import { ENVIRONMENT, TURNKEY_API } from "../core/constants";
import {
  LockInfo,
  LockSetupResult,
  CodeGenRequest,
  CodeGenResult,
  CodeType,
  RegisteredLock,
} from "../models/TurnKeyApi";
import { HttpResponseError } from "../core/errors";
import { AxiosHeaders } from "axios";
import SmartHomeService from "./smarthome.service";

export class TurnKeyService extends AbstractService {
  constructor() {
    super(TURNKEY_API);
  }

  private getLocktype(): string {
    return "TurnKey";
  }

  private async registerLock(lockInfo: LockInfo) {
    const url = `/lock/registerLock`;

    // Format date for request
    const req: any = Object.assign({}, lockInfo);
    req.base_date_time = format(req.base_date_time, "yyyy-MM-dd HH:mm");

    const result = await this.post<RegisteredLock>(url, req);
    if (result.error) throw new Error(result.error);
    else return result;
  }

  private async generateLockCode(data: CodeGenRequest) {
    const url = `/lock/generateCode`;
    const res = await this.post<CodeGenResult>(url, data);
    if (res && res.code) return res.code;
    else
      throw new Error(
        `Unable to generate lock code: ${res?.error ?? "reasons unknown"}`,
      );
  }

  private async removeLock(lockId: string) {
    const url = `/lock/removeLock`;
    return await this.post(url, { lockId: lockId });
  }

  private async addSmartHomeLock(
    propertyId: string,
    lockId: string,
    vendorType: string,
    operator: string,
  ) {
    const url = `/smarthome/addlock`;
    const body = {
      propertyId: propertyId,
      externalId: lockId,
      vendorType: vendorType,
      operator: operator,
    };
    return await this.post(url, body);
  }

  public async setupLock(lockInfo: LockInfo): Promise<LockSetupResult> {
    const lock = await this.registerLock(lockInfo);
    if (!lock || !lock.lockId)
      throw new Error("Unable to register lock. Please try again.");

    // Generate one-time code
    const genCodeReq: CodeGenRequest = {
      lockId: lock.lockId,
      startDate: new Date(),
      endDate: new Date(),
      userType: CodeType.OneTime,
      nameForCode: "test test",
    };
    genCodeReq.startDate = add(genCodeReq.startDate, {
      hours: parseInt(format(genCodeReq.startDate, "x")),
    });
    genCodeReq.endDate = add(genCodeReq.startDate, {
      hours: parseInt(format(genCodeReq.endDate, "x")),
    });

    const oneTimeCode = await this.generateLockCode(genCodeReq);

    // Generate guest code
    genCodeReq.userType = CodeType.Guest;
    genCodeReq.startDate = add(genCodeReq.startDate, { hours: -6 });
    genCodeReq.endDate = add(genCodeReq.endDate, { hours: 12 });
    genCodeReq.nameForCode = "Guest Code";

    const guestCode = await this.generateLockCode(genCodeReq);

    // Generate owner code
    genCodeReq.userType = CodeType.Owner;
    genCodeReq.endDate = add(genCodeReq.endDate, { years: 2 });
    genCodeReq.nameForCode = "Owner Code";

    const ownerCode = await this.generateLockCode(genCodeReq);

    const result: LockSetupResult = {
      lockId: lock.lockId,
      guestCode,
      ownerCode,
      oneTimeCode,
      baseTime: lockInfo.base_date_time,
    };

    //holy crap! it worked! do the rest of it (smart lock/blah blah)
    try {
      await this.addSmartHomeLock(
        lockInfo.propertyId,
        result.lockId + "",
        this.getLocktype(),
        lockInfo.operator,
      );
    } catch (error) {
      await this.removeLock(result.lockId + "");
      throw new Error(
        "An error occurred with registering the lock, please try again.",
      );
    }

    return result;
  }

  public async teardownLock(
    externalId: string,
    gatewayId: number,
    deviceId: number,
    operator: string = "admin-lock-wizard",
  ) {
    const smartHome = new SmartHomeService();
    const result = await smartHome.deleteDeviceAndGateway(
      deviceId,
      gatewayId,
      operator,
    );
    await this.removeLock(externalId);

    return result;
  }
}
