import { Icon, Tooltip } from "@vacasa/react-components-lib";
import { FC, useEffect, useState } from "react";
import {
  formatDateTime,
  formatMacAddressForDisplay,
} from "../../../../core/format";
import AnchoredDialog from "../../../../elements/Dialog/AnchoredDialog";
import {
  RegisteredDevice,
  RegisteredDeviceType,
  RegisteredRouter,
  RegisteredSensor,
} from "../../../../models/SmartDeviceApi";

import DeviceDetail from "./DeviceDetails/DeviceDetails";
import EditDevice from "./EditDevice/EditDevice";
import ToggleAlerts from "./ToggleAlerts/ToggleAlerts";
import UnregisterDevice from "./UnregisterDevice/UnregisterDevice";

import "./Device.scss";

interface DeviceProps {
  device: RegisteredDevice;
  timezone?: string;
  onEdit?: (device: RegisteredDevice) => void;
  onUnregister?: (device: RegisteredDevice) => void;
}

const Device: FC<DeviceProps> = (props) => {
  const [isEnabled, setIsEnabled] = useState(true);

  const [isPausingAlerts, setIsPausingAlerts] = useState(false);
  const [isResumingAlerts, setIsResumingAlerts] = useState(false);
  const [isViewingDetail, setIsViewingDeviceDetail] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isUnregistering, setIsUnregistering] = useState(false);

  // Calculates whether alerts are paused
  useEffect(() => {
    const disabledUntil = props.device.AlertsPauseUntilDate;
    if (disabledUntil) {
      const threshold = new Date(disabledUntil);
      setIsEnabled(new Date().getTime() >= threshold.getTime());
    } else {
      setIsEnabled(true);
    }
  }, [props.device]);

  // PAUSE & RESUME
  const handleToggleAlerts = () => {
    if (isEnabled) {
      setIsPausingAlerts(true);
    } else {
      setIsResumingAlerts(true);
    }
  };
  const handlePauseAlertClose = () => {
    setIsPausingAlerts(false);
  };
  const handleResumeAlertClose = () => {
    setIsResumingAlerts(false);
  };

  // VIEW DETAIL
  const handleViewDeviceDetail = () => {
    setIsViewingDeviceDetail(true);
  };
  const handleDeviceDetailClose = () => {
    setIsViewingDeviceDetail(false);
  };

  // EDIT
  const handleEditDeviceClick = () => {
    setIsEditing(true);
  };
  const handleEditDeviceClose = () => {
    setIsEditing(false);
  };
  const handleEditDevice = (newDevice: RegisteredDevice) => {
    props.onEdit?.(newDevice);
    setIsEditing(false);
    setIsPausingAlerts(false);
    setIsResumingAlerts(false);
  };

  // UNREGISTER
  const handleUnregisterClick = () => {
    setIsUnregistering(true);
  };
  const handleUnregisterClose = () => {
    setIsUnregistering(false);
  };
  const handleUnregister = () => {
    props.onUnregister?.(props.device);
    setIsUnregistering(false);
  };

  const getTypeBadge = () => {
    let icon = <Icon.AlertOctagon />;
    let title = "Unknown";

    switch (props.device.DeviceType) {
      case RegisteredDeviceType.Sensor:
        title = "Noise Sensor";
        icon = <Icon.Radio />;
        break;
      case RegisteredDeviceType.Router:
        title = "Router";
        icon = <Icon.Wifi />;
        break;
    }
    return (
      <div className={`badge ${props.device.DeviceType}`}>
        <span className="icon">{icon}</span>
        <span className="title">{title}</span>
      </div>
    );
  };

  const getThresholdText = () => {
    switch (props.device.DeviceType) {
      case RegisteredDeviceType.Sensor:
        return `Threshold (${props.device.NoiseAlertDecibelThreshold}) dB`;
      case RegisteredDeviceType.Router:
        return `Threshold (${props.device.ConnectedAlertThreshold} connected)`;
      default:
        return "";
    }
  };

  const getLatestReading = () => {
    switch (props.device.DeviceType) {
      case RegisteredDeviceType.Sensor:
        return `${props.device.RecentReading!.Decibels} dB, ${
          props.device.RecentReading!.Temperature
        } °F`;
      case RegisteredDeviceType.Router:
        return `${props.device.RecentReading!.Connected} connected`;
      default:
        return "";
    }
  };

  const getAlertsEnabledBadge = () => {
    return (
      <span className={`alert-status-badge ${!isEnabled && "disabled"}`}>
        {isEnabled ? "Alerts enabled" : "Alerts disabled"}
      </span>
    );
  };

  const getSensorDetail = (device: RegisteredSensor) => {
    if (!device.RecentReading) return;

    return (
      <div className="sensor-detail">
        <table className="device-detail-table">
          {/* Refering to classes in ./DeviceDetails/DeviceDetails.scss */}
          <tbody>
            <tr>
              <td>
                <div className="label">Latest Reading</div>
                <div>{device.RecentReading.Decibels} dB</div>
              </td>
              <td>
                <div className="label">Location</div>
                <div className="unweighted">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`https://www.google.com/maps/search/?api=1&query=${device.RecentReading.Latitude},${device.RecentReading.Longitude}`}
                  >
                    {device.RecentReading.Latitude.toFixed(4)},{" "}
                    {device.RecentReading.Longitude.toFixed(4)}
                  </a>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="label">Reading Date</div>
                <div>
                  {formatDateTime(
                    device.RecentReading.ReadingDate,
                    props.timezone,
                  )}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const getRouterDetail = (device: RegisteredRouter) => {
    if (!device.RecentReading) return;

    return (
      <div className="router-detail">
        <table className="device-detail-table">
          {/* Refering to classes in ./DeviceDetails/DeviceDetails.scss */}
          <tbody>
            <tr>
              <td>
                <div className="label">Latest Reading</div>
                <div>{device.RecentReading.Connected ?? 0} connected</div>
              </td>
              <td>
                <div className="label">Visible</div>
                <div>{device.RecentReading.Visible ?? 0}</div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="label">Reading Date</div>
                <div>
                  {formatDateTime(
                    device.RecentReading.ReadingDate,
                    props.timezone,
                  )}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const getDeviceDetail = () => {
    if (props.device.RecentReading) {
      return (
        <div className="device-detail">
          {props.device.DeviceType === RegisteredDeviceType.Sensor &&
            getSensorDetail(props.device)}
          {props.device.DeviceType === RegisteredDeviceType.Router &&
            getRouterDetail(props.device)}
        </div>
      );
    }
    return (
      <div className="device-without-detail">
        This device has never transmitted
      </div>
    );
  };

  return (
    <>
      <div className="component-device-item">
        <div className="component-wrapper">
          <div>
            {getTypeBadge()}
            <span className="macAddress">
              {formatMacAddressForDisplay(props.device.MacAddress)}
            </span>
          </div>
          <div className="device-summary">
            <div className="wrap-text">
              <span>{props.device.DeviceName} </span>
              <span>&#183;</span>
              <span>{getThresholdText()}</span>
            </div>
            <span className="actions">
              <Tooltip message={isEnabled ? "Pause alerts" : "Resume alerts"}>
                <button className="icon-button" onClick={handleToggleAlerts}>
                  {isEnabled ? (
                    <Icon.PauseCircle className="icon" />
                  ) : (
                    <Icon.PlayCircle className="icon" />
                  )}
                </button>
              </Tooltip>
              <Tooltip message="Details">
                <button
                  className="icon-button"
                  onClick={handleViewDeviceDetail}
                >
                  <Icon.FileText className="icon" />
                </button>
              </Tooltip>
              <Tooltip message="Edit">
                <button className="icon-button" onClick={handleEditDeviceClick}>
                  <Icon.Edit className="icon" />
                </button>
              </Tooltip>
              <Tooltip message="Unregister">
                <button className="icon-button" onClick={handleUnregisterClick}>
                  <Icon.Trash2 className="icon" />
                </button>
              </Tooltip>
            </span>
          </div>
          <div className="device-data-meta">
            {!props.device.RecentReading?.ReadingDate ? (
              <span className="device-data-meta-text">No data</span>
            ) : (
              <span className="device-data-meta-text">
                Latest Reading: {getLatestReading()} at{" "}
                {formatDateTime(
                  props.device.RecentReading.ReadingDate,
                  props.timezone,
                )}
                <br />
              </span>
            )}
            {getAlertsEnabledBadge()}
          </div>
          {isPausingAlerts && (
            <AnchoredDialog onClose={handlePauseAlertClose}>
              <ToggleAlerts
                device={props.device}
                isEnabled={isEnabled}
                onSubmit={handleEditDevice}
                onCancel={handlePauseAlertClose}
              />
            </AnchoredDialog>
          )}
          {isResumingAlerts && (
            <AnchoredDialog onClose={handleResumeAlertClose}>
              <ToggleAlerts
                device={props.device}
                isEnabled={isEnabled}
                onSubmit={handleEditDevice}
                onCancel={handleResumeAlertClose}
              />
            </AnchoredDialog>
          )}
          {isViewingDetail && (
            <AnchoredDialog onClose={handleDeviceDetailClose}>
              <DeviceDetail
                title={`${props.device.DeviceType} Details`}
                device={props.device}
              >
                {getDeviceDetail()}
              </DeviceDetail>
            </AnchoredDialog>
          )}
          {isEditing && (
            <AnchoredDialog onClose={handleEditDeviceClose}>
              <EditDevice
                device={props.device}
                onSubmit={handleEditDevice}
                onCancel={handleEditDeviceClose}
              />
            </AnchoredDialog>
          )}
          {isUnregistering && (
            <AnchoredDialog onClose={handleUnregisterClose}>
              <UnregisterDevice
                device={props.device}
                onSubmit={handleUnregister}
                onCancel={handleUnregisterClose}
              />
            </AnchoredDialog>
          )}
        </div>
      </div>
    </>
  );
};

export default Device;
