import { formatInTimeZone } from "date-fns-tz";

export function formatDate(
  datetime: Date | string,
  timezone: string | undefined = undefined,
): string {
  const d = new Date(datetime);

  if (timezone === undefined)
    return d.toLocaleDateString("en-us", {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    });
  else return formatInTimeZone(d, timezone, "MM/dd/yyyy").toLowerCase();
}

export function formatTime(
  datetime: Date | string,
  timezone: string | undefined = undefined,
): string {
  const d = new Date(datetime);
  if (timezone === undefined)
    return d
      .toLocaleString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      })
      .toLowerCase();
  else return formatInTimeZone(d, timezone, "hh:mm aaa").toLowerCase();
}

export function formatDateTime(
  datetime: Date | string | undefined,
  timezone: string | undefined = undefined,
): string {
  if (datetime === undefined) return "N/A";
  return `${formatDate(datetime, timezone)} · ${formatTime(
    datetime,
    timezone,
  )}`;
}

export function formatMacAddressForBackend(
  macAddress?: string,
): string | undefined {
  const rawValue = macAddress?.trim().replace(/[^a-fA-F0-9]/g, "");
  return rawValue;
}

export function formatMacAddressForDisplay(macAddress?: string): string {
  const rawValue = formatMacAddressForBackend(macAddress);
  if (!rawValue) return "";

  const formattedValue = Array.from(rawValue);
  for (let i = 2; i < formattedValue.length; i += 3) {
    formattedValue.splice(i, 0, ":");
  }

  return formattedValue.join("").substring(0, 17).toUpperCase();
}
