import { useEffect } from "react";

export const useKeyListener = function (key: string, fn: () => void) {
  useEffect(() => {
    const keyHandler = (event: KeyboardEvent): void => {
      if (event.key === key) fn();
    };
    document.addEventListener("keydown", keyHandler, false);
    return () => {
      document.removeEventListener("keydown", keyHandler, false);
    };
  }, [key, fn]);
};

export const useEscapeKeyListener = function (fn: () => void) {
  useKeyListener("Escape", fn);
};
