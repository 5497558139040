import { FC } from "react";
import { groupBy } from "lodash";
import { isBefore, isAfter, differenceInMinutes } from "date-fns";
import { Chart } from "react-google-charts";
import useWindowDimensions from "../../hooks/Window";
import { MOBILE_SCREEN_MAX_WIDTH } from "../../core/constants";
import { DataPoint, ChartDataSeries } from "./types";

interface BarChartProps {
  title?: string;
  data: ChartDataSeries;
  filterFrom?: Date;
  filterTo?: Date;
}

export const BarChart: FC<BarChartProps> = (props) => {
  const data = props.data;
  const filterFrom = props.filterFrom;
  const filterTo = props.filterTo;

  const chartOptions = {
    title: props.title,
    legend: {
      position: "none",
    },
  };

  const window = useWindowDimensions();

  const getBucketSize = (minDate: Date, maxDate: Date) => {
    // For viewability reasons, there should not be a reasonable number of buckets in the bar chart
    const minuteDifference = differenceInMinutes(maxDate, minDate);

    const numberOfBuckers = window.width <= MOBILE_SCREEN_MAX_WIDTH ? 20 : 120;

    // visually the graph looks best with 120 buckets (or 20 on mobile), however,
    // we need the bucket size to divide evenly into a timescale

    const idealBucketSize = Math.round(minuteDifference / numberOfBuckers);
    const validBucketSizes = [1, 5, 15, 30, 60, 120, 180, 240, 360, 720, 1440];
    let index = 0;
    let diff: number | null = null;
    for (let i = 0; i < validBucketSizes.length; i++) {
      // find the closest valid bucket size given the ideal bucket size
      const bucketSizeDiff = Math.abs(idealBucketSize - validBucketSizes[i]);
      if (diff === null || bucketSizeDiff < diff) {
        index = i;
        diff = bucketSizeDiff;
      }
    }

    return validBucketSizes[index];
  };

  /**
   * Formats props.data for chart display
   */
  const formatChartData = () => {
    const filtered: ChartDataSeries = {};
    Object.entries(data).forEach(([seriesName, seriesData]) => {
      filtered[seriesName] = [];
      seriesData.forEach((datapoint) => {
        const isAfterFilter =
          !filterFrom || isAfter(datapoint.timestamp, filterFrom);
        const isBeforeFilter =
          !filterTo || isBefore(datapoint.timestamp, filterTo);
        if (isAfterFilter && isBeforeFilter) {
          filtered[seriesName].push(datapoint);
        }
      });
      filtered[seriesName].sort(
        (a, b) => a.timestamp.getTime() - b.timestamp.getTime(),
      );
    });

    const allData = Object.values(filtered).reduce(
      (acc, cur) => acc.concat(cur),
      [],
    );
    const allTimestamps = allData.map((x) => x.timestamp.getTime());
    const minTimestamp = Math.min(...allTimestamps);
    const maxTimestamp = Math.max(...allTimestamps);

    const bucketSize = getBucketSize(
      new Date(minTimestamp),
      new Date(maxTimestamp),
    );
    const startDate = new Date(minTimestamp);
    startDate.setHours(0, 0, 0, 0);

    // Group readings into appropriate buckets
    const aggregated: ChartDataSeries = {};
    Object.entries(filtered).forEach(([seriesName, seriesData]) => {
      aggregated[seriesName] = aggregateSeries(
        startDate,
        seriesData,
        bucketSize,
      );
    });

    return toGoogleChartsData(aggregated);
  };

  const aggregateSeries = (
    startDate: Date,
    seriesData: DataPoint[],
    bucketSize: number,
  ): DataPoint[] => {
    if (!seriesData?.length) return [];

    const buckets = groupBy(seriesData, (reading) => {
      const readingDate = new Date(reading.timestamp);
      const minuteDiff = differenceInMinutes(readingDate, startDate);
      const bucketNumber = Math.trunc(minuteDiff / bucketSize);

      const bucketValue = new Date(startDate);
      bucketValue.setMinutes(
        bucketValue.getMinutes() + bucketSize * bucketNumber,
      );

      return bucketValue;
    });

    // Return a list of buckets with max reading from each mac address
    const aggregatedSeries: DataPoint[] = Object.entries(buckets).map(
      ([bucketTime, readings]) => {
        return {
          timestamp: new Date(bucketTime),
          value: Math.max(...readings.map((x) => x.value)),
        };
      },
    );
    return aggregatedSeries;
  };

  /**
   * Translates chart data for google charts input
   * @param series
   * @returns
   */
  const toGoogleChartsData = (series: ChartDataSeries): any[] => {
    // header = [xAxisLabel, seriesAName, seriesBName, ..., seriesZName]
    // columns = [timestamp, seriesAValue, seriesBValue, ..., seriesZValue][]
    const seriesNames = Object.keys(series);
    const header: string[] = ["Time", ...seriesNames];

    // Group all datapoints by timestamp
    const columnMap: { [key: string]: { [key: string]: number } } = {};
    Object.entries(series).forEach(([seriesName, seriesData]) => {
      seriesData.forEach((datapoint) => {
        const timestamp = datapoint.timestamp.toISOString();
        const value = datapoint.value;

        if (!columnMap[timestamp]) columnMap[timestamp] = {};
        columnMap[timestamp][seriesName] = value;
      });
    });
    const columns: any[][] = Object.entries(columnMap).map(
      ([timestamp, seriesData]) => {
        // return nested array where each inner array is [timestamp, seriesAValue, seriesBValue, ...]
        const column: any[] = seriesNames.map((seriesName) => {
          const dataValue = seriesData[seriesName];
          return dataValue ?? NaN;
        });
        column.unshift(new Date(timestamp));
        return column;
      },
    );

    if (columns.length) return [header, ...columns];
    else return [];
  };

  /**
   * Returns a chart drawing
   * @param chartData
   * @returns
   */
  const getCharts = (chartData: any[][]) => {
    return (
      <div className="chart-content">
        <Chart
          chartType="Bar"
          width="100%"
          height="200px"
          data={chartData}
          options={chartOptions}
        />
      </div>
    );
  };

  const getChartsSection = () => {
    const chartData = formatChartData();
    if (filterFrom && filterTo) {
      if (filterFrom.getTime() > filterTo.getTime()) {
        return (
          <div>
            <div className="chart-placeholder">
              <div className="Status">Invalid date range filter</div>
            </div>
          </div>
        );
      }
    }
    return (
      <div>
        {chartData.length > 0 ? (
          getCharts(chartData)
        ) : (
          <div className="chart-placeholder">
            <div className="Status">No data for this date range</div>
          </div>
        )}
      </div>
    );
  };

  return getChartsSection();
};

export default BarChart;
