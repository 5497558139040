import InputDate from "./InputDate";
import InputNumber from "./InputNumber";
import InputText from "./InputText";

const FormInput = {
  Text: InputText,
  Number: InputNumber,
  Date: InputDate,
};

export default FormInput;
