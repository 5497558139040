import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DeviceCompatibilityState } from ".";
import { BackendResource, Compatibility } from "../../models/SmartHomeApi";

const initialState: DeviceCompatibilityState = {
  isLoading: false,
  data: undefined,
};

export const slice = createSlice({
  name: "compatibility",
  initialState,
  reducers: {
    loadData: (state, action: PayloadAction<string>) => {
      const propertyId = action.payload;

      state.propertyId = propertyId;
      state.isLoading = true;
      state.error = undefined;
    },
    setData: (
      state,
      action: PayloadAction<BackendResource<Compatibility>[]>,
    ) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
      state.data = [];
    },
    clear: (state) => {
      state.propertyId = undefined;
      state.isLoading = false;
      state.data = [];
      state.error = undefined;
    },
    upsertData: (
      state,
      action: PayloadAction<BackendResource<Compatibility>>,
    ) => {
      const data = state.data ? [...state.data] : [];
      const index = data.findIndex((x) => x.id === action.payload.id);
      if (index >= 0) data.splice(index, 1, action.payload);
      else data.push(action.payload);

      state.data = data;
    },
    removeData: (
      state,
      action: PayloadAction<BackendResource<Compatibility>>,
    ) => {
      const data = state.data ? [...state.data] : [];
      const index = data.findIndex((x) => x.id === action.payload.id);
      if (index >= 0) data.splice(index, 1);

      state.data = data;
    },
  },
});
