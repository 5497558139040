import { useSelector } from "react-redux";
import { StoreData } from "..";
import { AmenityType } from "../../models/ConnectApi";

export const useIsLoaded = () => {
  const isLoading = useSelector((state: StoreData) => state.amenity.isLoading);
  const amenities = useSelector((state: StoreData) => state.amenity.amenities);

  return !isLoading && amenities !== undefined;
};

export const useNoiseSensorAmenity = () => {
  const amenities = useSelector((state: StoreData) => state.amenity.amenities);
  return amenities?.find(
    (x) => x.attributes.amenity_id === AmenityType.NoiseSensor,
  );
};
